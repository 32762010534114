import { removeDashBracket } from '../../utils/parser';

app.directive('intlTelInput', [
  '$rootScope',
  'mainConfig',
  '$filter',
  '$timeout',
  'intlTelInputService',
  function ($rootScope, mainConfig, $filter, $timeout, intlTelInputService) {
    return {
      restrict: 'C',
      scope: {
        onChange: '=',
        phoneNumber: '@',
        onInputChange: '=',
        ui: '@',
      },
      link: function (scope, element) {
        // because react-intl-tel-input internally uses "intl-tel-input" as the class name,
        // it will accidentally trigger this directive on production, and prevent it by check if the element is input or not
        if ($(element)[0].nodeName !== 'INPUT') {
          return;
        }
        const simplifiedUi = scope.ui === 'simplified';
        var preferredCountries = _.filter(
          [
            'tw',
            'hk',
            'my',
            'us',
            'cn',
            'sg',
            'id',
            'th',
            'vn',
            'ph',
            'jp',
            'kr',
            'au',
            'nz',
            'ca',
          ],
          function (country) {
            return country != mainConfig.requestCountry.toLowerCase();
          },
        );
        preferredCountries.unshift(mainConfig.requestCountry.toLowerCase());

        function updateInputLabelLeft() {
          element
            .siblings('.input-label')
            .css({ left: element.css('padding-left') });
        }

        function onCountryChange(countryData, isInit) {
          scope.onChange(countryData, isInit, element);
          intlTelInputService.renderCountryISO(element);
          intlTelInputService.updateInputPaddingLeft(element).then(() => {
            if (simplifiedUi) {
              updateInputLabelLeft();
            }
          });
          if (!isInit) {
            $rootScope.$digest();
          }
        }

        function onInputChange(input, isInit) {
          scope.onInputChange?.(input, isInit, element);

          if (!isInit) {
            $rootScope.$digest();
          }
        }

        const customPlaceholder = simplifiedUi ? () => ' ' : removeDashBracket;

        if (scope.phoneNumber) {
          element.intlTelInput({
            customPlaceholder,
          });
          if (intlTelInputUtils.isValidNumber('+' + scope.phoneNumber)) {
            element.intlTelInput('setNumber', '+' + scope.phoneNumber);
          } else {
            element.intlTelInput(
              'setCountry',
              mainConfig.requestCountry.toLowerCase(),
            );
            element.intlTelInput('setNumber', scope.phoneNumber);
          }
        } else {
          element.intlTelInput({
            customPlaceholder,
            preferredCountries: preferredCountries,
          });
        }

        if (simplifiedUi) {
          element.wrap('<div class="intl-floating-label-wrapper"></div>');
          const label = `<label class="input-label">${$filter('translate')(
            'users.fields.mobile.placeholder',
          )}</label>`;
          element.after(label);
        }

        onCountryChange(element.intlTelInput('getSelectedCountryData'), true);

        element.on('countrychange', function () {
          onCountryChange(element.intlTelInput('getSelectedCountryData'));
        });

        element.on('input', function () {
          onInputChange($(this).val());
        });
      },
    };
  },
]);
