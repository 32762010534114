app.directive('redeemGiftPage', [
  '$filter',
  '$timeout',
  'localDatetimeServices',
  function ($filter, $timeout, localDatetimeServices) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        $timeout(function () {
          var $dateDOM = element.find('.local-datetime');
          var offset = new Date().getTimezoneOffset() / -60;
          var title = $filter('translate')(
            'promotions.page.display_local_timezone',
            {
              offset: offset < 0 ? offset : '+' + Math.abs(offset),
            },
          );
          localDatetimeServices.mountTooltip($dateDOM, title);
        });
      },
    };
  },
]);
