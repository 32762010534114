import { isEmpty, negate, defaultTo } from 'lodash-es';

app.directive('taxFeePopover', [
  'taxService',
  '$rootScope',
  '$filter',
  function (taxService, $rootScope, $filter) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/shared/tax/templates.popover.tax.fee.html'),
      scope: {
        taxData: '=?',
        isTaxUpdate: '=?',
        createdBy: '@?',
      },
      link: function (scope, element) {
        const t = $filter('translate');
        if (scope.taxData.total.cents === 0) return;

        if (scope.taxData.sales_tax_fee.cents === 0) {
          scope.taxData.sales = {};
        }
        if (scope.taxData.delivery_tax_fee.cents === 0) {
          scope.taxData.delivery = {};
        }

        if (scope.isTaxUpdate) {
          $rootScope.$broadcast('tax.fee.isUpdated', scope.taxData);
        }

        var getTitleText = function (data) {
          data = defaultTo(
            [data.sales, data.delivery, data.service].find(negate(isEmpty)),
            {},
          );
          var titleText = $filter('translate')(data.country_code || '');
          if (data.region || data.city) {
            titleText += ' - ' + (data.city || data.region);
          }
          return titleText;
        };

        const getCalculationContent = function (createdBy, taxData) {
          if (createdBy === 'pos') {
            const hasSalesTax = !_.isEmpty(taxData.sales);
            const hasServiceTax = !_.isEmpty(taxData.service);

            const result = [];

            if (hasSalesTax) {
              const salesTaxRate = taxData.service?.service_charge_taxable
                ? `(1 + ${t('tax.service_tax_rate')}) * ${t(
                    'tax.sales_tax_rate',
                  )}`
                : t('tax.sales_tax_rate');

              const saleTax = `(${t('tax.product_price')} - ${t(
                'tax.discounts',
              )}) * ${salesTaxRate}`;

              result.push(saleTax);
            }
            if (hasServiceTax) {
              const serviceCharge = `(${t('tax.product_price')} - ${t(
                'tax.discounts',
              )} - ${t('tax.applied_store_credit')}) * ${t(
                'tax.service_tax_rate',
              )}`;

              result.push(serviceCharge);
            }
            return result.length > 0 ? result.join(' + ') : '-';
          }

          return t('tax.calculation_content');
        };

        var getContentText = function (data) {
          const contentText = [];
          var salesTaxRate = taxService.getTaxRateText(data.sales);
          var deliveryTaxRate = taxService.getTaxRateText(data.delivery);
          const serviceTaxRate = taxService.getTaxRateText(data.service);

          if (salesTaxRate) {
            const salesTaxName = data.sales.name || t('tax.sales_tax');
            contentText.push(`${salesTaxName} ${salesTaxRate}`);
          }
          if (deliveryTaxRate) {
            contentText.push(`${t('tax.delivery_tax')} ${deliveryTaxRate}`);
          }
          if (serviceTaxRate) {
            contentText.push(`${t('tax.service_tax')} ${serviceTaxRate}`);
          }
          return contentText.join(', ');
        };

        element.on('click', function (e) {
          e.stopPropagation();
        });

        element.popover({
          animation: true,
          trigger: 'hover',
          placement: 'right',
          html: true,
          content:
            '<div class="tax-fee-block">' +
            '<p class="tax-fee-title">' +
            getTitleText(scope.taxData) +
            '</p>' +
            '<p>' +
            getContentText(scope.taxData) +
            '</p>' +
            '</div>' +
            '<p class="tax-fee-title">' +
            $filter('translate')('tax.calculation_title') +
            '</p>' +
            '<p>' +
            getCalculationContent(scope.createdBy, scope.taxData) +
            '</p>',
          template:
            '<div class="tax-fee-popover popover" role="tooltip">' +
            '<h3 class="popover-title"></h3>' +
            '<div class="popover-content">' +
            '</div>' +
            '</div>',
        });
      },
    };
  },
]);
