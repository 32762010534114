const customerIntlKey = '#order-customer-phone.intl-tel-input-checkout';
const recipientIntlKey = '#recipient-phone.intl-tel-input-checkout';

app.service('ordersService', [
  '$http',
  '$window',
  'intlTelInputService',
  'slFeatureService',
  function ($http, $window, intlTelInputService, slFeatureService) {
    return {
      saveSessionData: function (order, options) {
        // Stash applied coupon to session data if there is any
        var coupons_applied = [];
        if (order.coupons && order.coupons.length > 0) {
          angular.forEach(
            order.coupons,
            function (coupon) {
              if (!coupon.auto_apply) {
                // autoapplied coupons do not need to reapply
                this.push(coupon.coupon_item.coupon_code);
              }
            },
            coupons_applied,
          );
        }

        let customerCountry = null,
          recipientCountry = null;
        if ($(customerIntlKey).length > 0) {
          customerCountry = intlTelInputService.getCountry(
            'abbr',
            $(customerIntlKey),
          );
        }
        if ($(recipientIntlKey).length > 0) {
          recipientCountry = intlTelInputService.getCountry(
            'abbr',
            $(recipientIntlKey),
          );
        }

        if (
          order.delivery_address &&
          typeof order.delivery_address.address_node_ids === 'object'
        ) {
          order.delivery_address.address_node_ids = Object.values(
            order.delivery_address.address_node_ids,
          );
        }

        return $http.post(
          '/api/orders/session_data',
          angular.extend(
            {
              customer_name: order.customer_name,
              customer_email: order.customer_email,
              customer_phone: order.customer_phone,
              save_customer_phone: order.save_customer_phone,
              order_remarks: order.order_remarks,
              recipient_name: order.delivery_data.recipient_name,
              recipient_phone: order.delivery_data.recipient_phone,
              delivery_option: order.delivery_option._id,
              payment_method: order.payment_method._id,
              coupon_codes: coupons_applied,
              recipient_is_customer: order.delivery_data.recipient_is_customer,
              customer_country: customerCountry,
              recipient_country: recipientCountry,
              delivery_address: order.delivery_address,
            },
            options,
          ),
        );
      },

      afterConfirm: function (orderId, isGaUpdated, isFbUpdated) {
        var url = '/orders/' + orderId + '/after_confirm';
        var params = {
          ga_tracked: isGaUpdated,
          fb_tracked: isFbUpdated,
        };
        var token = _.find($window.location.search.split('&'), function (
          param,
        ) {
          return param.indexOf('authorization_token') >= 0;
        });
        if (token) {
          params['authorization_token'] = token.split('=')[1];
          return $http({
            method: 'PUT',
            url: url,
            data: params,
          });
        } else {
          return;
        }
      },

      getTrackingFlags: function (orderId) {
        return $http({
          method: 'POST', // use post method here to prevent browser caching
          url: '/orders/' + orderId + '/tracking_flags',
        });
      },

      customerCancel: function (orderId, cancelReason) {
        return $http({
          method: 'POST',
          url: '/api/orders/' + orderId + '/customer_cancel',
          data: { cancelled_reason: cancelReason },
        });
      },

      allowTrialPlanOrders: function (merchantId) {
        return $http({
          method: 'GET',
          url: '/api/merchants/' + merchantId + '/check_trial_order_limit',
        });
      },

      getOrderNumber: function (order) {
        if (!order) return '';
        if (
          slFeatureService.hasFeature('merchant_order_number') &&
          order.merchant_order_number
        ) {
          return order.merchant_order_number;
        }
        return order.order_number;
      },
    };
  },
]);
