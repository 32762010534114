import { validatePhone } from '../../utils/intlTel';

app.controller('MessageFormController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  'mainConfig',
  '$upload',
  'customValidator',
  '$filter',
  'RecaptchaService',
  'pnotifyService',
  'slFeatureService',
  'intlTelInputService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    mainConfig,
    $upload,
    customValidator,
    $filter,
    RecaptchaService,
    pnotifyService,
    slFeatureService,
    intlTelInputService,
  ) {
    $scope.allowPhoneField = slFeatureService.hasFeature('contact_us_mobile');
    $scope.emailRegex = customValidator.EMAIL_REGEX;
    $scope.message = {
      recipient_id: mainConfig.merchantId,
      recipient_type: 'Merchant',
    };

    if ($rootScope.isUserLoggedIn) {
      $scope.message.sender_id = $rootScope.currentUser._id;
      $scope.message.sender_email = $rootScope.currentUser.email;
      $scope.message.sender_mobile = $rootScope.currentUser.mobile_phone;
      $scope.message.country_calling_code =
        $rootScope.currentUser.country_calling_code;
      $scope.message.sender_type = 'User';
    } else {
      $scope.message.sender_email = null;
      // parse current URL
      var current_query = new URI(window.location.href);
      current_query = current_query.query();
      current_query = URI.parseQuery(current_query);
      $scope.message.sender_id = current_query['sender_id'];
      $scope.message.sender_type = 'User';
    }

    function resetSubmitMessageRecaptcha() {
      if ($('#submit-message-recaptcha').length > 0) {
        RecaptchaService.reset('submit-message-recaptcha');
      }
      if ($('#btn-form-submit-modal').length > 0) {
        RecaptchaService.reset('btn-form-submit-modal');
      }
    }

    if (!$rootScope.isUserLoggedIn && $scope.allowPhoneField) {
      $scope.guestContact = {
        type: 'email', // email, mobile_phone
        email: null,
        phone: {
          countryCode: null,
          countryCallingCode: null,
          number: null,
        },
      };
      $scope.onPhoneChange = function () {
        verifyPhoneFormat();
      };
      $scope.onCountryCodeChange = function (countryData, isInit) {
        $scope.guestContact.phone.countryCallingCode = countryData.dialCode;
        $scope.guestContact.phone.countryCode = countryData.iso2;
        if (isInit) return false;
        verifyPhoneFormat();
      };
      // eslint-disable-next-line no-inner-declarations
      function verifyPhoneFormat() {
        const mobilePhoneInput = $('.guest-phone-with-country.intl-tel-input');
        const mobilePhoneInputValue = mobilePhoneInput.val();
        const internationalNumber = mobilePhoneInput
          .intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL)
          .replace(/\D/g, '');
        const nationalNumber = mobilePhoneInput
          .intlTelInput('getNumber', intlTelInputUtils.numberFormat)
          .replace(/\D/g, '');
        const isValid =
          validatePhone(
            internationalNumber,
            $scope.guestContact.phone.countryCode,
          ).length === 0 &&
          [internationalNumber, nationalNumber].includes(mobilePhoneInputValue);
        if (isValid) {
          $scope.guestContact.phone.number = internationalNumber;
        }
        $scope.messageForm.sender_mobile.$setValidity('pattern', isValid);
      }
    }

    $scope.submitMessageForm = function () {
      if ($scope.messageForm.submitting == true) {
        return;
      }
      $scope.messageForm.submitted = true;
      $scope.messageForm.submitting = true;

      var isFormValid = false;
      if (!$rootScope.isUserLoggedIn && $scope.allowPhoneField) {
        var isContactTypeValid =
          $scope.guestContact.type === 'email'
            ? $scope.messageForm.sender_email.$valid
            : $scope.messageForm.sender_mobile.$valid;
        isFormValid =
          isContactTypeValid && $scope.messageForm.message_text.$valid;
      } else {
        isFormValid = $scope.messageForm.$valid;
      }

      if (!isFormValid) {
        $scope.messageForm.submitting = false;
        resetSubmitMessageRecaptcha();
        return;
      }

      if (!$rootScope.isUserLoggedIn && $scope.allowPhoneField) {
        if ($scope.guestContact.type === 'email') {
          $scope.message.sender_email = $scope.guestContact.email;
        } else {
          $scope.message.sender_mobile = $scope.guestContact.phone.number;
          $scope.message.country_calling_code =
            $scope.guestContact.phone.countryCallingCode;
        }
      }

      $scope.loading = true;
      $http({
        method: 'POST',
        url: '/api/messages',
        data: {
          message: $scope.message,
          recaptchable: true,
        },
      })
        .then(function (res) {
          $scope.loading = false;
          $scope.messageForm.submitting = false;
          $rootScope.$broadcast('chat.message.created', res.data);
          $scope.message.text = '';
          $scope.deleteMedia();
          $scope.loading = false;
          if ($('.contact-us-modal').length > 0) {
            $rootScope.closeMessageForm();
          }
          $scope.messageForm.submitted = false;
        })
        .catch(function (res) {
          pnotifyService.notify(
            $filter('translate')(
              'order_comment_and_message.' + res.data.message,
            ),
            {
              customClass: 'error',
              icon: 'fa fa-exclamation-triangle',
            },
          );
          $scope.loading = false;
          $scope.messageForm.submitting = false;
        })
        .finally(function () {
          resetSubmitMessageRecaptcha();
        });
    };

    $scope.validateMediaRecaptcha = function ($files) {
      if ($files.length === 0) {
        return;
      }
      $scope.tempFiles = $files;
      RecaptchaService.reset('select-media-recaptcha');
      RecaptchaService.execute('select-media-recaptcha');
    };

    $scope.onMediaRecaptchaSuccess = function () {
      $scope.onFileSelect($scope.tempFiles);
    };

    $scope.onFileSelect = function ($files) {
      if ($scope.imageUploading == true) {
        return;
      }
      $scope.flash = [];
      if ($files.length > 4) {
        $scope.flash.push({
          type: 'error',
          text: 'You may only upload up to 4 files',
        });
        return;
      }

      for (var i = 0; i < $files.length; i++) {
        var file = $files[i];
        // if (file.type !='image/png' && file.type !='image/jpeg') {
        //   $scope.imageUploading = false;
        //   $scope.flash.push ({type:"error", text: "Only Jpeg and Png files are supported"});
        //   return;
        // }

        $scope.imageUploading = true;
        $scope.uploadMedia(file);
      }
    };

    $scope.uploadMedia = function (image) {
      $scope.errorMessage = '';
      $scope.upload = $upload
        .upload({
          url: '/api/media',
          method: 'POST',
          data: {
            media: {
              status: 'temp',
              trackable_type: 'Chat',
            },
            recaptchable: true,
          },
          file: image,
          fileFormDataName: 'media[image_clip]',
          formDataAppender: function (fd, key, val) {
            if (angular.isArray(val)) {
              angular.forEach(val, function (v) {
                fd.append(key, v);
              });
            } else if (angular.isObject(val)) {
              angular.forEach(val, function (value, idx2) {
                fd.append(key + '[' + idx2 + ']', value);
              });
            } else {
              fd.append(key, val);
            }
          },
        })
        .then(function (res) {
          var data = res.data;
          $scope.message.media = data.data;
          $scope.message.media_id = data.data._id;
        })
        .catch(function () {
          $scope.errorMessage = $filter('translate')('media.validation.error');
        })
        .finally(function () {
          $scope.imageUploading = false;
          if ($('#select-media-recaptcha').length > 0) {
            RecaptchaService.reset('select-media-recaptcha');
          }
        });
    };

    $scope.deleteMedia = function () {
      $scope.message.media = null;
      $scope.message.media_id = null;
      if ($('#select-media-recaptcha').length > 0) {
        RecaptchaService.reset('select-media-recaptcha');
      }
    };

    const unbindWatch = $scope.$watch('guestContact.type', (newValue) => {
      if (newValue === 'mobile_phone') {
        const mobilePhoneInput = $('.guest-phone-with-country.intl-tel-input');
        if (mobilePhoneInput) {
          intlTelInputService.updateInputPaddingLeft(mobilePhoneInput);
        }
        unbindWatch();
      }
    });
  },
]);
