app.directive('productVariationDropdown', [
  'productService',
  'cartService',
  'trackerService',
  '$filter',
  'locationService',
  'slPixelService',
  '$location',
  'productPreorderService',
  function (
    productService,
    cartService,
    trackerService,
    $filter,
    locationService,
    slPixelService,
    $location,
    productPreorderService,
  ) {
    return {
      restrict: 'AE',
      link: function (scope) {
        scope.variationName = getVariationName();

        function getVariationName() {
          const variantId =
            locationService.getQueryParams()?.variant_id ||
            scope.$parent.variantId;

          if (variantId) {
            const variation = scope.product.variations.find(
              ({ key }) => key === variantId,
            );
            return (
              variation?.fields.map((field) =>
                $filter('translateModel')(field.name_translations),
              ) || []
            );
          }

          return [];
        }

        scope.onVariationChanged = function (index) {
          scope.notEnoughStock = -1; //means that there is enough stock
          scope.notEnoughStockQty = -1;

          filterVariations(index);

          scope.variationSelected = _.find(scope.product.variations, function (
            variation,
          ) {
            // Get list of matching variation fields
            // example: ["small", "red"]
            var variationSelected = _.filter(variation.fields, function (
              field,
              index,
            ) {
              return (
                scope.variationName[index] ==
                $filter('translateModel')(
                  variation.fields[index].name_translations,
                )
              );
            });
            return variationSelected.length == variation.fields.length;
          });

          if (
            angular.isUndefined(scope.variationSelected) ||
            !scope.variationSelected
          ) {
            scope.addItemQuantity = 1;
            scope.hasStock = false;
            scope.state.checkoutReady = false;
            return;
          }
          // SL-33827 remove legacy code: sold out when scope.variationSelected.max_order_quantity <= 0
          // if (scope.checkoutReady) scope.checkoutReady = true; // checking for not have delivery option and payment method, hide price etc is in
          scope.state.checkoutReady = productService.validateCheckoutReady(
            scope.product,
            scope.variationSelected,
            cartService.getItemPrice({ product: scope.product }),
          );
          scope.addItemQuantity = 1;
          // get variation price or member_price or price_sale
          if (
            scope.variationSelected.price ||
            scope.variationSelected.member_price ||
            scope.variationSelected.price_sale
          ) {
            scope.price = productService.showVariationPrice(
              scope.variationSelected,
            );
          }

          trackerService.gaSendDetails(
            scope.product,
            scope.variationSelected,
            scope.products,
          );
          scope.products = null; //clear the related products as they are only for impressions
          var price = cartService.getItemPrice({
            product: scope.product,
            variation: scope.variationSelected,
          });
          var dollars = _.isObject(price) ? price.dollars : 0;
          trackerService.fbSendViewContent(
            scope.product,
            dollars,
            scope.variationSelected,
          );
          trackerService.track({
            type: trackerService.generalEventType.VIEW_CONTENT,
            data: {
              product: scope.product,
              value: dollars,
              variationSelected: scope.variationSelected,
            },
          });
          var wishlistBlockCenter = !scope.product.hide_price && scope.hasStock;
          angular
            .element('.Wishlist-addItem-block')
            [wishlistBlockCenter ? 'addClass' : 'removeClass'](
              'Wishlist-block-center',
            );

          var productDetailPagePathRegex = /^\/products\/.*/;
          if (productDetailPagePathRegex.test($location.path()))
            sendSlPixelTracking();
        };

        function sendSlPixelTracking() {
          var product = scope.product;
          var targetVariation = scope.variationSelected;
          if (!_.isObject(targetVariation) || !_.isObject(product)) return;

          var priceTarget = product.same_price ? product : targetVariation;
          var variationId = targetVariation.key;
          var salePrice = priceTarget.price_sale
            ? priceTarget.price_sale.dollars
            : 0;
          var memberPrice = priceTarget.member_price
            ? priceTarget.member_price.dollars
            : 0;
          var currency =
            priceTarget.price && priceTarget.price.currency_iso
              ? targetVariation.price.currency_iso
              : null;
          var trackingProductParams = {
            productID: product._id,
            variationID: variationId,
            name: $filter('translateModel')(product.title_translations),
            price: priceTarget.price && priceTarget.price.dollars,
            currency: currency,
            salePrice: salePrice,
            memberPrice: memberPrice,
          };
          return slPixelService.hdPageView('productDetail', {
            product: trackingProductParams,
            inStock: scope.hasStock,
          });
        }

        scope.onVariationClick = function (e) {
          var variationValue = $(e.currentTarget).data('value');
          var dropdownIndex = $(e.currentTarget)
            .parents('.dropdown')
            .data('id');
          scope.variationName[dropdownIndex] = variationValue;
          scope.onVariationChanged(dropdownIndex);
        };

        function filterVariations(variationIndex) {
          scope.product._cache = scope.product._cache || {};
          const state = productService.updateVariationOption(
            {
              optionList: scope.product.digest_variations || [],
              selectedList: scope.variationName || [],
            },
            scope.product,
            variationIndex,
            scope.product._cache,
          );

          scope.product.digest_variations = state.optionList;
          scope.variationName = state.selectedList;
        }

        function selectBestOption(variation_key) {
          var bestVariation =
            getRequestedVariation() ||
            getInStockVariation() ||
            scope.product.variations[0];
          scope.variationName[variation_key] = $filter('translateModel')(
            bestVariation.fields[variation_key].name_translations,
          );
        }

        var getUrlVariationId = function () {
          // TODO: update variationId params onVariationChange
          var urlParams = locationService.getQueryParams();
          return urlParams['variation'];
        };

        var getRequestedVariation = function () {
          var urlVariationId = getUrlVariationId();
          return _.findWhere(scope.product.variations, { key: urlVariationId });
        };

        const getInStockVariation = function () {
          const isOutOfStockOrderable = productService.isOutOfStockOrderable(
            scope.product,
          );

          return scope.product.variations.find(function (variation) {
            if (scope.product.unlimited_quantity || isOutOfStockOrderable) {
              return true;
            }

            const totalOrderableQuantity =
              variation.quantity +
              productPreorderService.getPreorderLimit(scope.product, variation);
            return totalOrderableQuantity > 0;
          });
        };

        // variant selected from pdp
        var previewVariant = scope.product.variations.find(function (option) {
          return option.key === scope.variantId;
        });

        var watchProduct = scope.$watch('product', function (newValue) {
          if (newValue) {
            filterVariations(0);

            if (
              scope.product.variations &&
              scope.product.variations.length > 0 &&
              !scope.product.hide_price
            ) {
              if (previewVariant) {
                previewVariant.variant_option_ids.forEach(function (index) {
                  scope.onVariationChanged(index);
                });
              } else {
                scope.product.digest_variations.forEach(function (v, index) {
                  selectBestOption(index);
                });
                scope.onVariationChanged();
              }
            }
            watchProduct();
          }
        });

        scope.getSelectTypeByIndex = function (index) {
          return productService.getVariationType(
            scope.product.field_titles[index].selectorEnabled,
          );
        };
      },
    };
  },
]);
