app.directive('formDatePicker', [
  '$filter',
  function ($filter) {
    return {
      restrict: 'E',
      scope: {
        itemId: '@',
        label: '@',
        isRequired: '@',
      },
      require: ['^form'],
      templateUrl: require('../../../../../public/themes/v1/default/views/form.date-picker.html'),
      link: function ($scope, element, attrs, ctrls) {
        var formController = ctrls[0];
        $scope.dateRegex = '/d{4}-d{2}-d{2}/';
        $scope.datepickerOptions = {
          showWeeks: false,
        };

        $scope.datepicker = {
          open: false,
          value: null,
        };

        $scope.open = function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          $scope.datepicker.open = true;
        };

        $scope.$watch(function () {
          return validate();
        }, true);

        $scope.$watch(
          'datepicker.open',
          function () {
            return validate();
          },
          true,
        );

        $scope.$watch('hasError', function () {
          if ($scope.error.parse == true) {
            $scope.errorMessage = $filter(
              'translate',
            )('form.validation.pattern', { field_name: $scope.label });
            return;
          }

          if ($scope.error.required == true) {
            $scope.errorMessage = $filter(
              'translate',
            )('form.validation.required', { field_name: $scope.label });
            return;
          }
        });

        var validate = function () {
          var fieldObject = formController[$scope.itemId];
          if (fieldObject == undefined) return;

          if (formController.$submitted || formController.submitted) {
            $scope.hasError = fieldObject.$invalid;
          } else {
            $scope.hasError = false;
          }

          $scope.error = fieldObject.$error;
        };
      },
    };
  },
]);
