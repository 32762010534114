import getSku from '../../utils/getSku';

app.service('productService', [
  '$rootScope',
  '$http',
  'mainConfig',
  '$filter',
  '$q',
  'featureService',
  'slFeatureService',
  function (
    $rootScope,
    $http,
    mainConfig,
    $filter,
    $q,
    featureService,
    slFeatureService,
  ) {
    this.merchantId = '';
    var merchantData = {};
    var deliveryOptions = null;

    this.PREORDER_UNLIMITED_AMOUNT = -1;

    this.setMerchantData = function (data) {
      merchantData = data;
      //$rootScope.$broadcast( 'MerchantService.update', merchantData );
    };
    this.getCurrency = function () {
      return merchantData.base_currency;
    };

    this.fetchData = function () {
      $http({
        method: 'GET',
        url:
          mainConfig.apiBaseUrl + '/api/theme/v1/merchants/' + this.merchantId,
      }).then(function (res) {
        var merchantData = res.data.data;
        $rootScope.$broadcast('merchantService.update', merchantData);
      });
    };
    this.getDeliveryOptions = function () {
      if (deliveryOptions == null) return [];
      return deliveryOptions;
    };
    this.fetchDeliveryOptions = function () {
      $http({
        method: 'GET',
        url:
          mainConfig.apiBaseUrl +
          '/api/theme/v1/merchants/' +
          this.merchantId +
          '/delivery_options',
      }).then(function (res) {
        var deliveryOptions = res.data.data;
        $rootScope.$broadcast(
          'merchantService.deliveryOptions.update',
          deliveryOptions,
        );
      });
    };
    this.fetchCheckoutOptions = function () {
      $http({
        method: 'GET',
        url:
          mainConfig.apiBaseUrl +
          '/api/theme/v1/merchants/' +
          this.merchantId +
          '/checkout_options',
      }).then(function (res) {
        var data = res.data;
        deliveryOptions = data.data.delivery_options;
        $rootScope.$broadcast(
          'merchantService.checkoutOptions.update',
          data.data,
        );
      });
    };
    /**
     *
     * @param {String} id product id
     * @param {String} v_id variation id
     * @param {String} pageId express_checkout_page id
     * @param {Boolean} isFastCheckoutCart
     * @param {Object} options
     * @param {String} options.onePageStoreId onePageStoreId
     * @returns {Promise} angular $http response
     */
    this.checkStock = function (
      id,
      v_id,
      pageId,
      isFastCheckoutCart,
      options = {},
    ) {
      var params = { variation_id: v_id, page_id: pageId };
      if (isFastCheckoutCart) {
        params.product_id = id;
      }
      if (options?.onePageStoreId) {
        params.one_page_store_id = options.onePageStoreId;
      }
      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          mainConfig.merchantId +
          '/products/' +
          id +
          '/check_stock',
        params: params,
      });
    };
    this.checkStocks = function (products, isFastCheckoutCart) {
      var params = { products: products };
      if (isFastCheckoutCart) {
        params.product_id = products[0].product_id;
      }
      return $http({
        method: 'POST',
        url:
          '/api/merchants/' + mainConfig.merchantId + '/products/check_stocks/',
        data: params,
      });
    };
    this.checkPurchaseLimitByCustomer = function (productId, quantity) {
      var params = { quantity: quantity };

      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          mainConfig.merchantId +
          '/products/' +
          productId +
          '/purchase_limit_by_customer',
        params: params,
      });
    };
    (this.checkProductSetStock = function (params) {
      const productSetParams = params.productSetData
        .map((data) => {
          let param =
            'selected_child_products[][child_product_id]=' +
            data.child_product_id;
          if (data.child_variation_id) {
            param +=
              '&&selected_child_products[][child_variation_id]=' +
              data.child_variation_id;
          }
          if (data.quantity) {
            param += '&&selected_child_products[][quantity]=' + data.quantity;
          }
          return param;
        })
        .join('&');
      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          mainConfig.merchantId +
          '/products/' +
          params.id +
          '/check_stock?' +
          productSetParams,
      });
    }),
      (this.getVariationName = function (variation) {
        var variation_name = '';
        if (variation) {
          var temp = $filter('translateModel')(variation.fields_translations);
          if (temp[0]) {
            if (temp[1]) {
              variation_name = temp[0] + temp[1];
            } else {
              variation_name = temp[0];
            }
          }
        }
        return variation_name;
      });
    this.getSku = getSku;
    this.getByCategory = function (categoryId, options) {
      if (options === undefined) {
        options = {};
      }
      options.limit = options.limit || 24;
      options.page = options.page || 1;
      var requestOptions = {
        url: '/api/categories/' + categoryId + '/products',
        params: options,
        method: 'GET',
      };
      var offset = (options.page - 1) * options.limit;
      requestOptions.params.offset = offset;

      if (options.format && options.format == 'html') {
        requestOptions.url = '/categories/' + categoryId;
        requestOptions.headers = {
          'X-Requested-With': 'XMLHttpRequest',
        };
      }

      var additionOptions = ['col_class'];
      for (var i = 0; i < additionOptions.length; i++) {
        var key = additionOptions[i];
        if (options[key]) {
          requestOptions.params[key] = options[key];
        }
      }

      return $q(function (resolve, reject) {
        $http(requestOptions)
          .then(function (res) {
            resolve(res.data);
          })
          .catch(function (res) {
            reject(res.status);
          });
      });
    };
    this.validateCheckoutReady = function (
      product,
      variationSelected,
      productPrice,
    ) {
      if (product.belongsToProductSet) {
        // skip price check if product belongs to product set
        return (
          mainConfig.merchantData.payment_method_count > 0 &&
          mainConfig.merchantData.delivery_option_count > 0
        );
      }

      if (variationSelected) {
        var validVariationPrice;
        if (product.same_price) {
          validVariationPrice = productPrice && productPrice.cents > 0;
        } else {
          validVariationPrice =
            (variationSelected.price &&
              variationSelected.price.cents &&
              variationSelected.price.cents > 0) ||
            (variationSelected.member_price &&
              variationSelected.member_price.cents &&
              variationSelected.member_price.cents > 0 &&
              mainConfig.currentUser &&
              slFeatureService.hasFeature('member_price')) ||
            (variationSelected.price_sale &&
              variationSelected.price_sale.cents &&
              variationSelected.price_sale.cents > 0);
        }

        // SL-33827 remove legacy code: variationSelected.max_order_quantity > 0
        return (
          mainConfig.merchantData.payment_method_count > 0 &&
          mainConfig.merchantData.delivery_option_count > 0 &&
          validVariationPrice
        );
      } else {
        return (
          mainConfig.merchantData.payment_method_count > 0 &&
          mainConfig.merchantData.delivery_option_count > 0 &&
          productPrice != null &&
          productPrice.cents > 0 &&
          product.hide_price != true
        );
      }
    };

    this.getById = function (id) {
      return $http({
        method: 'GET',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        url: '/api/merchants/' + mainConfig.merchantId + '/products/' + id,
      });
    };

    this.hasLowerPrice = function (product) {
      var tierMemberPriceEnable = slFeatureService.hasFeature(
        'tier_member_price',
      );
      if (
        tierMemberPriceEnable &&
        product.price &&
        product.lowest_member_price &&
        product.price.cents === product.lowest_member_price.cents
      ) {
        return false;
      }

      return (
        (product.price_sale && product.price_sale.cents > 0) ||
        (((tierMemberPriceEnable &&
          product.lowest_member_price &&
          product.lowest_member_price.cents > 0) ||
          (product.member_price && product.member_price.cents > 0)) &&
          mainConfig.currentUser &&
          slFeatureService.hasFeature('member_price'))
      );
    };

    this.showVariationPrice = function (variation) {
      if (
        variation.member_price &&
        variation.member_price.cents > 0 &&
        mainConfig.currentUser &&
        slFeatureService.hasFeature('member_price')
      ) {
        return variation.member_price.label;
      }
      if (variation.price_sale && variation.price_sale.cents > 0) {
        return variation.price_sale.label;
      }
      return variation.price.label;
    };

    this.variationPriceMemberTag = function (variation) {
      if (!variation) {
        return '';
      }

      if (
        variation.member_price &&
        variation.member_price.cents > 0 &&
        mainConfig.currentUser &&
        slFeatureService.hasFeature('member_price') &&
        !this.hasFlashPrice(variation)
      ) {
        var memberOnlyTag = $filter('translate')('product.member_only');
        var memberTierPrice =
          mainConfig.currentUser && variation.product_price_tiers
            ? variation.product_price_tiers[
                mainConfig.currentUser.membership_tier_id
              ]
            : null;
        if (
          slFeatureService.hasFeature('tier_member_price') &&
          !_.isEmpty(memberTierPrice) &&
          memberTierPrice.cents > 0
        ) {
          memberOnlyTag = $filter('translate')('product.tier_member_only', {
            tierName: $filter('translateModel')(
              mainConfig.currentUser.membership_tier.name_translations,
            ),
          });
        }
        return memberOnlyTag;
      }
      return '';
    };

    this.hasFlashPrice = function (product) {
      if (!product || !slFeatureService.hasFeature('flash_price_campaign')) {
        return false;
      }
      if (product.same_price || !product.variations) {
        return product.has_flash_price;
      }
      return _.find(product.variations, function (variation) {
        return variation.has_flash_price;
      });
    };

    this.showMemberPriceTip = function (product) {
      if (
        mainConfig.currentUser ||
        !mainConfig.merchantData.product_setting.enabled_show_member_price ||
        this.hasFlashPrice(product)
      )
        return false;
      return (
        product &&
        product.member_price &&
        product.member_price.cents > 0 &&
        slFeatureService.hasFeature('member_price')
      );
    };

    this.getVariationType = function (isSelectorEnabled) {
      if (isSelectorEnabled) {
        return 'selector';
      }
      if (
        !isSelectorEnabled &&
        featureService.hasFeature('variation_label_swatches') &&
        mainConfig.merchantData.product_setting.variation_display === 'swatches'
      ) {
        return 'label';
      }
      return 'dropdown';
    };

    this.showPromotionLabel = function (subtotalItem) {
      if (['addon', 'addonproduct'].includes(subtotalItem.object_data.type)) {
        return $filter('translate')('product.addon_products.label');
      } else if (subtotalItem.object_data.type === 'gift') {
        return $filter('translate')('product.gift.label');
      } else if (subtotalItem.type === 'subscription_product') {
        return $filter('translate')('product.subscription_product.label');
      }
      return false;
    };

    this.isItemVariant = function (subtotalItem) {
      return (
        subtotalItem.item_data.variation_data &&
        subtotalItem.item_data.variation_data.key !== ''
      );
    };

    this.getProductItemLink = function (subtotalItem) {
      if (_.isEmpty(subtotalItem)) {
        return;
      }
      if (
        ['addon', 'addonproduct'].includes(subtotalItem.object_data.type) ||
        subtotalItem.object_data.type === 'gift'
      ) {
        return;
      }
      return '/products/' + subtotalItem.item_id;
    };

    this.getCurrentFlashPriceSet = function (product) {
      if (_.isEmpty(product.flash_price_sets)) {
        return {};
      }
      return (
        _.find(product.flash_price_sets, function (flash_price_set) {
          const start_at = dayjs(flash_price_set.start_at);
          const end_at = dayjs(flash_price_set.end_at);
          //fix SL-36611 The flash_price_set is applied to the removed price set,causing in inconsistent amounts in the cart and mini cart. eg: start_at < end_at
          if (dayjs().isAfter(start_at) && dayjs().isBefore(end_at)) {
            return flash_price_set;
          }
        }) || {}
      );
    };

    /** To filter available variation options by current selected */
    this.updateVariationOption = function (
      prevState,
      product,
      fromIndex = 0,
      cache = {},
    ) {
      // MARK: Init `cache`
      if (!cache.matchedVariations) {
        cache.matchedVariations = {};
      }

      if (!cache.optionList) {
        if (product.variations && product.field_titles) {
          const fieldsArray = product.variations.map((variation) =>
            variation.fields.map((field) =>
              $filter('translateModel')(field.name_translations),
            ),
          );
          cache.optionList = product.field_titles.map((field, index) =>
            _.uniq(fieldsArray.map((fieldArr) => fieldArr[index])),
          );
        } else {
          cache.optionList = [];
        }
      }

      // MARK: Filter variations

      const result = {
        optionList: Array.from(prevState.optionList || []),
        selectedList: Array.from(prevState.selectedList || []),
      };
      const length = cache.optionList.length;
      for (let index = fromIndex; index < length; index++) {
        if (index <= fromIndex) {
          result.optionList[index] =
            result.optionList[index] || cache.optionList[index] || [];
          result.selectedList[index] =
            result.selectedList[index] || result.optionList[index][0];
          continue;
        }

        const cacheKey = result.selectedList.slice(0, index).join(',');
        let varationSet = cache.matchedVariations[cacheKey];
        if (!varationSet) {
          const matchedVariations = product.variations.filter((value) => {
            for (var i = 0; i < index; i++) {
              const labelName = $filter('translateModel')(
                value.fields[i].name_translations,
              );
              if (result.selectedList[i] !== labelName) {
                return false;
              }
            }

            return true;
          });

          varationSet = new Set(
            matchedVariations.map((variation) =>
              $filter('translateModel')(
                variation.fields[index].name_translations,
              ),
            ),
          );

          cache.matchedVariations[cacheKey] = varationSet;
        }

        const variations = cache.optionList[index].filter((variationName) =>
          varationSet.has(variationName),
        );

        let selected = result.selectedList[index];
        if (!selected || !variations.includes(selected)) {
          selected = variations[0];
        }

        result.optionList[index] = variations;
        result.selectedList[index] = selected;
      }

      return result;
    };

    const isUnlimitedAmount = (product) =>
      product.preorder_limit === this.PREORDER_UNLIMITED_AMOUNT;

    this.isOutOfStockOrderable = function (product) {
      if (!slFeatureService.hasFeature('preorder_limit')) {
        return product.out_of_stock_orderable;
      }
      if (!product.is_preorder) {
        return false;
      }
      const hasVariants = product.variations?.length > 0;
      if (hasVariants) {
        return product.variations.some(isUnlimitedAmount);
      }
      return isUnlimitedAmount(product);
    };
  },
]);
