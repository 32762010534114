app.directive('variationLabelSelector', [
  '$filter',
  '$window',
  'productStockService',
  function ($filter, $window, productStockService) {
    const MAX_ROW = 2;

    return {
      restrict: 'E',
      scope: {
        index: '=',
        model: '=',
        product: '=',
        onChange: '&',
        isCollapsable: '=?',
      },
      templateUrl: require('../../../../../public/themes/shared/product/templates.product_variation_label_selector.html'),
      link: function (scope, element) {
        scope.shouldLabelDisabled = function (name, index) {
          if (scope.product.unlimited_quantity) return false;

          const hasStock = scope.product.variations.some(
            (variation) =>
              $filter('translateModel')(
                variation.fields[index].name_translations,
              ) === name &&
              productStockService.checkHasStock(scope.product, variation),
          );
          return !hasStock;
        };

        scope.selectVariation = function (name) {
          scope.model[scope.index] = name;
          scope.onChange();
        };

        scope.$watch(
          'product.digest_variations',
          function () {
            scope.options = scope.product.digest_variations[scope.index];
          },
          true,
        );

        // MARK: Collapsable

        scope.isInited = false;
        scope.isCollapsed = false;
        scope.isCollabpeButtonShown = false;
        scope.rowGap = 0;
        scope.unitHeight = 0;
        scope.collapsedHeight = 0;
        scope.originalHeight = 0;

        scope.$watch('isCollapsable', function (current, prev) {
          if (current) {
            scope.isInited = scope.isInited && current === prev;
            return;
          }

          scope.isInited = true;
          scope.isCollapsed = false;
          scope.isCollabpeButtonShown = false;
          scope.rowGap = 0;
          scope.unitHeight = 0;
          scope.collapsedHeight = 0;
          scope.originalHeight = 0;
        });

        scope.$watchCollection(
          (currentScope) => {
            let { isInited, unitHeight, rowGap } = currentScope;
            if (isInited && unitHeight) {
              return { unitHeight, rowGap };
            }

            const placeholder = element.find(
              '.variation-label[data-placeholder=""]',
            )[0];
            if (!placeholder) {
              return { unitHeight, rowGap };
            }

            rowGap = Number(
              window
                .getComputedStyle(placeholder)
                .marginBottom.replace('px', ''),
            );
            unitHeight = placeholder.getBoundingClientRect().height;

            return { rowGap, unitHeight };
          },
          ({ rowGap, unitHeight }) => {
            if (!unitHeight) {
              return;
            }

            scope.rowGap = rowGap;
            scope.unitHeight = unitHeight;
            scope.isInited = true;
            scope.isCollapsed = true;
          },
        );

        const labelContainer = element.find(
          '.variation-label-select__label-container',
        )[0];
        scope.$watch(
          (currentScope) => {
            const { isInited, originalHeight } = currentScope;
            if (!isInited) {
              return originalHeight;
            }
            const labelContainerHeight = labelContainer.getBoundingClientRect()
              .height;
            if (
              isInited &&
              originalHeight &&
              originalHeight === labelContainerHeight
            ) {
              return originalHeight;
            }
            return labelContainerHeight;
          },
          (originHeight) => (scope.originalHeight = originHeight),
        );

        scope.$watchGroup(
          ['rowGap', 'unitHeight', 'originalHeight', 'isCollapsable'],
          function (groupList) {
            const [
              rowGap,
              unitHeight,
              originalHeight,
              isCollapsable,
            ] = groupList;
            const maxHeight = unitHeight * MAX_ROW + rowGap * MAX_ROW;

            scope.collapsedHeight = maxHeight + unitHeight / 2;
            scope.isCollabpeButtonShown =
              isCollapsable && maxHeight < originalHeight;
          },
        );

        // MARK: Collapsable - On Window Resize

        const onWindowResize = _.debounce(function () {
          if (!scope.isCollapsable) {
            return;
          }

          scope.originalHeight = labelContainer.getBoundingClientRect().height;
          scope.$digest();
        }, 50);

        const windowElement = angular.element($window);
        windowElement.on('resize', onWindowResize);
        scope.$on('$destroy', () => {
          windowElement.off('resize', onWindowResize);
        });

        // MARK: Collapsable - Exported Method

        scope.toggleCollapse = function () {
          scope.isCollapsed = !scope.isCollapsed;
        };

        /**
         * @returns {string}
         */
        scope.calculateMaxHeight = function () {
          if (!scope.isInited || !scope.isCollabpeButtonShown) {
            return 'none';
          }

          if (scope.isCollapsed) {
            return scope.collapsedHeight + 'px';
          }

          return scope.originalHeight + 'px';
        };
      },
    };
  },
]);
