app.controller('FacebookQuickSignupController', [
  '$scope',
  'fbService',
  'browserService',
  'slFeatureService',
  function ($scope, fbService, browserService, slFeatureService) {
    $scope.facebook = {
      appId: null,
      sdkVersion: null,
    };

    $scope.init = function (
      facebookAppId,
      facebookSdkVersion,
      triggerMessage,
      platformChannelId,
    ) {
      init(
        facebookAppId,
        facebookSdkVersion,
        triggerMessage,
        platformChannelId,
      );
    };

    var init = function (
      facebookAppId,
      facebookSdkVersion,
      triggerMessage,
      platformChannelId,
    ) {
      $scope.facebook.appId = facebookAppId;
      $scope.facebook.sdkVersion = facebookSdkVersion;
      fbService.setSetting(triggerMessage, platformChannelId);
      fbService.getFacebookSetting().then(function (facebook_setting) {
        if (facebook_setting['app_id']) {
          getFacebookLoginStatus(facebookAppId, facebookSdkVersion);
        } else {
          window.location.replace('/oauth/facebook/quick_sign_up_failure');
        }
      });
    };

    var handleFacebookLogin = function () {
      if (
        browserService.isInAppBrowser() ||
        browserService.isSafariMobileBrowser() ||
        slFeatureService.hasFeature('fb_login_integration')
      ) {
        window.location.replace('/users/auth/facebook?signup=true');
      } else {
        fbService
          .login($scope.facebook.appId, $scope.facebook.sdkVersion)
          .then(function () {
            window.location.replace(
              '/oauth/facebook/quick_sign_up_success?is_new_user=true',
            );
          });
      }
    };

    var getFacebookLoginStatus = function () {
      fbService
        .getFacebookLoginStatus(
          $scope.facebook.appId,
          $scope.facebook.sdkVersion,
        )
        .then(function (facebookResponse) {
          switch (facebookResponse.status) {
            case 'connected':
              fbService
                .loginWithFacebookPayload(facebookResponse)
                .then(function () {
                  window.location.replace(
                    '/oauth/facebook/quick_sign_up_success?is_new_user=false',
                  );
                });
              break;
            case 'not_authorized':
            default:
              handleFacebookLogin();
              break;
          }
        });
    };
  },
]);
