app.controller('FbInAppBrowserPopupController', [
  '$scope',
  '$filter',
  'userAgentService',
  'execCommandService',
  'slFeatureService',
  function (
    $scope,
    $filter,
    userAgentService,
    execCommandService,
    slFeatureService,
  ) {
    const regex = /FBAV\/([\d.]+)/;
    const match = navigator.userAgent.match(regex);
    const fbInAppBrowserVersion = Number(match?.[1]?.substring(0, 5));
    const setCookieCosentPopupDisplay = (value) => {
      const cookieConsentPopup = document.getElementById(
        'cookie-consent-popup',
      );
      if (cookieConsentPopup) {
        cookieConsentPopup.style.display = value;
      }
    };

    $scope.showFbHint =
      !slFeatureService.hasFeature('fb_bug_fixed') &&
      userAgentService.isFacebookBrowser() &&
      userAgentService.isiOS() &&
      fbInAppBrowserVersion >= 399 &&
      fbInAppBrowserVersion <= 400;

    $scope.copyUrl = function () {
      execCommandService.copyToClipboard(window.location.href);

      new PNotify({
        title: $filter('translate')('member_referral.copy.link.success'),
        addclass: 'success facebook-copy-notify',
        animate: {
          animate: true,
          in_class: 'fadeInDown',
          out_class: 'fadeOut',
        },
        delay: 4000,
        icon: 'fa fa-check-circle',
      });
    };

    $scope.handleClose = () => {
      $scope.showFbHint = false;
      setCookieCosentPopupDisplay('block');
    };

    const initController = () => {
      if ($scope.showFbHint) setCookieCosentPopupDisplay('none');
    };

    initController();
  },
]);
