import { CHECKOUT_EVENTS } from '../constants/angular-events';

app.directive('checkoutCartAddons', [
  '$http',
  '$lightbox',
  '$timeout',
  '$window',
  '$rootScope',
  'checkoutService',
  'cartService',
  'trackerService',
  'mainConfig',
  'slFeatureService',
  'logger',
  function (
    $http,
    $lightbox,
    $timeout,
    $window,
    $rootScope,
    checkoutService,
    cartService,
    trackerService,
    mainConfig,
    slFeatureService,
    logger,
  ) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        var isDesktop = checkIsDesktop();
        var scrollbarInstance;
        var carouselInstance;
        var responsiveRule = getResponsiveRule(
          mainConfig.merchantData.current_theme_key,
        );

        element.on('click', '.btn-purchase-addon', function (event) {
          return addAddonItemClick($(event.currentTarget).data('addon'));
        });

        element.on('click', '.addon-product-image', function (event) {
          return scope.enlargeImage($(event.currentTarget).data('img'));
        });

        scope.$on(CHECKOUT_EVENTS.CART.ADDONS.RELOAD, function () {
          $('.cart-addon-items .btn-addon btn-purchase-addon').addClass(
            'disabled',
          );

          return checkoutService
            .requestPartial('cart', 'addons')
            .then(function (res) {
              $('.cart-addon-items').each(function () {
                var target = $(this).find('.cart-addon-items-overlay').first();
                var addonId = target.length ? target.attr('addon-id') : null;
                var htmlFromServer = $(res.data)
                  .find('.cart-addon-items-overlay[addon-id=' + addonId + ']')
                  .siblings('.description')
                  .html();

                if (htmlFromServer) {
                  $(this).find('.description').html(htmlFromServer);
                  $(this)
                    .find('.addon-promotion-name')
                    .dotdotdot({ wrap: 'letter' });

                  if (
                    $('.cart-addon-items .btn-purchase-addon.disabled').length >
                    0
                  ) {
                    $('.cart-addon-container .alert').show();
                  } else {
                    $('.cart-addon-container .alert').hide();
                  }
                } else {
                  // the item has been removed
                  $(this).parent('.owl-item').remove();
                  addScrollBar();
                  addCarousel();
                }
              });
            })
            .catch(function () {
              return logger.log('Unable to load cart addons');
            })
            .finally(function () {
              $('.cart-addon-items .btn-addon btn-purchase-addon').removeClass(
                'disabled',
              );
            });
        });

        var addAddonItemClick = function (itemId) {
          cartService
            .addItem(
              itemId,
              {
                type: 'addon_product',
              },
              undefined,
              {
                skip_calculate_order: false,
                cache_key: mainConfig.currentPath,
                is_cart_page: true,
              },
            )
            .then(function (data) {
              showOverlay(itemId);
              var addOnItem = data.items.find(function (item) {
                return item.product_id === itemId;
              });

              if (addOnItem) {
                var addOnItemProduct = addOnItem.product;

                if (slFeatureService.hasFeature('data_layer_info')) {
                  $rootScope.$emit('add.addon.item.to.cart', [
                    {
                      title: addOnItemProduct.title_translations,
                      sku: addOnItemProduct.sku,
                      currency: mainConfig.merchantData.base_currency_code,
                      quantity: 1,
                      price: addOnItem.price.dollars,
                    },
                  ]);
                }

                trackerService.track({
                  type: trackerService.generalEventType.ADD_TO_CART,
                  data: {
                    items: [
                      {
                        product: addOnItemProduct,
                        value: addOnItem.price.dollars,
                        quantity: addOnItem.quantity,
                        variationSelected: null,
                      },
                    ],
                    eventId: data.event_id,
                  },
                });
              }

              cartService
                .validate()
                .then(function () {
                  scope.$emit(CHECKOUT_EVENTS.CART.ITEMS.CHANGED, {
                    success: true,
                  });
                })
                .catch(function () {
                  scope.$emit(CHECKOUT_EVENTS.CART.ITEMS.CHANGED, {
                    success: false,
                  });
                });
            });
        };

        scope.enlargeImage = function (url) {
          $lightbox.open([{ detail_image_url: url }], 0, { size: 'lg' });
        };

        function checkIsDesktop() {
          return $window.matchMedia('(min-width: 768px)').matches;
        }

        function getResponsiveRule(themeKey) {
          switch (themeKey) {
            case 'chic':
            case 'king':
              return {
                768: { items: 2 },
                1194: { items: 3 },
                1492: { items: 4 },
              };
            case 'bianco':
              return {
                768: { items: 2 },
                992: { items: 2 },
                1100: { items: 3 },
                1440: { items: 4 },
              };
            default:
              return {
                768: { items: 2 },
                992: { items: 3 },
                1200: { items: 4 },
              };
          }
        }

        function addScrollBar() {
          /*
            The addon items will be replaced by partial render after cart reloading,
            so we should destroy original scrollbar then create a new one.
          */
          if (scrollbarInstance) {
            scrollbarInstance.destroy();
            scrollbarInstance = null;
          }
          if (checkIsDesktop()) return; // scroll bar is for mobile only

          scrollbarInstance = $('.cart-addon .addon-items-row')
            .overlayScrollbars({ overflowBehavior: { y: 'hidden' } })
            .overlayScrollbars(); // a hacky way to get the instance
        }

        function addCarousel() {
          /*
            The addon items will be replaced by partial render after cart reloading,
            so we should destroy original carousel then create a new instance.
          */
          if (carouselInstance) {
            carouselInstance.trigger('destroy.owl.carousel');
            carouselInstance.removeClass('owl-carousel owl-theme');
            carouselInstance = null;
          }
          if (!checkIsDesktop()) return; // carousel is for desktop only

          carouselInstance = $('.cart-addon .addon-items-row')
            .addClass('owl-carousel owl-theme')
            .owlCarousel({
              dots: true,
              responsive: responsiveRule,
            });
        }

        var showOverlay = function (itemId) {
          var $overlay = angular.element(
            '.cart-addon-items .cart-addon-items-overlay[addon-id="' +
              itemId +
              '"]:first',
          );

          $overlay.addClass('active');
          $timeout(function () {
            $overlay.removeClass('active');
          }, 3000);
        };

        var resizeEventHandler = _.throttle(
          function () {
            if (isDesktop === checkIsDesktop()) return;

            addScrollBar();
            addCarousel();
            isDesktop = !isDesktop;
          },
          500,
          { leading: false },
        );

        $rootScope.$on('$destroy', function () {
          angular.element($window).off('resize', resizeEventHandler);
        });

        angular.element($window).on('resize', resizeEventHandler);
        addScrollBar();
        addCarousel();
        $('.cart-addon-items .addon-promotion-name').dotdotdot({
          wrap: 'letter',
        });
        element.removeClass('section-loading-placeholder');
      },
    };
  },
]);
