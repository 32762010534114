import { sumBy } from 'lodash-es';

app.directive('productSetBasketOverview', [
  'productService',
  '$filter',
  'imageService',
  '$lightbox',
  '$uibModal',
  'productSetService',
  'productSetCacheService',
  function (
    productService,
    $filter,
    imageService,
    $lightbox,
    $uibModal,
    productSetService,
    productSetCacheService,
  ) {
    return {
      restrict: 'E',
      templateUrl:
        '/themes/v1/default/views/templates.product-set-basket-overview.html',
      link: function (scope) {
        scope.productService = productService;
        scope.childProducts = scope.product?.child_products || [];
        scope.basketCartItems = [];

        function init() {
          // no variants product: auto add to basket
          const childProductWithoutVariants = scope.childProducts.filter(
            (childProduct) =>
              !childProduct.variations || childProduct.variations?.length === 0,
          );
          childProductWithoutVariants.forEach((childProduct) => {
            scope.basketCartItems.push({
              ...childProduct,
              quantity: childProduct.bundle_set?.min_quantity || 0,
            });
          });

          // variants product: add to basket if cache exist
          const variantsCache = productSetCacheService.getProductCache(
            scope.product._id,
            scope.childProducts,
          );
          scope.basketCartItems = scope.basketCartItems.concat(variantsCache);

          if (scope.basketCartItems.length > 0) {
            prepareSelectedProductSetData();
            prepareChildproductData();
            prepareCheckoutPrice();
          }
        }

        init();

        scope.editProductSet = function () {
          const newScope = scope.$new();
          newScope.product = scope.product;
          newScope.childProducts = scope.childProducts;
          newScope.basketCartItems = _.clone(scope.basketCartItems);
          const modal = $uibModal.open({
            templateUrl:
              '/themes/v1/default/views/templates.product-set-basket.html',
            windowClass: 'basket-page-modal-window',
            controller: 'ProductSetBasketController',
            scope: newScope,
            keyboard: false,
          });

          modal.result.then((res) => {
            if (!res) return;

            scope.basketCartItems = res.basketCartItems;
            prepareSelectedProductSetData();
            prepareChildproductData();
            prepareCheckoutPrice();

            if (res.shouldAddToCart) {
              scope.$emit('addProductSetBasketToCart');
            }
          });
        };

        function prepareSelectedProductSetData() {
          scope.selectedProductSetData = scope.basketCartItems.map((item) => {
            return {
              child_product_id: item._id,
              child_variation_id: item.variationSelected
                ? item.variationSelected.key
                : null,
              quantity: item.quantity,
            };
          });
        }

        function prepareChildproductData() {
          scope.childProducts.forEach((childProduct) => {
            const productId = childProduct._id;
            if (childProduct.field_titles?.length > 0) {
              // if has variants
              const matchedItems = scope.basketCartItems.filter(
                (item) => productId === item._id,
              );
              childProduct.variationSelected = matchedItems.map((item) => {
                return {
                  ...item.variationSelected,
                  quantity: item.quantity,
                };
              });
            } else {
              // if no variants
              const matchedItem = scope.basketCartItems.find(
                (item) => productId === item._id,
              );
              childProduct.selectedQuantity = matchedItem.quantity;
            }
          });
        }

        function prepareCheckoutPrice() {
          const totalQuantity = sumBy(
            scope.basketCartItems,
            (item) => item.quantity,
          );
          const totalRequiredQuantity = sumBy(
            scope.childProducts,
            (item) => item.bundle_set?.min_quantity || 1,
          );

          if (totalQuantity === totalRequiredQuantity) {
            scope.product.productSetCheckoutPrice = productSetService.getCheckoutPrice(
              {
                product: scope.product,
                basketCartItems: scope.basketCartItems,
              },
            );
            scope.state.checkoutReady =
              scope.product.productSetCheckoutPrice.cents > 0;
            scope.state.productSetCheckoutReady = true;
          } else {
            scope.state.productSetCheckoutReady = false;
          }
        }

        scope.getImage = function (childProductIndex) {
          const targetChildProduct = scope.childProducts[childProductIndex];
          const media = targetChildProduct.media[0];
          return {
            media,
            url: imageService.getMediaImageUrl(media, { size: '200x' }),
          };
        };

        scope.onProductImageClicked = function (childProductIndex) {
          $lightbox.open([scope.getImage(childProductIndex).media], 0);
        };

        scope.getChildVariationName = productSetService.getChildVariationName;

        scope.isProductReachMinQuantity = function (childProduct) {
          if (scope.basketCartItems.length === 0) return false;
          const itemQuantity = scope.basketCartItems
            .filter((item) => item._id === childProduct._id)
            .reduce((accu, curr) => {
              return accu + curr.quantity;
            }, 0);

          return itemQuantity >= childProduct.bundle_set?.min_quantity;
        };

        scope.isProductOutOfStock = function (childProduct) {
          return !productSetService.checkChildProductStock(childProduct);
        };

        scope.isAllWithoutVariation = function () {
          return scope.childProducts.every(
            (childProduct) => childProduct.variations.length === 0,
          );
        };

        scope.isDisableBtn =
          dayjs().isBefore(scope.product.available_start_time) ||
          dayjs().isAfter(scope.product.available_end_time);

        scope.getBtnText = () => {
          if (dayjs().isBefore(scope.product.available_start_time)) {
            return $filter('translate')('product.coming_soon');
          }

          if (dayjs().isAfter(scope.product.available_end_time)) {
            return $filter('translate')('product.available_time_over');
          }

          return $filter('translate')('product_set.build');
        };
      },
    };
  },
]);
