app.directive('slWishlistButton', [
  '$rootScope',
  'productSetService',
  function ($rootScope, productSetService) {
    return {
      restrict: 'E',
      scope: {
        getTargetWishlistItem: '&',
        product: '=',
        variationSelected: '=',
        wishlistItem: '=',
        isItemInWishlist: '=',
        selectedProductSetData: '=',
      },
      link: function ($scope) {
        function updateView() {
          $scope.isItemInWishlist = checkIsWishlistItemActive();
        }

        var checkIsWishlistItemActive = function () {
          if (!$rootScope.isUserLoggedIn) {
            return false;
          }

          return !_.isEmpty($scope.wishlistItem);
        };

        if ($scope.product.variations.length > 0) {
          $scope.$watch('variationSelected', function () {
            $scope.getTargetWishlistItem();
          });
        } else if (
          productSetService.isProductSetEnabled($scope.product) ||
          productSetService.isProductSetRevampEnabled($scope.product)
        ) {
          $scope.$watch(
            'selectedProductSetData',
            function () {
              $scope.getTargetWishlistItem();
            },
            true,
          );
        } else {
          $scope.getTargetWishlistItem();
        }

        $scope.$watch('wishlistItem', updateView);
      },
    };
  },
]);
