app.directive('categoryFilterTags', [
  '$filter',
  '$location',
  'mainConfig',
  'advanceFilterData',
  'categoryFilterService',
  function (
    $filter,
    $location,
    mainConfig,
    advanceFilterData,
    categoryFilterService,
  ) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: require('../../../../../public/themes/shared/category/templates.category_filter_tags.html'),
      link: function (scope) {
        /*
          search params may look like this:
          scope=advanced_filter&filter_tag[aa][]=111&filter_tag[aa][]=222&filter_tag[bb][]=11&material[]=乳液&min_price=123&max_price=321"
        */
        var initData = function () {
          scope.advanceFilterTypes = categoryFilterService.getFilterTypes();
          scope.baseCurrency =
            mainConfig.merchantData.base_currency.alternate_symbol;
          scope.priceType = ['price_range', 'min_price', 'max_price'];
          scope.groupData = groupDataByType();
          scope.currentSearchParams = $location.search();
          scope.currentFilterTags = getCurrentFilterTags();
        };

        var groupDataByType = function () {
          return advanceFilterData.reduce(function (group, data) {
            var type = data.type;
            if (!group[type + '[]']) {
              group[type + '[]'] = [];
            }
            group[type + '[]'] = group[type + '[]'].concat(data.items);
            return group;
          }, {});
        };

        var getCurrentFilterTags = function () {
          var CurrentFilterTags = [];

          Object.keys(scope.currentSearchParams).forEach(function (key) {
            if (!scope.priceType.includes(key)) {
              var isFilterTag = /filter_tag/.test(key);
              var searchParam = scope.currentSearchParams[key];
              if (scope.advanceFilterTypes.includes(key) || isFilterTag) {
                if (Array.isArray(searchParam)) {
                  searchParam.forEach(function (id) {
                    var tagName = getTagName(isFilterTag, id);
                    if (tagName) {
                      CurrentFilterTags.push({
                        id: isFilterTag ? id : tagName,
                        type: key,
                        name: tagName,
                      });
                    }
                  });
                } else {
                  var tagName = getTagName(isFilterTag, searchParam);
                  if (tagName) {
                    CurrentFilterTags.push({
                      id: searchParam,
                      type: key,
                      name: tagName,
                    });
                  }
                }
              }
            }
          });

          var priceTag;
          if (
            scope.currentSearchParams.min_price &&
            scope.currentSearchParams.max_price
          ) {
            priceTag = {
              type: 'price_range',
              name: $filter('translate')(
                'products.category.advance_filter.tag.price_range',
                {
                  min_price:
                    scope.baseCurrency + scope.currentSearchParams.min_price,
                  max_price:
                    scope.baseCurrency + scope.currentSearchParams.max_price,
                },
              ),
            };
          } else if (scope.currentSearchParams.min_price) {
            priceTag = {
              type: 'min_price',
              name: $filter('translate')(
                'products.category.advance_filter.tag.min_price',
                {
                  min_price:
                    scope.baseCurrency + scope.currentSearchParams.min_price,
                },
              ),
            };
          } else if (scope.currentSearchParams.max_price) {
            priceTag = {
              type: 'max_price',
              name: $filter('translate')(
                'products.category.advance_filter.tag.max_price',
                {
                  max_price:
                    scope.baseCurrency + scope.currentSearchParams.max_price,
                },
              ),
            };
          }
          if (priceTag) {
            CurrentFilterTags.push(priceTag);
          }

          return CurrentFilterTags;
        };

        var getTagName = function (isFilterTag, value) {
          if (isFilterTag) {
            var tagData = scope.groupData['filter_tag[]'].find(function (data) {
              return data.id === value;
            });
            return tagData
              ? $filter('translateModel')(tagData.content_translations)
              : value;
          }

          return value;
        };

        scope.removeFilterTag = function (tag) {
          if (scope.priceType.includes(tag.type)) {
            if (tag.type === 'price_range') {
              delete scope.currentSearchParams.min_price;
              delete scope.currentSearchParams.max_price;
            } else {
              delete scope.currentSearchParams[tag.type];
            }
          } else {
            if (Array.isArray(scope.currentSearchParams[tag.type])) {
              var removeTagIndex = scope.currentSearchParams[
                tag.type
              ].findIndex(function (id) {
                return tag.id === id;
              });
              scope.currentSearchParams[tag.type].splice(removeTagIndex, 1);
              if (!scope.currentSearchParams[tag.type].length) {
                delete scope.currentSearchParams[tag.type];
              }
            } else {
              delete scope.currentSearchParams[tag.type];
            }
          }

          var hasFilterType = Object.keys(scope.currentSearchParams).some(
            function (key) {
              var isFilterTag = /filter_tag/.test(key);
              return scope.advanceFilterTypes.includes(key) || isFilterTag;
            },
          );

          if (!hasFilterType) {
            delete scope.currentSearchParams['scope'];
          }

          $location.search(scope.currentSearchParams);
          window.location.href = $location.absUrl();
        };

        scope.removeAllFilterTag = function () {
          Object.keys(scope.currentSearchParams).forEach(function (key) {
            var isFilterTag = /filter_tag/.test(key);
            if (scope.advanceFilterTypes.includes(key) || isFilterTag) {
              delete scope.currentSearchParams[key];
            }
          });
          delete scope.currentSearchParams['scope'];
          $location.search(scope.currentSearchParams);
          window.location.href = $location.absUrl();
        };

        if (advanceFilterData) {
          initData();
        }
      },
    };
  },
]);
