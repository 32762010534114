import {
  TCAT_SEVEN_ELEVEN_EMAP_ONLY_REGION_TYPE,
  SL_LOGISTICS_TCAT_SEVEN_ELEVEN_ROOMTEMP_TYPE,
  SL_LOGISTICS_TCAT_SEVEN_ELEVEN_REFRIGERATED_TYPE,
  SL_LOGISTICS_TCAT_SEVEN_ELEVEN_FROZEN_TYPE,
} from '../constants/delivery';

function tcatSevenElevenService(browserService) {
  const SL_LOGISTICS_TCAT_SEVEN_ELEVEN_ROOMTEMP_TYPES = Object.values(
    SL_LOGISTICS_TCAT_SEVEN_ELEVEN_ROOMTEMP_TYPE,
  );
  const SL_LOGISTICS_TCAT_SEVEN_ELEVEN_REFRIGERATED_TYPES = Object.values(
    SL_LOGISTICS_TCAT_SEVEN_ELEVEN_REFRIGERATED_TYPE,
  );
  const SL_LOGISTICS_TCAT_SEVEN_ELEVEN_FROZEN_TYPES = Object.values(
    SL_LOGISTICS_TCAT_SEVEN_ELEVEN_FROZEN_TYPE,
  );
  const TCAT_SEVEN_ELEVEN_EMAP_TYPES = [
    ...Object.values(TCAT_SEVEN_ELEVEN_EMAP_ONLY_REGION_TYPE),
    ...SL_LOGISTICS_TCAT_SEVEN_ELEVEN_ROOMTEMP_TYPES,
    ...SL_LOGISTICS_TCAT_SEVEN_ELEVEN_REFRIGERATED_TYPES,
    ...SL_LOGISTICS_TCAT_SEVEN_ELEVEN_FROZEN_TYPES,
  ];
  const TCAT_SEVEN_EMAP_URL = {
    desktop: 'https://appservice.ezcat.com.tw/Map.aspx',
    mobile: 'https://appservice.ezcat.com.tw/MobileMap.aspx',
  };

  const isTcatSevenElevenEmapType = (regionType) =>
    TCAT_SEVEN_ELEVEN_EMAP_TYPES.includes(regionType);

  const isSlLogisticsTcatSevenElevenRoomtempType = (regionType) => {
    return SL_LOGISTICS_TCAT_SEVEN_ELEVEN_ROOMTEMP_TYPES.includes(regionType);
  };

  const isSlLogisticsTcatSevenElevenRefrigeratedType = (regionType) => {
    return SL_LOGISTICS_TCAT_SEVEN_ELEVEN_REFRIGERATED_TYPES.includes(
      regionType,
    );
  };

  const isSlLogisticsTcatSevenElevenFrozenType = (regionType) => {
    return SL_LOGISTICS_TCAT_SEVEN_ELEVEN_FROZEN_TYPES.includes(regionType);
  };

  const getEMapUrl = () => {
    return browserService.isMobile()
      ? TCAT_SEVEN_EMAP_URL.mobile
      : TCAT_SEVEN_EMAP_URL.desktop;
  };

  const getEMapCallbackUrl = () =>
    `${window.location.origin}/callback?isSameTab=true`;

  return {
    isTcatSevenElevenEmapType,
    isSlLogisticsTcatSevenElevenRoomtempType,
    isSlLogisticsTcatSevenElevenRefrigeratedType,
    isSlLogisticsTcatSevenElevenFrozenType,
    getEMapUrl,
    getEMapCallbackUrl,
  };
}

app.service('tcatSevenElevenService', [
  'browserService',
  tcatSevenElevenService,
]);
