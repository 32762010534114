import { PLATFORM } from '../../constants/benchat';

app.directive('smsMessengerCheckbox', [
  '$q',
  'benchatService',
  'smsSubscriptionService',
  function ($q, benchatService, smsSubscriptionService) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.sms-messenger-checkbox.html'),
      scope: {
        smsMessengerCheckbox: '=',
        customerId: '@',
        topicStr: '@',
        benchatSubscriptions: '=',
        decorateHandleClick: '=',
      },
      link: function (scope) {
        scope.sms = scope.smsMessengerCheckbox;
        scope.topics = scope.topicStr.split(',');
        scope.subscriptions = []; // would only store active subscriptions

        const getSubscriptions = function () {
          return benchatService
            .getSubscriptions(scope.customerId)
            .then(function (result) {
              const subscriptions = result.data.subscriptions;
              // to update parent`s benchat subscription
              if (subscriptions.length > 0) {
                scope.benchatSubscriptions = angular.extend(
                  scope.benchatSubscriptions,
                  subscriptions,
                );
              }
              scope.subscriptions = smsSubscriptionService.getActiveSubscriptions(
                scope.topics,
                subscriptions,
              );
              scope.sms.isChecked =
                scope.subscriptions.length === scope.topics.length;
            });
        };
        getSubscriptions();

        const deactivateSubscriptions = () =>
          $q.all(
            scope.subscriptions.map((subscription) =>
              benchatService.deactivateSubscription(subscription._id),
            ),
          );

        const activateSubscriptions = () =>
          benchatService
            .activateSubscriptionRevamp(PLATFORM.SMS.toLowerCase())
            .catch((error) => {
              // TODO: handle error
              console.error(error);
            });

        scope.handleClick = function () {
          const toggleSubscriptions = scope.sms.isChecked
            ? activateSubscriptions
            : deactivateSubscriptions;
          toggleSubscriptions().then(getSubscriptions);
        };
      },
    };
  },
]);
