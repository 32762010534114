app.directive('checkoutOmiseCreditCardForm', [
  '$filter',
  'mainConfig',
  'logger',
  function ($filter, mainConfig, logger) {
    return {
      restrict: 'A',
      scope: '=',
      link: function (scope) {
        return $.getScript(
          mainConfig.shoplinePaymentCashierSDKUrl,
          function () {
            var SUCCESS_RESPONSE_CODE = '10000';
            var form = document.getElementById(
              'checkout-omise-credit-card-form',
            );
            var publicKey = form.getAttribute('public-key');
            var deviceType = /iPad/.test(navigator.userAgent)
              ? 'pad'
              : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                  navigator.userAgent,
                )
              ? 'phone'
              : 'pc';

            scope.paymentElement = new paymentElement.default(
              'checkout-omise-credit-card-form',
              {
                loopTime: 6000,
                env: form.getAttribute('env'),
                deviceTag: deviceType,
                appId: 'sl1',
                channelInfo: {
                  chId: 'Omise',
                  payMethod: 'Card',
                  extra: {
                    publicKey: publicKey,
                  },
                },
                merchant: {
                  merchantId: mainConfig.merchantData._id,
                  merchantSiteCountry:
                    mainConfig.merchantData.base_country_code,
                  settlementCurrency:
                    mainConfig.merchantData.base_currency_code,
                },
                themeData: {
                  version: 'v1',
                },
                lang: {
                  formComponent: {
                    holderName: {
                      labelTitle: $filter('translate')(
                        'order.payment_data.holdername',
                      ),
                      placeholder: $filter('translate')(
                        'order.payment_data.holdername',
                      ),
                      errorTips: $filter('translate')(
                        'form.validation.required',
                        {
                          field_name: $filter('translate')(
                            'order.payment_data.holdername',
                          ),
                        },
                      ),
                      tips: $filter('translate')('payment.cc.holdername.hint'),
                    },
                    cardNumber: {
                      labelTitle: $filter('translate')(
                        'order.payment_data.credit_card_number',
                      ),
                      placeholder: $filter('translate')(
                        'order.payment_data.credit_card_number',
                      ),
                      errorTips: $filter('translate')(
                        'form.validation.required',
                        {
                          field_name: $filter('translate')(
                            'order.payment_data.credit_card_number',
                          ),
                        },
                      ),
                      notSupportTips: $filter('translate')(
                        'checkout.card_type_not_support_error',
                      ),
                    },
                    expiryDate: {
                      labelTitle: $filter('translate')(
                        'order.payment_data.expiry_date',
                      ),
                      placeholder: 'MM/YY',
                      errorTips: $filter('translate')(
                        'form.validation.required',
                        {
                          field_name: $filter('translate')(
                            'order.payment_data.expiry_date',
                          ),
                        },
                      ),
                    },
                    cvv: {
                      labelTitle: $filter('translate')(
                        'order.payment_data.cvc',
                      ),
                      placeholder: $filter('translate')(
                        'order.payment_data.cvc',
                      ),
                      errorTips: $filter('translate')(
                        'form.validation.required',
                        {
                          field_name: $filter('translate')(
                            'order.payment_data.cvc',
                          ),
                        },
                      ),
                      tips: $filter('translate')('payment.cc.cvc.hint'),
                    },
                  },
                },
              },
            );

            scope.paymentElement.clientEventBus.addEventListener(
              'init',
              function (event) {
                if (event.code === SUCCESS_RESPONSE_CODE) {
                  logger.log(event);
                } else {
                  logger.error(event.msg || event, event);
                }
              },
            );

            scope.paymentElement.clientEventBus.addEventListener(
              'update',
              function (event) {
                if (event.code !== SUCCESS_RESPONSE_CODE) {
                  scope.btnPlaceOrder.stop();
                  scope.state.isCheckoutLoading = false;
                  scope.errors.checkout = $filter('translate')(
                    'checkout.payments_transaction_error',
                  );
                  scope.renderErrors();
                  logger.error(event.msg || event, event);
                }
              },
            );
          },
        );
      },
    };
  },
]);
