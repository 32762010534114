import { isEmpty, get, matches } from 'lodash-es';

const PROMOTION_I18N_BADGE = {
  promotion: 'orders.promotion_type.promotion',
  reward_credit: 'orders.promotion_type.reward_credit',
  bundle_pricing: 'orders.promotion_type.bundle_pricing',
  bundle_percentage: 'orders.promotion_type.bundle_percentage',
  bundle_amount: 'orders.promotion_type.bundle_amount',
  bundle_gift: 'orders.promotion_type.bundle_gift',
  bundle_group: 'orders.promotion_type.bundle_group',
  bundle_group_percentage: 'orders.promotion_type.bundle_group_percentage',
  bundle_group_amount: 'orders.promotion_type.bundle_group_amount',
  bundle_group_gift: 'orders.promotion_type.bundle_group_gift',
  buyandget_free: 'orders.promotion_type.buyandget_free',
  buyandget_pricing: 'orders.promotion_type.buyandget_pricing',
  buyandget_percentage: 'orders.promotion_type.buyandget_percentage',
  coupon: 'orders.promotion_type.coupon',
  membership_offer: 'orders.promotion_type.membership_offer',
  custom_discount: 'orders.promotion_type.custom_discount',
  member_point_redeem_gift: 'orders.promotion_type.member_point_redeem_gift',
  free_shipping: 'orders.promotion_type.free_shipping',
  credit_reward: 'orders.promotion_type.credit_reward',
};

app.controller('MemberCenterCheckoutObjectDetailController', [
  '$scope',
  '$sanitize',
  '$uibModalInstance',
  'slFeatureService',
  '$filter',
  'memberOrdersService',
  'mainConfig',
  '$timeout',
  'moneyService',
  function (
    $scope,
    $sanitize,
    $uibModalInstance,
    slFeatureService,
    $filter,
    memberOrdersService,
    mainConfig,
    $timeout,
    moneyService,
  ) {
    $scope.PROMOTION_I18N_BADGE = PROMOTION_I18N_BADGE;
    $scope.generalPromotionItems = [];
    $scope.redeemGiftPromotionItems = [];
    $scope.creditRewardPromotionItems = [];
    $scope.creditPointTriggerpointEnabled = slFeatureService.hasFeature(
      'credit_point_triggerpoint',
    );
    $scope.autoCreditEnabled = slFeatureService.hasFeature('auto_credit');
    $scope.checkoutObject = null;
    $scope.state = {
      atTop: true,
      atBottom: false,
      isLoading: true,
    };

    $scope.close = function () {
      $uibModalInstance.close();
    };

    window.addEventListener('message', (event) => {
      const regex = /^https:\/\/[\w-]+.myshopline.com/;
      if (!event.origin.match(regex)) {
        return;
      }
      $('#shopline_payment_iframe_url').css('height', event.data.height);
    });

    $scope.$watch('checkoutObject', function (newVal) {
      if (isEmpty(newVal)) {
        return;
      }

      $scope.viewableRoundingItems = newVal.rounding_difference_items?.filter(
        (item) => item.total.cents > 0,
      );

      const generalPromotionItems = [];
      const redeemGiftPromotionItems = [];
      const creditRewardPromotionItems = [];
      newVal.promotion_items.forEach((item) => {
        switch (item?.promotion_data?.discount_type) {
          case 'member_point_redeem_gift':
            redeemGiftPromotionItems.push(item);
            break;
          case 'credit_reward':
            creditRewardPromotionItems.push(item);
            break;
          default:
            generalPromotionItems.push(item);
        }
      });

      $scope.generalPromotionItems = generalPromotionItems;
      $scope.redeemGiftPromotionItems = redeemGiftPromotionItems;
      $scope.creditRewardPromotionItems = creditRewardPromotionItems;
    });

    $scope.getCheckoutObjectDetail = function () {
      $scope.state.isLoading = true;

      memberOrdersService
        .getCheckoutObject($scope.checkoutObjectId)
        .then(function (response) {
          $scope.checkoutObject = response.data.data;

          $timeout(() => handleScrollWrapper(), 50);
        })
        .finally(function () {
          $scope.state.isLoading = false;
        });
    };

    $scope.getCheckoutObjectDetail();

    function scrollEvent(event) {
      const WRAPPER_PADDING = 16;
      const { scrollTop, scrollHeight, clientHeight } = event.target;

      $scope.state.atTop = scrollTop <= WRAPPER_PADDING;
      $scope.state.atBottom =
        scrollTop + clientHeight >= scrollHeight - WRAPPER_PADDING;
      $scope.$digest();
    }

    function handleScrollWrapper() {
      const $element = angular.element(document);
      const $scrollWrapper = $element.find(
        '.checkout-object-detail__body-scroll-wrapper',
      );

      $scrollWrapper[0].addEventListener(
        'scroll',
        _.throttle(scrollEvent, 200),
      );
    }

    // promotions scope
    $scope.shouldShowGeneralPromotions = function () {
      return $scope.generalPromotionItems.length > 0;
    };

    $scope.shouldShowRedeemGiftPromotions = function () {
      return $scope.redeemGiftPromotionItems.length > 0;
    };

    $scope.shouldShowRewardCreditPromotions = function () {
      return $scope.creditRewardPromotionItems.length > 0;
    };

    $scope.shouldShowCustomDiscount = function () {
      return $scope.checkoutObject.custom_discount_items.length > 0;
    };

    $scope.getPromotionDiscountType = function (promotionItem) {
      return (
        promotionItem?.discount_type ??
        promotionItem?.promotion?.discount_type ??
        'promotion'
      );
    };

    $scope.getPromotionBadge = function (promotionItem) {
      if (promotionItem?.coupon_code) {
        return promotionItem?.coupon_code;
      }

      if (promotionItem?.is_membership_tier_promotion) {
        return $filter('translate')(
          $scope.PROMOTION_I18N_BADGE.membership_offer,
        );
      }

      const discountType = $scope.getPromotionDiscountType(promotionItem);
      // free shipping promotion has cart tag name translations
      if (discountType === 'free_shipping') {
        return (
          $scope.getCartTagName(promotionItem.cart_tag_translations) +
          '-' +
          $filter('translate')($scope.PROMOTION_I18N_BADGE[discountType])
        );
      }

      const badgeI18nKey =
        $scope.PROMOTION_I18N_BADGE[discountType] ||
        $scope.PROMOTION_I18N_BADGE.promotion;
      return $filter('translate')(badgeI18nKey);
    };

    $scope.getPromotionDescription = function (promotionItem) {
      const descriptionTranslation =
        promotionItem?.summary_translations ??
        promotionItem?.title_translations;

      return $sanitize($filter('translateModel')(descriptionTranslation));
    };

    $scope.getCustomDiscountLabel = function (customDiscountItem) {
      return (
        customDiscountItem?.custom_price?.label ??
        customDiscountItem?.total?.label
      );
    };

    $scope.shouldShowAutoReward = function () {
      return (
        $scope.autoCreditEnabled &&
        $scope.checkoutObject?.auto_reward_credit_summary?.triggered
      );
    };

    $scope.getAutoRewardCreditDescription = function () {
      const {
        accumulated_type,
        credit_threshold,
        credit_value,
        is_accumulated,
      } = $scope.checkoutObject?.auto_reward_credit_summary || {};

      const translationKey =
        accumulated_type === 'percentage' || !is_accumulated
          ? 'orders.reward_credit.auto_reward'
          : 'orders.reward_credit.accumulated_auto_reward';

      return $sanitize(
        $filter('translate')(translationKey, {
          credit_threshold,
          credit_value,
        }),
      );
    };

    $scope.shouldShowAppliedPromotionsSection = function () {
      return (
        $scope.shouldShowGeneralPromotions() ||
        $scope.shouldShowRedeemGiftPromotions() ||
        $scope.shouldShowCustomDiscount() ||
        $scope.showAutoReward()
      );
    };

    // summary scope
    $scope.shouldShowSummaryColumn = function (column) {
      return !isEmpty(column) && column.cents > 0;
    };

    $scope.shouldShowRedeemedPoints = function () {
      return $scope.checkoutObject?.member_point_summary?.redeemed_points > 0;
    };

    $scope.shouldShowEarnedPoints = function () {
      const { pending_days, earned_points } =
        $scope.checkoutObject?.member_point_summary || {};
      return pending_days !== undefined && earned_points != 0;
    };

    $scope.getCartTagName = function (order) {
      if (
        order.order_delivery?.platform === 'pos' ||
        mainConfig.merchantData.checkout_setting?.multi_checkout?.enabled !==
          true
      ) {
        return '';
      }

      return (
        $filter('translateModel')(order.cart_tag?.name_translations) ||
        $filter('translate')('multi_checkout.cart_tag_unnamed')
      );
    };

    $scope.getMoneyFormatLabel = function (moneyObject) {
      return moneyService.toMoney(moneyObject).label;
    };

    $scope.getDeliveryFeeDisplay = function (order) {
      const cartId = get(order, 'cart_tag._id', '');
      const hasFreeDeliveryPromotion = $scope.checkoutObject?.promotion_items.find(
        matches({
          cart_tag_id: cartId,
          promotion: { discount_type: 'free_shipping' },
        }),
      );

      if (hasFreeDeliveryPromotion) {
        return $filter('translate')('checkout.labels.promotion.free_shipping');
      }

      return moneyService.toMoney(order.order_delivery.total).label;
    };

    $scope.earnedPointsTitle = function () {
      const { order_created_by, pending_days } =
        $scope.checkoutObject?.member_point_summary || {};

      if ($scope.creditPointTriggerpointEnabled) {
        return $filter('translate')(
          'orders.member_point.settled_after_order_completed',
        );
      }

      const translationKey =
        order_created_by === 'pos'
          ? 'orders.member_point.pos_settled_content'
          : 'orders.member_point.settled_after_content';

      return $filter('translate')(translationKey, { pending_days });
    };
  },
]);
