app.controller('CommentsFormController', [
  '$rootScope',
  '$scope',
  '$http',
  '$uibModalStack',
  '$upload',
  'order',
  '$filter',
  'RecaptchaService',
  'pnotifyService',
  'locationService',
  function (
    $rootScope,
    $scope,
    $http,
    $uibModalStack,
    $upload,
    order,
    $filter,
    RecaptchaService,
    pnotifyService,
    locationService,
  ) {
    //$scope.commentForm.submitted = false;

    $scope.recordId = order._id;
    $scope.recordType = 'Order';
    $scope.comment = {
      owner_type: 'User',
      trackable_id: $scope.recordId,
      trackable_type: 'Order',
      owner_id: order.customer_id,
    };

    // $scope.$on('userService.update', function( event, data ) {
    //   if (userService.isUserLoggedIn) {
    //   } else {

    //   }
    // });

    $scope.submitCommentForm = function () {
      $scope.commentForm.submitted = true;
      if (!$scope.commentForm.$valid) {
        RecaptchaService.reset('form-submit-recaptcha');
        return;
      }

      $scope.loading = true;
      $http({
        method: 'POST',
        url:
          '/api/orders/' +
          order._id +
          '/comments?performer_id=' +
          order.customer_id +
          '&authorization_token=' +
          locationService.getQueryParams().authorization_token,
        data: {
          comment: $scope.comment,
          recaptchable: true,
        },
      })
        .then(function (res) {
          $scope.loading = false;
          // if (data.result == false) {
          //   $scope.errorMessage = data.message;
          //   return;
          // }
          $rootScope.$broadcast('comment.created', res.data);
          // $rootScope.trackEvent('Shopper Action', {"Action Name":"Comment Added","Target Type": 'Order',"Comment Type": "Text"});
          $scope.comment.text = '';
          $scope.deleteMedia();
          $scope.commentForm.submitted = false;
          $uibModalStack.dismissAll();
        })
        .catch(function (res) {
          pnotifyService.notify(
            $filter('translate')(
              'order_comment_and_message.' + res.data.message,
            ),
            {
              customClass: 'error',
              icon: 'fa fa-exclamation-triangle',
            },
          );
          $scope.loading = false;
        })
        .finally(function () {
          $scope.imageUploading = false;
          RecaptchaService.reset('form-submit-recaptcha');
        });
    };

    $scope.deleteMedia = function () {
      $scope.comment.media = null;
      $scope.comment.media_id = null;
      RecaptchaService.reset('select-media-recaptcha');
    };

    $scope.validateMediaRecaptcha = function ($files) {
      if ($files.length === 0) {
        return;
      }
      $scope.tempFiles = $files;
      RecaptchaService.reset('select-media-recaptcha');
      RecaptchaService.execute('select-media-recaptcha');
    };

    $scope.onMediaRecaptchaSuccess = function () {
      $scope.onFileSelect($scope.tempFiles);
    };

    $scope.onFileSelect = function ($files) {
      $scope.flash = [];
      if ($files.length > 1) {
        // why is this 4?
        $scope.flash.push({
          type: 'error',
          text: 'You may only upload up to 1 files',
        });
        return;
      }

      $scope.imageUploading = true;

      for (var i = 0; i < $files.length; i++) {
        $scope.errorMessage = '';

        var file = $files[i];

        var extension = file.name.split('.').pop();
        //console.log(extension);
        if (/jpg|png|jpeg/.test(extension.toLowerCase())) {
          $scope.imageUploading = true;
          $scope.imageResize(file);
        } else {
          $scope.imageUploading = false;
          $scope.errorMessage = $filter('translate')('media.validation.error');
        }
      }
    };

    $scope.imageResize = function (file) {
      var imageLoaded = function (canvas) {
        if (typeof canvas.toDataURL === 'function') {
          var canvasData = canvas.toDataURL('image/jpeg', 0.6);
          var blob = $scope.dataUrlToBlob(canvasData);
          $scope.uploadMedia(blob);
        } else {
          $scope.errorMessage = $filter('translate')('media.validation.error');
          $scope.loading = false;
          $scope.imageUploading = false;
        }
      };
      var options = {
        maxWidth: 900,
        maxHeight: 900,
        canvas: true,
      };
      //console.log("shit got real");
      loadImage.parseMetaData(
        file,
        function (data) {
          if (!data.imageHead) {
            if (!loadImage(file, imageLoaded, options)) {
              //  console.log("shit got real");
              //$scope.errorMessage="THIS NO IMAGE 23";
              $scope.loading = false;
              $scope.imageUploading = false;
              return;
            }
            return;
          }
          var orient = false;
          if (
            !angular.isUndefined(data.exif) &&
            typeof data.exif.get === 'function'
          ) {
            orient = data.exif.get('Orientation');
            //console.log(orient);
          }
          options = {
            maxWidth: 900,
            maxHeight: 900,
            canvas: true,
            orientation: orient,
          };

          if (!loadImage(file, imageLoaded, options)) {
            //console.log("I WAS HERE 23");
          }
        },
        {
          maxMetaDataSize: 262144,
          disableImageHead: false,
        },
      );
    };

    $scope.uploadMedia = function (image) {
      $scope.upload = $upload
        .upload({
          url: '/api/media',
          method: 'POST',
          data: {
            media: {
              status: 'temp',
              trackable_type: 'Order',
            },
            recaptchable: true,
          },
          file: image,
          fileFormDataName: 'media[image_clip]',
          formDataAppender: function (fd, key, val) {
            if (angular.isArray(val)) {
              angular.forEach(val, function (v) {
                fd.append(key, v);
              });
            } else if (angular.isObject(val)) {
              angular.forEach(val, function (value, idx2) {
                fd.append(key + '[' + idx2 + ']', value);
              });
            } else {
              fd.append(key, val);
            }
          },
        })
        .progress(function () {})
        .then(function (res) {
          var data = res.data;
          $scope.comment.media = data.data;
          $scope.comment.media_id = data.data._id;

          // $http({
          //   method: 'POST',
          //   url: '/api/orders/' + order._id + '/comments?performer_id=' + order.customer_id,
          //   data:{comment:$scope.comment,}
          // }).then(function(data,status) {
          $scope.imageUploading = false;
          // $rootScope.$broadcast("comment.created", data );
          // $scope.comment.text="";
          // $scope.comment.media = undefined;
          // $scope.comment.media_id = undefined;
          // $scope.commentForm.submitted = false;
          // $uibModalStack.dismissAll();
          // }).error(function(data, status, headers, config) {
          //   $scope.imageUploading= false;
          //   $scope.errorMessage = data;
          // })
        })
        .catch(function () {
          $scope.imageUploading = false;
          $scope.errorMessage = $filter('translate')('media.validation.error');
        })
        .finally(function () {
          RecaptchaService.reset('select-media-recaptcha');
        });
    };

    $scope.dataUrlToBlob = function (dataURL) {
      var BASE64_MARKER = ';base64,';
      var parts;
      var contentType;
      var raw;
      if (dataURL.indexOf(BASE64_MARKER) == -1) {
        parts = dataURL.split(',');
        contentType = parts[0].split(':')[1];
        raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: contentType });
      }

      parts = dataURL.split(BASE64_MARKER);
      contentType = parts[0].split(':')[1];
      raw = window.atob(parts[1]);
      var rawLength = raw.length;
      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    };
  },
]);
