app.directive('checkoutShoplinePaymentV2', [
  'logger',
  'slPaymentService',
  'cart',
  '$rootScope',
  'slFeatureService',
  'creditCard',
  function (
    logger,
    slPaymentService,
    cart,
    $rootScope,
    slFeatureService,
    creditCard,
  ) {
    return {
      restrict: 'A',
      scope: {
        onSdkInit: '&',
        useShoplinePaymentQuickPayment: '=?',
      },
      link: function (scope) {
        try {
          const isSubscriptionOrder = cart.items?.some(
            (item) => item.type === 'subscription_product',
          );

          const paymentConfig = {
            ...slPaymentService.getDefaultPaymentConfig(cart.payment_method),
            amount:
              cart.total.currency_iso === 'TWD'
                ? cart.total.cents * 100
                : cart.total.cents,
            customerToken: '',
            paymentInstrument: {
              bindCard: {
                enable: false,
                protocol: {
                  mustAccept: false,
                  textType: {
                    paymentAgreement: false,
                  },
                  defaultSwitchStatus: false,
                  switchVisible: false,
                },
              },
            },
          };

          const customerTokenTimeRecord = {
            beforeCustomerT0k3n: '',
            afterCustomerT0k3n: '',
            beforeSDKinit: '',
            renderSDKError: null,
            customer_T0k3n: '',
          };

          const renderSDK = (paymentConfig) => {
            slPaymentService
              .renderSDK({
                paymentConfig,
                elementId: '#checkout-shopline-payment-v2-form',
              })
              .then((data) => {
                scope.onSdkInit({ value: data });
              })
              .catch((error) => {
                customerTokenTimeRecord.renderSDKError = error;
                logger.error(
                  'customerTokenTimeRecord:',
                  customerTokenTimeRecord,
                );
              });
          };

          if (
            !slFeatureService.hasFeature('slp_remember_credit_card') ||
            cart.payment_method.config_data.shopline_payment_payment !==
              'credit' ||
            cart.payment_method.config_data?.installments.length > 0
          ) {
            renderSDK(paymentConfig);
            return;
          }

          const initBeforeRenderSDK = async () => {
            if ($rootScope.currentUser) {
              const paymentInstrumentsRes = await slPaymentService.getPaymentInstruments();
              customerTokenTimeRecord.beforeCustomerT0k3n = new Date();
              const tokenRes = await slPaymentService.getCustomerToken();
              customerTokenTimeRecord.afterCustomerT0k3n = new Date();
              const cardList = paymentInstrumentsRes.data;
              if (tokenRes?.data?.customer_token) {
                paymentConfig.customerToken = tokenRes.data.customer_token;
                paymentConfig.paymentInstrument.bindCard.enable = true;
                scope.useShoplinePaymentQuickPayment = false;
              }

              if (cardList.length < creditCard.CARD_LIST_LIMIT) {
                paymentConfig.paymentInstrument.bindCard.protocol.textType.paymentAgreement = true;
                paymentConfig.paymentInstrument.bindCard.protocol.switchVisible = true;
                scope.useShoplinePaymentQuickPayment = true;
              }

              if (cardList.length === creditCard.CARD_LIST_LIMIT) {
                scope.useShoplinePaymentQuickPayment = true;
              }

              if (isSubscriptionOrder) {
                paymentConfig.paymentInstrument = {
                  bindCard: {
                    enable: true,
                    protocol: {
                      mustAccept: true,
                      textType: {
                        paymentAgreement: true,
                        subscribeAgreement: true,
                      },
                      defaultSwitchStatus: false,
                      switchVisible: true,
                    },
                  },
                };
              }

              Object.assign(customerTokenTimeRecord, {
                beforeSDKinit: new Date(),
                customer_T0k3n: paymentConfig.customerToken,
              });
              renderSDK(paymentConfig);
            } else {
              if (isSubscriptionOrder) {
                paymentConfig.paymentInstrument = {
                  bindCard: {
                    enable: true,
                    protocol: {
                      mustAccept: true,
                      textType: {
                        paymentAgreement: false,
                        subscribeAgreement: true,
                      },
                      defaultSwitchStatus: true,
                      switchVisible: false,
                    },
                  },
                };
              }

              renderSDK(paymentConfig);
            }
          };

          initBeforeRenderSDK();
        } catch (e) {
          logger.error(e);
        }
      },
    };
  },
]);
