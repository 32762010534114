app.directive('productReviewCommentsMediaUpload', [
  '$upload',
  '$filter',
  '$timeout',
  'pnotifyService',
  'mainConfig',
  'RecaptchaService',
  function (
    $upload,
    $filter,
    $timeout,
    pnotifyService,
    mainConfig,
    RecaptchaService,
  ) {
    return {
      restrict: 'E',
      scope: {
        medias: '=',
        itemId: '@',
      },
      templateUrl: require('../../../../../public/themes/shared/product_review_comments/templates.product_review_comments_media_upload.html'),
      link: function ($scope, element) {
        $scope.isRecaptchaEnabled = mainConfig.isRecaptchaEnabled;
        $scope.recaptchaElementId = `review-upload-media-recaptcha-${$scope.itemId}`;

        $scope.openFile = function () {
          if ($scope.imageUploading) {
            return;
          }
          var input = element.find('input');
          input.trigger('click');
        };

        $scope.deleteMedia = function (media) {
          var index = $scope.medias.indexOf(media);
          $scope.medias.splice(index, 1);
        };

        $scope.onFileSelect = function ($files) {
          $scope.flash = [];
          if ($files.length == 0) {
            return;
          }

          if ($files.length > 1) {
            pnotifyService.notify(
              $filter('translate')('media.validation.file_limit.one'),
              {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              },
            );
            return;
          }

          $scope.imageUploading = true;

          for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            $scope.uploadMedia(file);
          }
        };

        $scope.validateMediaRecaptcha = function ($files) {
          if ($files.length === 0) {
            return;
          }
          $scope.tempFiles = $files;
          RecaptchaService.execute($scope.recaptchaElementId);
        };

        $scope.onReviewMediaRecaptchaSuccess = function () {
          $scope.onFileSelect($scope.tempFiles);
        };

        $scope.onValidateFileSelect = function ($files) {
          if ($scope.isRecaptchaEnabled) {
            window.onReviewMediaRecaptchaSuccess = function (token) {
              $scope.recaptchaToken = token;
              $scope.onReviewMediaRecaptchaSuccess();
            };

            $scope.validateMediaRecaptcha($files);
          } else {
            $scope.onFileSelect($files);
          }
        };

        $scope.uploadMedia = function (image) {
          $scope.upload = $upload
            .upload({
              url: '/api/media',
              method: 'POST',
              data: {
                recaptchable: true,
              },
              file: image,
              fileFormDataName: 'media[image_clip]',
              formDataAppender: function (fd, key, val) {
                if (angular.isArray(val)) {
                  angular.forEach(val, function (v) {
                    fd.append(key, v);
                  });
                } else if (angular.isObject(val)) {
                  angular.forEach(val, function (value, idx2) {
                    fd.append(key + '[' + idx2 + ']', value);
                  });
                } else {
                  fd.append(key, val);
                }
              },
            })
            .then(function (res) {
              var data = res.data;
              $scope.medias.push(data.data);

              $timeout(function () {
                window.resizeImages(element.find('.media-container').last()[0]);
              });

              if ($scope.isRecaptchaEnabled) {
                RecaptchaService.reset($scope.recaptchaElementId);
              }
            })
            .catch(function () {
              pnotifyService.notify(
                $filter('translate')('media.validation.error'),
                {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                },
              );
            })
            .finally(function () {
              $scope.imageUploading = false;
            });
        };

        // init Recaptcha
        if ($scope.isRecaptchaEnabled) {
          $scope.$watchGroup(['$root.recaptchaLoaded', 'itemId'], function (
            newValue,
          ) {
            const [recaptchaLoaded, itemId] = newValue;

            if (recaptchaLoaded && itemId) {
              window[`onReviewMediaRecaptchaSuccess_${itemId}`] = function (
                token,
              ) {
                $('#g-recaptcha-response').val(token);
                $scope.onReviewMediaRecaptchaSuccess();
              };

              const sendElement = document.getElementById(
                $scope.recaptchaElementId,
              );

              if (sendElement && !sendElement.dataset.widgetId) {
                return window.renderSingleRecaptcha(sendElement);
              }
            }
          });
        }
      },
    };
  },
]);
