import dayjs from 'dayjs';
import {
  DISCOUNT_CATEGORY,
  DISOUNT_TYPE_TO_DISCOUNT_CATEGORY,
  COUPON_TAG_TYPE,
  DISCOUNT_ON,
} from '../../constants/promotion';
import {
  getGeneralCouponTagTypes,
  getPlatformCouponTagType,
} from '../../../utils/promotion';

const DISCOUNT_CATEGORY_MAP = {
  [DISCOUNT_CATEGORY.discount]: {
    i18nKey: 'coupon_v2.discount_type.discount',
    icon: 'usd',
  },
  [DISCOUNT_CATEGORY.freeShipping]: {
    i18nKey: 'coupon_v2.discount_type.free_shipping',
    icon: 'truck',
  },
  [DISCOUNT_CATEGORY.gift]: {
    i18nKey: 'coupon_v2.discount_type.gift',
    icon: 'gift',
  },
};

app.directive('couponV2', [
  '$filter',
  '$uibModal',
  'mainConfig',
  function ($filter, $uibModal, mainConfig) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../../public/themes/shared/components/templates.coupon-v2.html'),
      scope: {
        coupon: '=',
        disabled: '=',
        actionText: '@',
        onAction: '&',
      },
      link: function (scope) {
        scope.discountCategoryI18nKey =
          DISCOUNT_CATEGORY_MAP[
            DISOUNT_TYPE_TO_DISCOUNT_CATEGORY[scope.coupon.discount_type]
          ].i18nKey;
        scope.discountCategoryIcon =
          DISCOUNT_CATEGORY_MAP[
            DISOUNT_TYPE_TO_DISCOUNT_CATEGORY[scope.coupon.discount_type]
          ].icon;

        scope.hasPromotionPage =
          !scope.disabled &&
          [DISCOUNT_ON.item, DISCOUNT_ON.category].includes(
            scope.coupon.discount_on,
          );

        const timeFormat =
          mainConfig.localeData.loadedLanguage.code === 'vi'
            ? 'DD-MM-YYYY HH:mm'
            : 'YYYY-MM-DD HH:mm';

        const getPeriodText = (coupon) => {
          const isStarted =
            coupon.start_at === null ||
            dayjs(coupon.start_at).isBefore(dayjs());
          const isNeverExpires = coupon.end_at === null;

          if (!isStarted && !isNeverExpires) {
            return `${dayjs(coupon.start_at).format(timeFormat)} - ${dayjs(
              coupon.end_at,
            ).format(timeFormat)}`;
          }
          if (!isStarted && isNeverExpires) {
            return $filter('translate')('coupon_v2.period.start_at', {
              date: dayjs(coupon.start_at).format(timeFormat),
            });
          }
          if (isStarted && !isNeverExpires) {
            return $filter('translate')('coupon_v2.period.end_at', {
              date: dayjs(coupon.end_at).format(timeFormat),
            });
          }
          if (isStarted && isNeverExpires) {
            return '';
          }
        };
        scope.periodText = getPeriodText(scope.coupon);

        scope.couponTagTypeTextMap = {
          [COUPON_TAG_TYPE.platformOnlineStore]: $filter('translate')(
            'coupon.online_store_only',
          ),
          [COUPON_TAG_TYPE.platformRetailStore]: $filter('translate')(
            'coupon.retail_store_only',
          ),
          [COUPON_TAG_TYPE.platformSelectedRetailStore]: $filter('translate')(
            'coupon.selected_retail_store_only',
          ),
          [COUPON_TAG_TYPE.platformOnlineAndSelectedRetailStore]: $filter(
            'translate',
          )('coupon.online_and_selected_retail_store_only'),
          [COUPON_TAG_TYPE.firstPurchaseOnly]: $filter('translate')(
            'coupon.first_purchase_only',
          ),
          [COUPON_TAG_TYPE.oneUserMaxUseCount]: $filter('translate')(
            'coupon.one_time',
          ),
          [COUPON_TAG_TYPE.firstShopper]: $filter(
            'translate',
          )('coupon.first_shoppers', { number: scope.coupon.max_use_count }),
          [COUPON_TAG_TYPE.reachLimit]: $filter('translate')(
            'coupon.reach_limit',
          ),
          [COUPON_TAG_TYPE.expired]: $filter('translate')('coupon.expired'),
          [COUPON_TAG_TYPE.used]: $filter('translate')('coupon.used'),
        };

        scope.platformCouponTagType = getPlatformCouponTagType(scope.coupon);
        scope.shouldPlatformTagShowMore = [
          COUPON_TAG_TYPE.platformSelectedRetailStore,
          COUPON_TAG_TYPE.platformOnlineAndSelectedRetailStore,
        ].includes(scope.platformCouponTagType);

        scope.generalCouponTags = getGeneralCouponTagTypes(scope.coupon).map(
          (couponTagType) => scope.couponTagTypeTextMap[couponTagType],
        );

        scope.showSelectedRetail = () => {
          if (!scope.shouldPlatformTagShowMore) {
            return;
          }

          $uibModal.open({
            templateUrl: require('../../../../../../public/themes/v1/default/views/users/templates.dialog.coupon_selected_retail_store.html'),
            controller: [
              '$scope',
              '$uibModalInstance',
              function ($modalScope, $uibModalInstance) {
                $modalScope.availableChannels = scope.coupon.available_channels
                  .map((channel) => $filter('translateModel')(channel.name))
                  .join(', ');
                $modalScope.confirm = function () {
                  $uibModalInstance.close();
                };
              },
            ],
          });
        };
      },
    };
  },
]);
