app.directive('localDatetime', [
  '$filter',
  '$timeout',
  'featureService',
  'localDatetimeServices',
  'mainConfig',
  function (
    $filter,
    $timeout,
    featureService,
    localDatetimeServices,
    mainConfig,
  ) {
    return {
      restrict: 'A',
      link: function (_, element) {
        var format =
          mainConfig.localeData.loadedLanguage.code === 'vi'
            ? 'DD/MM HH:mm'
            : 'MM/DD HH:mm';

        // replace with local datetime dynamically
        element[0].innerText = dayjs(element[0].innerText).format(format);

        $timeout(function () {
          var $dateDOM = $(element[0]);
          var offset = new Date().getTimezoneOffset() / -60;
          var title = $filter('translate')(
            'promotions.page.display_local_timezone',
            {
              offset: offset < 0 ? offset : '+' + Math.abs(offset),
            },
          );

          $dateDOM.addClass('local-datetime');
          localDatetimeServices.mountTooltip($dateDOM, title);
        });
      },
    };
  },
]);
