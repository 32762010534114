import { PRODUCT_REVIEW_REWARD_TYPE } from '../constants/productReview';

app.directive('productReviewEntry', [
  '$location',
  'slFeatureService',
  'productReviewCommentService',
  'mainConfig',
  '$q',
  '$filter',
  function (
    $location,
    slFeatureService,
    productReviewCommentService,
    mainConfig,
    $q,
    $filter,
  ) {
    return {
      templateUrl: require('../../../../../public/themes/shared/product_review_comments/templates.product_review_entry.html'),
      restrict: 'E',
      scope: false,
      link: function (scope) {
        scope.productReviewEnabled =
          productReviewCommentService.productReviewEnabled;
        if (!scope.productReviewEnabled) {
          return;
        }

        scope.productReviewUrl =
          '/users/' +
          mainConfig.currentUser._id +
          '/product_review_comments/orders';

        scope.getTitle = () => {
          switch (scope.productReviewRewardType) {
            case PRODUCT_REVIEW_REWARD_TYPE.user_credit:
              return $filter('translate')(
                'product_review_entry.review_description_title.user_credit',
                {
                  value: scope.productReviewRewardRule.credit_value,
                },
              );
            case PRODUCT_REVIEW_REWARD_TYPE.member_point:
              return $filter('translate')(
                'product_review_entry.review_description_title.member_point',
                {
                  value: scope.productReviewRewardRule.point_value,
                },
              );
            case null:
            default:
              return $filter('translate')(
                'product_review_entry.review_description_title',
              );
          }
        };
      },
    };
  },
]);
