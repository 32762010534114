/**
 * Directive to display cart count
 **/
app.directive('slCartCount', [
  'cartService',
  '$filter',
  function (cartService, $filter) {
    return {
      restrict: 'C',
      scope: {
        countStyle: '=',
      },
      link: function (scope, element, attrs) {
        const { countStyle = 'icon' } = attrs;
        function updateCount(count) {
          if (countStyle === 'icon_and_text') {
            const countValue = count && count > 0 ? count : 0;
            element.text(
              `${$filter('translate')('cart')}(${
                countValue > 99 ? `+` : countValue
              })`,
            );
            return;
          }

          if (count && count > 0) {
            element.text(count > 99 ? '+' : count);
            element.show();
          } else {
            element.text('');
            element.hide();
          }
        }

        scope.$on('cartItemCountUpdated', function (event, count) {
          updateCount(count);
        });
        updateCount(cartService.getItemCount());
      },
    };
  },
]);
