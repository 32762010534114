import parsePhoneNumber from 'libphonenumber-js/max';
import { removeDashBracket } from '../../utils/parser';
import {
  getPreferredCountries,
  validatePhone,
  VALIDATION_TYPE,
} from '../../utils/intlTel';

/**
 * link: https://shopline.atlassian.net/wiki/spaces/EN/pages/2430402688/intlTelControlGroup
 */
app.service('intlTelInputService', [
  '$filter',
  '$timeout',
  function ($filter, $timeout) {
    /**
     * type = abbr | number
     * if type is abbr
     * return ex. 'hk', 'tw', etc.
     * return undefined when country is not selected
     */
    this.getCountry = function (type, elem) {
      switch (type) {
        case 'abbr':
          return elem.intlTelInput('getSelectedCountryData').iso2;
        case 'number':
          return elem.intlTelInput('getSelectedCountryData').dialCode;
      }
    };

    /**
     * type = abbr | number
     * value is a string
     * if type = abbr
     *  value = 'hk' | 'tw', etc.
     * if type = number
     *  value = '886' | '44', etc.
     * return boolean indicate whether it's successful or not.
     */
    this.setCountry = function (type, elem, value) {
      let isSuccess = false;
      if (!value) return isSuccess;
      try {
        switch (type) {
          case 'abbr': {
            elem.intlTelInput('setCountry', value);
            if (this.getCountry('abbr', elem) === value) isSuccess = true;
            break;
          }
          case 'number': {
            const iso2 = this.toAbbr(value);
            if (iso2) elem.intlTelInput('setCountry', iso2);
            if (this.getCountry('number', elem) === value) isSuccess = true;
            break;
          }
        }
      } catch (error) {
        isSuccess = false;
      }
      if (isSuccess) {
        this.renderCountryISO(elem);
      }
      return isSuccess;
    };

    /**
     * type = complete | simple | national
     * if type is complete
     * return string ex. '+886958554123'
     * if type is simple
     * return string ex. '982544632'
     * if type is national
     * return national phone with prefix like '0985123123', depend on its country
     */
    this.getPhone = function (type, elem) {
      switch (type) {
        case 'complete':
          return elem.intlTelInput('getNumber');
        case 'simple':
          return elem.val();
        case 'national':
          return elem
            .intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL)
            .replace(/\D/g, '');
      }
    };

    /**
     * type = complete | simple
     * if type is complete
     * value is string, ex, '+886928755412'
     * if type is simple
     * value is string, ex. '986554123'
     * if trigger is true, it will trigger input event and digest loop
     */
    this.setPhone = function (type, elem, value) {
      switch (type) {
        case 'simple':
          elem.val(value);
          break;
        case 'complete':
          elem.intlTelInput('setNumber', value);
          elem.val(elem.val().replace(/\D/g, ''));
      }
    };

    /**
     * return number
     * use intlTelInputUtils.numberType to mapping, ex. intlTelInputUtils.numberType.MOBILE = 1
     */
    this.getPhoneType = function (elem) {
      return elem.intlTelInput('getNumberType');
    };

    this.validatePhone = function (elem, config = {}) {
      const country = this.getCountry('abbr', elem);
      const phone = this.getPhone('national', elem);
      const errorMessages = validatePhone(phone, country, config);
      return errorMessages.map((error) => ({
        ...error,
        message: $filter('translate')(error.message.key, error.message.wording),
      }));
    };

    /**
     * Mapping the dialCode to get ISO country code
     *
     * @param {string} value
     * @return {string}
     */
    this.toAbbr = (value) =>
      window.intlTelInputGlobals
        .getCountryData()
        .find((v) => v.dialCode === value)?.iso2;

    /**
     * Mapping the ISO country code to get dialCode
     *
     * @param {string} value
     * @return {string}
     */
    this.toDialCode = (value) =>
      window.intlTelInputGlobals.getCountryData().find((v) => v.iso2 === value)
        ?.dialCode;

    /**
     * dialCode should be the string in number format, ex. '886'
     * phone should be simple number like '986321456'
     * defaultCountry should be abbr format, ex. 'tw'
     */
    this.initValue = (elem, { dialCode, phone, defaultCountry }) => {
      if (!dialCode && !phone) {
        this.setCountry('abbr', elem, defaultCountry);
        return;
      }
      const isSuccess = this.setCountry('number', elem, dialCode);
      // if the country code found in alphabetical order is not found in onlyCountries, use the parsePhoneNumber method to obtain it instead.
      if (!isSuccess && dialCode) {
        const country = parsePhoneNumber(`+${dialCode}${phone}`)?.country;
        this.setCountry('abbr', elem, country);
      }
      this.setPhone('complete', elem, phone);
    };

    this.initSetting = (elem, config = {}) => {
      const { userCountry, allowedCountry, hideHint } = config;
      const finalConfig = this.generateInitConfig(
        userCountry,
        allowedCountry,
        config,
      );
      elem.intlTelInput(finalConfig);
      this.insertPlaceholderAndHint(elem, hideHint);
    };

    /* reset country to blank */
    this.reset = (elem, config) => {
      // for intlTelInput to generate new placeholder
      elem.removeAttr('placeholder');
      elem.intlTelInput('destroy');
      this.initSetting(elem, config);
    };

    /* start, utils */
    this.formatPhone = function (elem) {
      const nationalPhone = this.getPhone('national', elem);
      this.setPhone('simple', elem, nationalPhone);
      return nationalPhone;
    };

    this.filterNonNumeric = function (elem) {
      const currentPhone = this.getPhone('simple', elem);
      const numericPhone = currentPhone.replace(/\D/g, '');
      this.setPhone('simple', elem, numericPhone);
      return numericPhone;
    };

    this.insertPlaceholderAndHint = (element, hideHint = false) => {
      const defaultPlaceHolder = `<div class="default-place-holder">${$filter(
        'translate',
      )('users.fields.callingCode')}</div>`;
      const countryCodeHint = `<div class="country-code-hint">${$filter(
        'translate',
      )('users.fields.callingCodeHint')}</div>`;
      element
        .parents('.iti--allow-dropdown')
        .addClass('without-country-code')
        .find('.iti__selected-flag')
        .prepend($(defaultPlaceHolder));
      if (!hideHint) {
        element
          .parents('.iti--allow-dropdown.without-country-code')
          .append($(countryCodeHint));
        element
          .parents('.intl-tel-input-container')
          .attr('data-has-hint', 'true');
      }
      this.updateInputPaddingLeft(element);
    };

    this.removeHint = (element) => {
      element.find('.country-code-hint').remove();
      element.parents('.intl-tel-input-container').removeAttr('data-has-hint');
    };

    this.removePlaceholderAndHint = (element) => {
      const inputEleWithPlaceHolder = element.parents(
        '.iti--allow-dropdown.without-country-code',
      );
      element.css({ 'padding-left': '' });
      this.removeHint(inputEleWithPlaceHolder);
      inputEleWithPlaceHolder.removeClass('without-country-code');
      inputEleWithPlaceHolder.find('.default-place-holder').remove();
    };

    /**
     * @typedef InitConfigOptions
     * @prop {boolean} usePreferredCountriesWithAllowedCountry - when allowedCountry is set and this is true, show the preferredCountries
     */

    /**
     * Get userCountry or allowedCountry, to filter the country codes
     * @param {string} userCountry - additional preferred country at the top
     * @param {string[]} allowedCountry - `onlyCountries` of intl-tel-input, ISO 3316-1 2-digit in lower case
     * @param {InitConfigOptions} options
     * @returns
     */
    this.generateInitConfig = (userCountry, allowedCountry, options = {}) => {
      const { usePreferredCountriesWithAllowedCountry = false } = options;
      if (Array.isArray(allowedCountry) && allowedCountry.length > 0) {
        return {
          initialCountry: 'auto',
          customPlaceholder: removeDashBracket,
          // default is ["us", "gb"]
          preferredCountries: usePreferredCountriesWithAllowedCountry
            ? getPreferredCountries(userCountry)
            : [],
          onlyCountries: allowedCountry,
        };
      }
      const preferredCountries = getPreferredCountries(userCountry);
      const config = {
        initialCountry: 'auto',
        customPlaceholder: removeDashBracket,
        preferredCountries,
      };
      return config;
    };
    /* end, utils */

    /* start, for AngularJs */

    /**
     * update validity for a modal of input, depends on your data
     * the validities include: countryRequired | numberRequired | numberValidation
     * @param {Element} element
     * @param {NgModelController} field
     */
    this.updateIntlInputValidity = (element, field, config) => {
      const initValidity = Object.values(VALIDATION_TYPE).reduce(
        (accumulate, current) => {
          accumulate[current] = true;
          return accumulate;
        },
        {},
      );
      const errors = this.validatePhone(element, config);
      errors.forEach((error) => (initValidity[error.type] = false)); // set to invalid if there is error
      Object.keys(initValidity).forEach((key) =>
        field.$setValidity(key, initValidity[key]),
      );
    };

    /**
     * because the country code drop down is inside the input box
     * if the country code changes, the left padding needs to be updated to match the new country code width
     * @param {Element} element
     */
    this.updateInputPaddingLeft = (element) => {
      const INPUT_PADDING = 6;
      return $timeout(() => {
        const placeHolderWidth = element
          .parents('.iti--allow-dropdown')
          .find('.iti__selected-flag')
          .outerWidth();
        const leftPadding = placeHolderWidth || 105;
        element.css({ 'padding-left': `${leftPadding + INPUT_PADDING}px` });
      });
    };

    this.renderCountryISO = (element) => {
      $(element)
        .parents('.iti--allow-dropdown')
        .find('.iti__flag-container .iti__selected-flag .iti__flag').html(`
          <span class="iti__country-code">
          ${this.getCountry('abbr', element).toUpperCase()}
          </span>
          <span class="iti__dial-code">
          +${this.getCountry('number', element)}
          </span>
        `);
    };
  },
]);
