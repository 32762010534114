app.service 'tappayService', [
  '$q'
  ($q) ->
    getPrime: ->
      tokenDeferObject = $q.defer()
      TPDirect.card.getPrime (result) ->
        if result.status == 0
          tokenDeferObject.resolve result
        else
          tokenDeferObject.reject result
        return
      tokenDeferObject.promise
]