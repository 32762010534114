app.directive('slForm', [
  '$http',
  'locationService',
  '$rootScope',
  '$timeout',
  function ($http, locationService, $rootScope, $timeout) {
    return {
      restrict: 'C',
      require: 'form',
      scope: {
        requestUrl: '@',
        formId: '@',
        onFormSubmission: '@',
      },
      compile: function (element) {
        var formData = {};
        element[0].noValidate = true;

        element
          .find('.sl-validatable')
          .filter(
            '[item-type=short_text], [item-type=long_text], [item-type=dropdown], [item-type=checkbox], [item-type=radio], [item-type=address]',
          )
          .attr('control-group', '')
          .attr('watch-field-validity', '')
          .attr('field-name', function () {
            return this.getAttribute('label');
          });

        element
          .find('input[type="text"], input[type="radio"], select, textarea')
          .each(function () {
            var $el = $(this);
            var id = $el.attr('item-id');
            var addressType = $el.attr('address-type');
            $el
              .attr('name', function () {
                return addressType ? id + '{}' : id;
              })
              .attr('ng-model', function () {
                this.dataset.modelKey = id;
                var modelKey = addressType ? id + '-' + addressType : id;
                return 'formBuilderForm.formData["' + modelKey + '"]';
              });
          });

        element.find('input[type="checkbox"]').each(function (i) {
          var $el = $(this);
          var value = this.value;
          var id = $el.attr('item-id');

          $el
            .attr('name', function () {
              return id + '[]';
            })
            .attr('ng-model', function () {
              var modelKey = this.type !== 'checkbox' ? id : id + '-' + i;
              this.dataset.modelKey = modelKey;
              return 'formBuilderForm.formData["' + modelKey + '"]';
            });

          if (!formData[id]) {
            formData[id] = [];
          }

          var values = formData[id];

          Object.defineProperty(formData, this.dataset.modelKey, {
            get: function () {
              return values.indexOf(value) !== -1;
            },
            set: function (check) {
              if (check) {
                values.push(value);
              } else {
                values.splice(values.indexOf(value), 1);
              }
            },
          });

          if (this.required) {
            this.removeAttribute('required');
            this.setAttribute(
              'ng-required',
              'formBuilderForm.checkboxRequired("' + id + '")',
            );
          }
        });

        return function postLink(scope, element, attrs, form) {
          var alert;
          var ladda;
          var submitBtn = _.find(element.find('button'), function (button) {
            return (button.type = 'submit');
          });
          var queryParams = locationService.getQueryParams();
          if (queryParams['is_preview']) {
            submitBtn.remove();
          } else if (submitBtn) {
            submitBtn.classList.add('ladda-button');
            submitBtn.setAttribute('data-style', 'slide-down');
            ladda = Ladda.create(submitBtn);
          }

          var getFormData = function () {
            var formDataArray = $('#form-' + scope.formId).serializeArray();
            var addressElIndex = 0;
            let elementName = null;
            return _.reduce(
              formDataArray,
              function (acc, el) {
                var nameArr;
                var fieldName;
                if (el.name.includes('[]')) {
                  nameArr = el.name.split('[]');
                  fieldName = nameArr[0];
                  if (acc[fieldName] && $.isArray(acc[fieldName])) {
                    acc[fieldName].push(el.value);
                  } else {
                    acc[fieldName] = [el.value];
                  }
                  return acc;
                }
                // construct address field key/value map for address type data
                if (el.value && el.name.includes('{}')) {
                  // when element changed, the index should reset from 0
                  if (elementName !== el.name) {
                    elementName = el.name;
                    addressElIndex = 0;
                  }
                  nameArr = el.name.split('{}');
                  fieldName = nameArr[0];
                  var addressEls = element.find('[name="' + el.name + '"]');
                  var addressEl = addressEls[addressElIndex];
                  var $el = $(addressEl);
                  var addressType = $el.attr('address-type');
                  if (addressType) {
                    if (!acc[fieldName]) acc[fieldName] = {};
                    acc[fieldName][addressType] = el.value;
                  }
                  addressElIndex++;
                  return acc;
                }
                if (el.value && !el.value.match(/undefined/)) {
                  acc[el.name] = el.value;
                }
                return acc;
              },
              {},
            );
          };

          form.checkboxRequired = function (id) {
            return !getFormData()[id];
          };

          element.on('submit', function (e) {
            e.preventDefault();

            if (!form.$valid || form.$invalid || form.submitting) {
              return;
            }

            var formData = getFormData();
            // comment out for now until product confirm empty form submittion with locale texts
            // if (Object.keys(formData).length === 0) {
            //   return;
            // }

            if (ladda) {
              ladda.start();
            }

            $http({
              method: 'POST',
              url: window.location.origin + scope.requestUrl,
              data: {
                formData: formData,
              },
            })
              .then(function () {
                $rootScope.$broadcast('form.response.submit', scope.formId);
                if (scope.onFormSubmission === 'clear_form') {
                  form.$$element[0] && form.$$element[0].reset();
                  // reset form to unsubmmited state for resubmit
                  $timeout(function () {
                    form.$setPristine();
                    form.$setUntouched();
                    form.$submitted = false;
                  }, 10);
                } else {
                  element
                    .find(
                      '.FormBuilder-container > :not(.FormBuilder-submit-success)',
                    )
                    .remove();
                  element
                    .find('.FormBuilder-submit-success')
                    .addClass('active');
                }
              })
              .catch(function (res) {
                if (alert) {
                  alert.fadeOut(function () {
                    alert.text(res.data.error).fadeIn();
                  });
                } else {
                  alert = $(
                    '<div class="alert alert-danger">' +
                      res.data.error +
                      '</div>',
                  );
                  element.prepend(alert);
                }

                $('html, body').animate({ scrollTop: 0 }, 600);
              })
              .finally(function () {
                if (ladda) {
                  ladda.stop();
                }
              });
          });
        };
      },
    };
  },
]);
