app.service('backInStockService', [
  'slFeatureService',
  'productSetService',
  'productStockService',
  function (slFeatureService, productSetService, productStockService) {
    // back is stock service is available when 'back_in_stock_notify' and 'wishlist_app' are both installed
    this.isBackInStockAvailable =
      slFeatureService.hasFeature('back_in_stock_notify') &&
      slFeatureService.hasFeature('wishlist_app');
    this.shouldShowBtn = function (product, variationSelected) {
      if (this.isBackInStockAvailable) {
        if (productSetService.isProductSetRevampEnabled(product)) {
          return false;
        }
        return !productStockService.checkHasStock(product, variationSelected);
      } else {
        return false;
      }
    };
  },
]);
