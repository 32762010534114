import { onTabActive } from '../features/member-center';

app.controller('MemberCreditsController', [
  '$scope',
  'memberCreditsService',
  '$element',
  function ($scope, memberCreditsService, $element) {
    // init
    $scope.userCredits = [];

    $scope.pagination = {
      total: 0,
      itemsPerPage: 0,
      current: 0,
    };

    $scope.state = {
      loadingItems: false,
    };

    $scope.errors = {
      loadMoreError: false,
    };

    $scope.getCredits = function () {
      $scope.errors.loadMoreError = false;
      if (
        $scope.pagination.current > $scope.maxPage ||
        $scope.state.loadingItems
      ) {
        return;
      }

      $scope.state.loadingItems = true;

      memberCreditsService
        .getCreditInfo($scope.pagination.current + 1)
        .then(function (response) {
          $scope.pagination.current += 1;
          var data = response.data;
          data.items.forEach(function (item) {
            if (!_.findWhere($scope.userCredits, { _id: item._id })) {
              $scope.userCredits.push(item);
            }
          });
          $scope.pagination.total = data.total;
          $scope.pagination.itemsPerPage = data.limit;
          $scope.maxPage = Math.ceil(
            $scope.pagination.total / $scope.pagination.itemsPerPage,
          );
        })
        .catch(function () {
          $scope.errors.loadMoreError = true;
        })
        .finally(function () {
          $scope.state.loadingItems = false;
        });
    };

    $scope.getCredits();

    onTabActive($element, () => {
      history.replaceState(null, null, `user_credits${location.search}`);
    });
  },
]);
