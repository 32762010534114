import { createDeferredPromise } from '../../utils/promise';

app.service('RecaptchaService', [
  'mainConfig',
  function (mainConfig) {
    const reset = (elementId) => {
      if (
        typeof grecaptcha !== 'undefined' &&
        document.querySelector('#g-recaptcha-response')
      ) {
        if (document.querySelector('.g-recaptcha#' + elementId) === null) {
          return;
        }
        const widgetId = document.querySelector('.g-recaptcha#' + elementId)
          .dataset.widgetId;
        grecaptcha.reset(widgetId);
      }
    };

    const execute = (elementId) => {
      if (
        typeof grecaptcha !== 'undefined' &&
        document.querySelector('#g-recaptcha-response')
      ) {
        if (document.querySelector('.g-recaptcha#' + elementId) === null) {
          return;
        }
        const widgetId = document.querySelector('.g-recaptcha#' + elementId)
          .dataset.widgetId;
        grecaptcha.execute(widgetId);
      }
    };

    const executeWithPromise = () => {
      if (typeof grecaptcha === 'undefined') {
        return Promise.reject(new Error('grecaptcha not found'));
      }

      const defferredPromise = createDeferredPromise();

      const recaptchaElement = document.createElement('div');
      const onRecaptchaSuccessed = (token) => {
        recaptchaElement.remove();
        document.getElementById('g-recaptcha-response').value = token;
        defferredPromise.resolve(token);
      };
      const onRecaptchaRejected = (error) => {
        recaptchaElement.remove();
        defferredPromise.reject(error);
      };
      const widgetId = grecaptcha.render(recaptchaElement, {
        sitekey: mainConfig.recaptchaSiteKey,
        size: 'invisible',
        badge: 'none',
        callback: onRecaptchaSuccessed,
        'error-callback': onRecaptchaRejected,
      });
      document.body.appendChild(recaptchaElement);

      grecaptcha.execute(widgetId);

      return defferredPromise.promise;
    };

    return {
      reset,
      execute,
      executeWithPromise,
    };
  },
]);
