app.directive('productReviewEntryProvider', [
  '$location',
  '$q',
  'productReviewCommentService',
  'slFeatureService',
  'mainConfig',
  function (
    $location,
    $q,
    productReviewCommentService,
    slFeatureService,
    mainConfig,
  ) {
    return {
      restrict: 'A',
      scope: false,
      link: function (scope) {
        scope.isProductReviewDataLoaded = false;
        scope.hasUncommentedOrder = false;
        scope.productReviewRewardType = null;
        scope.productReviewRewardRule = null;

        const getUncommentedOrderAmount = function () {
          // Do not show product review entry at last step of checkout flow
          if ($location.url().includes('/confirm')) {
            return $q.resolve();
          }

          return productReviewCommentService
            .getUncommentedOrders({
              page: 1,
              limit: 1,
            })
            .then((res) => {
              scope.hasUncommentedOrder = res.data.data['total'] > 0;
              return res;
            });
        };

        const getProductReviewRewardConfig = () => {
          if (!slFeatureService.hasFeature('product_review_reward')) {
            return $q.resolve();
          }

          return productReviewCommentService
            .getProductReviewRewardConfig()
            .then(({ rewardType, rule }) => {
              scope.productReviewRewardType = rewardType;
              scope.productReviewRewardRule = rule;
            });
        };

        const fetchData = () => {
          if (!mainConfig.merchantData.product_setting.enabled_product_review) {
            return;
          }

          $q.all([getUncommentedOrderAmount(), getProductReviewRewardConfig()])
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              scope.isProductReviewDataLoaded = true;
            });
        };
        fetchData();
      },
    };
  },
]);
