app.controller('CommentsController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$uibModalStack',
  'mainConfig',
  '$upload',
  'order',
  '$lightbox',
  'locationService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $uibModalStack,
    mainConfig,
    $upload,
    order,
    $lightbox,
    locationService,
  ) {
    $scope.recordId = order._id;
    $scope.recordType = 'Order';
    $scope.comments = [];

    $scope.loadItems = function (page) {
      $scope.loadingItems = true;
      $http({
        method: 'GET',
        url:
          '/api/orders/' +
          order._id +
          '/comments?page=' +
          page +
          '&authorization_token=' +
          locationService.getQueryParams().authorization_token,
      }).then(function (res) {
        var data = res.data;
        $scope.totalItems = data.data.total;
        $scope.currentPage = page;
        $scope.comments = data.data.items.concat($scope.comments);
        $scope.loadingItems = false;
        $scope.limit = data.data.limit;
        if (data.data.items.length < data.data.limit) {
          $scope.hasMore = false;
        } else {
          $scope.hasMore = true;
        }
      });
    };

    $scope.loadMore = function () {
      var nextPage = $scope.currentPage + 1;
      $scope.loadItems(nextPage);
    };
    $scope.loadItems(1);

    $scope.$on('comment.created', function (event, data) {
      $scope.comments.push(data);
    });

    $scope.onImageCliked = function ($event, comment) {
      $lightbox.open([comment.media], 0);
      return;
    };
  },
]);
