app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('de', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.jp': '日本語',

      'form.action.delete': 'Löschen',
      'recaptcha.language': 'de',

      'form.validation.required': '{{field_name}} ist erforderlich',
      'form.validation.maxlength': '{{field_name}} ist zu lang',
      'form.validation.minlength': '{{field_name}} ist zu kurz',
      'form.validation.invalid': '{{field_name}} ist ungültig',
      'form.validation.pattern': '{{field_name}} ist ungültig',
      'form.validation.pattern.recipient-name':
        'Leerzeichen und Symbole, oder mehr als 5 Zeichen.',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} muss 10-stellige Zahl sein',
      'form.validation.email': '{{field_name}} ist ungültig',
      'form.validation.unique':
        '{{field_name}} bereits verwendet. Bitte erneut wählen.',
      'form.validation.confirmation': 'Passwörter stimmen nicht überein.',
      'form.validation.policy':
        'Sie müssen die Nutzungsbedingungen akzeptieren, um fortzufahren.',
      'form.validation.delivery_option.outlying':
        'Die von Ihnen gewählte Lieferart ist für entlegene Inseln in Taiwan nicht verfügbar, bitte Ihre Lieferadresse ändern oder eine andere Lieferart wählen.',
      'form.validation.delivery_option.local':
        'Die von Ihnen gewählte Lieferart ist für Inseln in Taiwan nicht verfügbar, bitte Ihre Lieferadresse ändern oder eine andere Lieferart wählen.',
      'form.validation.delivery_option.country':
        'Die von Ihnen gewählte Lieferart ist für das Land nicht verfügbar, bitte Ihre Lieferadresse ändern oder eine andere Lieferart wählen.',
      'form.validation.delivery_option.region':
        'Die von Ihnen gewählte Lieferart ist für die Region nicht verfügbar, bitte Ihre Lieferadresse ändern oder eine andere Lieferart wählen.',
      'form.validation.birthday.invalid': 'Birthday is invalid',
      'form.validation.birthday.invalid.minimum_age_limit':
        'Birthday is invalid, should over {{minimumAgeLimit}} years old.',
      'form.validation.card.bin.error':
        'Current payment method is not applicable to your credit card. Please contact shop owner for details.',
      'form.validation.card.bin.error.unknow_scheme':
        'We cannot identify your credit card type. Please email shop owner for details.',

      'dropdown.hint': 'Bitte auswählen',

      'session.signup.title': 'Registrieren',
      'session.terms.facebook': 'Mit Facebook registrieren',
      'session.signup.facebook': 'Facebook-Login',
      'sessions.or': 'ODER',
      'session.terms':
        'Mit dem Fortfahren akzeptieren Sie unsere Nutzungsbedingungen.',
      'session.signin.submit': 'Anmelden',
      'session.forgot_password': 'Passwort vergessen',
      'session.signin.title': 'Anmelden',
      'session.signin.facebook': 'Facebook-Login',
      'session.mobile_signup.check_mobile.title':
        'Bitte Ihre Mobilnummer überprüfen, da die SMS an diese Nummer gesendet wird',
      'session.mobile_signup.check_mobile.your_number': 'Neue Mobilnummer',
      'session.mobile_signup.check_mobile.your_number.hint':
        'Bitte taiwanesische Mobilnummer (beginnt mit 09) eingeben',
      'session.mobile_signup.check_mobile.send_code': 'Prüfcode senden',
      'session.mobile_signup.check_mobile.go_back.1': 'Sie können',
      'session.mobile_signup.check_mobile.go_back.2': 'zurückgehen',
      'session.mobile_signup.check_mobile.go_back.3':
        'um Mobilnummer zu korrigieren, falls falsch',
      'session.mobile_signup.input_code.hint':
        'Bitte empfangenen Prüfcode eingeben',
      'session.mobile_signup.input_code.submit': 'Los',
      'session.mobile_signup.input_code.resend.countdown':
        'SMS mit Prüfcode erneut an meine Mobilnummer senden ({{resendCountdown}} s)',
      'session.mobile_signup.input_code.resend':
        'SMS mit Prüfcode erneut an meine Mobilnummer senden',
      'session.forget_passowrd.check_phone.error':
        'Mobilnummer ist falsch. Bitte erneut überprüfen',
      'session.mobile_center.check_phone.error':
        'Mobilnummer ist bereits vergeben',

      'user.quick_signup.title': 'Jetzt Mitglied werden!',
      'user.quick_signup.title.content':
        'Keines der exklusiven Angebote entgehen lassen!‘',
      'user.quick_signup.promotion':
        'Ich möchte die neuesten Angebote und Aktionen erhalten',
      'user.quick_signup.policy': 'Ich akzeptiere die',
      'user.quick_signup.policy.url_content':
        'Nutzungsbedingungen und Datenschutzrichtlinien des Shops',
      'user.quick_signup.submit': 'Jetzt registrieren',
      'user.quick_signup.error.format': 'Formatfehler',
      'user.quick_signup.error.confirmed':
        'Diese Mobilnummer ist bereits vergeben. ',
      'user.quick_signup.error.confirmed.url': 'Jetzt anmelden.',
      'user.quick_signup.validate.title': 'Registrierung fortsetzen',
      'user.quick_signup.validate.success':
        'Danke für Ihre Registrierung als Mitglied!<br/>Du kannst den Registrierungsprozess abschließen <br/>oder ',
      'user.quick_signup.validate.success.shop': 'jetzt einkaufen',
      'user.quick_signup.validate.finish_hint':
        'Registrierung abschließen und sofort Shop-Guthaben verwenden!',
      'user.quick_signup.validate.phone': 'Verifizieren',
      'user.quick_signup.validate.send.sms': 'Bestätigungscode senden',
      'user.quick_signup.validate.wrong.number':
        'Wenn die Mobilnummer falsch ist, bitte ',
      'user.quick_signup.validate.previous': 'zurückgehen',
      'user.quick_signup.validate.modify': 'und aktualisieren',
      'user.quick_signup.validate.hint.password': 'Mindestens 8 Zeichen',
      'user.quick_signup.validate.hint.send':
        'Bestätigungscode gesendet. Sie können nach {{count}} Sekunden einen neuen anfordern.',
      'user.quick_signup.validate.hint.resend':
        'Sie haben den Prüfcode nicht erhalten? Bitte hier klicken, um erneut zu senden.',
      'user.quick_signup.validate.submit': 'Registrierung abschließen‘',
      'user.quick_signup.validate.placeholder.sms': 'Bestätigungscode eingeben',
      'user.quick_signup.validate.placeholder.name': 'Name',
      'user.quick_signup.validate.placeholder.password': 'Passwort eingeben',
      'user.sign_up.email_verification.dialog.title':
        'Bestätigungs-E-Mail gesendet an {{mail}}',
      'user.sign_up.email_verification.dialog.description':
        'Bitte jetzt die E-Mail-Adresse überprüfen und die neue E-Mail-Adresse wird aktualisiert. Wenn Sie sie nicht erhalten haben, bitte Ihre E-Mail-Adresse erneut eingeben und speichern.',
      'user.sign_up.email_verification.dialog.confirm': 'Bestätigen',
      'user.delivery_data.recipient_phone': 'Kontaktnummer (Empfänger)',

      'product.out_of_stock': 'Ausverkauft',
      'product.addon_products.label': 'Zusatzartikel',
      'product.bundled_products.label': 'Paket',
      'product.bundle_group_products.label': 'A + B',
      'product.addon_products.errors.main_product_out_of_stock':
        'Nicht genügend Vorrat für Hauptprodukt',
      'product.addon_products.errors.reached_max_purchase_quantity':
        'Limit {{ message }} pro Auftrag.',
      'product.addon_products.errors.addon_product_out_of_stock':
        'Nicht genügend Vorrat für Zusatzartikel',
      'product.addon_products.errors.larger_addon_product':
        'Anzahl der Zusatzartikel kann nicht größer als Anzahl des Hauptprodukts',
      'product.addon_products.errors.out_of_stock':
        'Nicht genügend Vorrat für Zusatzartikel {{title}}.',
      'product.addon_products.tips.limit_exceed':
        'Wenn Sie die Menge anpassen, darf die Anzahl der Zusatzartikel nicht größer sein als die der Hauptprodukte.',
      'product.addon_products.add_to_cart.hint':
        'Der Artikel wurde Ihrem Warenkorb erfolgreich hinzugefügt',
      'product.add_to_cart': 'ZUM WARENKORB HINZUFÜGEN',
      'product.add_to_cart_fail': 'Your cart is full!',
      'product.buy_now.preorder.hint': 'Now taking pre-orders',
      'product.buy_together': 'ZUSAMMEN KAUFEN',
      'product.preorder_limit.hint':
        'The purchase quantity includes both in-stock and preordered items.',
      'product.coming_soon': 'COMING SOON',
      'product.available_time_over': 'AVAILABLE TIME OVER',
      'product.store_stock.check_stock': 'Check Stock Availability at Stores',
      'product.set.open_variation': 'View product details',

      'product.buy_now': 'KAUFE JETZT',

      'member.center.load.more': 'Mehr',
      'member.center.load.more.error':
        'Daten können nicht geladen werden. Bitte erneut versuchen.',

      'wishlist.signin_register': 'Jetzt anmelden/registrieren',
      'wishlist.messages.remove_error':
        'Artikel kann nicht von Wunschliste entfernt werden.',

      orders: 'Bestellungen',
      order: 'Bestellung',
      'orders.fields.billing_address': 'Rechnungsadresse',
      'orders.fields.order_number': 'Auftragsnr.',
      'orders.fields.order_date': 'Datum',
      'orders.fields.order_status': 'Status',
      'orders.fields.order_remarks': 'Bemerkung zum Auftrag',
      'orders.fields.customer_name': 'Kundenname',
      'orders.fields.product_subscription_period': '{{period}}.',
      'orders.fields.status.temp': 'Ausstehend',
      'orders.fields.status.pending': 'Ausstehend',
      'orders.fields.status.confirmed': 'Bestätigt',
      'orders.fields.status.shipped': 'Versandt',
      'orders.fields.status.completed': 'Abgeschlossen',
      'orders.fields.status.received': 'Empfangen',
      'orders.fields.status.cancelled': 'Storniert',
      'orders.fields.status.returning': 'Rückgabe',
      'orders.fields.status.returned': 'Zurückgegeben',
      'orders.fields.preorder_item': 'Artikel vorbestellen',
      'orders.fields.customer': 'Kunde',
      'orders.fields.customer.phone': 'Telefonnummer (Kunde)',
      'orders.fields.billto': 'Rechnung an',
      'orders.fields.payment_type': 'Zahlungsart',
      'orders.fields.payment_instructions': 'Zahlungsanweisungen',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        'Transaktion fehlgeschlagen: Maximaler Bestellbetrag für CVS-Zahlung ist 20.000 NT$',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        'Transaktion fehlgeschlagen: Mindestzahlbetrag für CVS-Zahlung ist 30 NT$',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        'Transaktion fehlgeschlagen: Maximaler Bestellbetrag für Strichcode-Zahlung ist 20.000 NT$',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        'Transaktion fehlgeschlagen: Mindestbestellbetrag für Strichcode-Zahlung ist 30 NT$',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        'Die Obergrenze pro Transaktion mit CVS ist 20.000 NTD',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'Die Obergrenze pro Transaktion mit Strichcode ist 20.000 NTD',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'Die Obergrenze pro Transaktion mit WebATM ist 30.000 NTD',
      'orders.fields.delivery_method': 'Liefermethode',
      'orders.fields.delivery_description': 'Beschreibung',
      'orders.fields.delivery_fee': 'Versandgebühr',
      'orders.fields.delivery_address': 'Lieferadresse',
      'orders.fields.delivery_address.remarks': 'Lieferhinweise',
      'orders.fields.accept_terms.validation.required':
        'Sie müssen die Nutzungsbedingungen akzeptieren, um fortzufahren',
      'orders.fields.options.blacklist.error':
        'Ihre Produkte werden mit verschiedenen Liefer- oder Zahlungsmethoden geliefert. Bitte andere gültige Methoden auswählen oder entsprechend bestellen.',
      'orders.show.message.thankyou.title': 'Vielen Dank für Ihren Auftrag',
      'orders.show.thankyou.continue': 'Jetzt weiter einkaufen!',

      'orders.show.message.shipped.title': 'Ihr Artikel wurde versandt',

      'orders.payment_slip.order_info.amount': 'Auftragssumme',
      'orders.payment_slip.order_info.instructions': 'Zahlungsanweisungen',
      'orders.payment_slip.order_info.read_more': 'Anleitung anzeigen',
      'orders.payment_slip.upload_form.title':
        'Nach Abschluss der Übertragung, bitte Überweisungsdetails laden',
      'orders.payment_slip.upload_form.hint':
        'Nach der Bestätigung der Zahlung, werden wir Versand so bald wie möglich gestalten!',
      'orders.payment_slip.upload_form.notification':
        'Um aktuelle Auftrag Benachrichtigung zu erhalten, tragen Sie sich bitte',
      'orders.payment_slip.upload_form.image_input.title':
        'Upload-Transfer-Details',
      'orders.payment_slip.upload_form.image_input.description':
        'Bitte bestätigen Sie, dass das Bild klar ist, und enthält die letzten 5 Ziffern der Transaktionsnummer, Übertragungszeit und Betrag.',
      'orders.payment_slip.upload_form.image_input.change':
        'Klicken Sie hier, um das Bild zu ändern',
      'orders.payment_slip.upload_form.image_input.error':
        'Die Bildgröße muss kleiner als 10 MB sein',
      'orders.payment_slip.upload_form.paid_time': 'Wählen Sie Zahlungsfrist',
      'orders.payment_slip.upload_form.message':
        'Füllen Sie Ihre Zahlung Bemerkungen',
      'orders.payment_slip.upload_form.message.error':
        'Kann {{ n }} Zeichen nicht überschreiten',
      'orders.payment_slip.uploaded.alert':
        'Vielen Dank für das Hochladen Überweisungsdetails, wir so schnell wie möglich bestätigen!',
      'orders.payment_slip.uploaded.notification':
        'Abonnieren Sie die neuesten, um Informationen zu erhalten',
      'orders.payment_slip.uploaded.title':
        'Hier unten ist der Transfer Details',
      'orders.payment_slip.uploaded.paid_time': 'Zahlungsfrist:',
      'orders.payment_slip.uploaded.upload_time': 'Gärzeit aktualisieren:',
      'orders.payment_slip.uploaded.order_link': 'Bestellung anzeigen',
      'orders.payment_slip.uploaded_without_login.title':
        'Vielen Dank! Für diese Bestellung wurden Angaben zur Überweisung hochgeladen',
      'orders.payment_slip.uploaded_without_login.hint':
        'Bitte loggen Sie sich in Detailansicht',
      'orders.payment_slip.footer':
        'Die Zahlungsinformationen Sie ausgefüllt haben, ist nur für die Bestellung überprüft und es wird sicher verschlüsselt. Sie können Ihre Zahlung mit Leichtigkeit abzuschließen.',

      'orders.index.no_records.title': 'Sie haben keine Aufträge.',
      'orders.index.no_records.description':
        'Sieht so aus, als hätten Sie noch nie in diesem Shop bestellt.',
      'orders.index.no_records.action': 'Los geht‘s',
      'orders.index.title': 'Aufträge',
      'orders.index.action.view': 'Anzeigen',
      'address.fields.address.recipient_name': 'Empfängername',
      'address.fields.address': 'Adresse',
      'address.fields.city': 'Stadt',
      'address.fields.postcode': 'PLZ',
      'address.fields.country': 'Land/ Region',

      'orders.fields.subtotal': 'Zwischensumme',
      'orders.fields.total': 'Gesamt',
      'order.billing_address.same': 'Identisch mit Lieferadresse',

      'orders.coupons.label': 'Gutscheincode',
      'orders.coupons': 'Glückwunsch! Sie genießen folgenden Rabatt:',
      'orders.coupons.placeholder': 'Gutscheincode eingeben',
      'orders.promotion_coupons.placeholder': 'Gutscheincode eingeben',

      'orders.coupons.apply': 'Gutschein anwenden',
      'orders.fields.order_discount': 'Rabattcode angewandt',
      'orders.fields.order_custom_discount': 'Rabatt (Benutzerdefiniert)',
      'orders.coupons.invalid': 'Ihr Gutscheincode ist leider ungültig.',
      'orders.coupons.validation.minamount':
        'Leider erfüllt Ihr Auftrag nicht den geforderten Wert für diesen Gutschein',
      'orders.coupons.invalid.order':
        'Bitte ungültigen Gutscheincode entfernen, bevor Sie Ihren Auftrag platzieren.',

      'orders.actions.title': 'Rücksendestatus verwalten',
      'orders.action.cancel': 'Auftrag stornieren',
      'orders.action.confirm': 'Auftrag bestätigen',
      'orders.action.ship': 'Als versandt markieren',
      'orders.general.title': 'Auftragsdetails',
      'orders.payment.title': 'Zahlungsdetails',
      'orders.delivery.title': 'Lieferdetails',
      'orders.products.title': 'Produktdetails',

      'orders.fields.accept_terms':
        'Sie akzeptieren die Nutzungsbedingungen und Datenschutzrichtlinien des Shops',
      'orders.action.checkout': 'Auftrag erteilen',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        'Empfängername',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        '7-11 Shop-Name',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        '7-11 Shop-Code',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        'Empfängername',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'Family Mart Shop-Name',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'Family Mart Shop-Code',
      'orders.fields.delivery_data.tw_ezship.location_code.hint': 'Shop suchen',
      'orders.fields.delivery_data.tw_ezship.location_code.label': 'Shop-Code',
      'orders.fields.delivery_data.tw_ezship.location_name.label': 'Shop-Name',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label':
        'Empfängername',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': 'Referenz-ID',

      // Checkout page error message field name
      'order.customer_name': 'Kundenname',
      'order.email': 'E-Mail-Adresse',
      'order.customer_email': 'E-Mail-Adresse (Kunde)',
      'order.customer_phone': 'Telefonnummer (Kunde)',
      'order.delivery_data.recipient_name': 'Empfängername',
      'order.delivery_data.recipient_phone': 'Kontaktnummer (Empfänger)',
      'order.delivery_address.address_1': 'Adresse',
      'order.delivery_address.city': 'Stadt',
      'order.delivery_address.state': 'Bezirk/Staat/Provinz',
      'order.delivery_data.location_code': 'Shop-Code',
      'order.delivery_data.location_name': 'Shop-Name',
      'order.delivery_data.time_slot': 'Lieferzeit',
      'order.delivery_data.scheduled_delivery_date': 'Ankunftsdatum',
      'order.delivery_data.time_slot_key': 'Ankunftszeit',
      'order.invoice.carrier_number': 'Spediteur',
      'order.invoice.mailing_address': 'Adresse',
      'order.invoice.tax_id': 'Steuer-ID',
      'order.payment_data.holdername': 'Vollständiger Name des Karteninhabers',
      'order.payment_data.expiry_date': 'Gültig bis',
      'order.payment_data.cvc': 'CVC',
      'order.payment_data.credit_card_number': 'Kreditkartennummer',
      'delivery.sfexpress.district': 'SF Express Bezirk‘',
      'delivery.sfexpress.store': 'SF Express Standort‘',
      'delivery_address.region.hk': 'Region',
      'delivery_address.district.hk': 'Bezirk',
      'delivery_address.region.tw': 'Bundesland/Stadt',
      'delivery_address.district.tw': 'Bezirk',
      'save_fields.customer_info.birthday': 'Geburtstag (Kunde)',

      'delivery_options.fields.delivery_type': 'Lieferart',
      'delivery_options.fields.delivery_types.pickup': 'Abholung',
      'delivery_options.fields.delivery_types.local': 'Lokal',
      'delivery_options.fields.delivery_types.email': 'E-Mail',
      'delivery_options.fields.delivery_types.international': 'International',
      'delivery_options.fields.delivery_types.custom': 'Benutzerdefiniert',
      'delivery_options.fields.delivery_types.tw_simple_711':
        '7-11 Abholung (Nur Taiwan)',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'Family Mart Abholung (Nur Taiwan)',
      'delivery_options.fields.select_date': 'Ankunftsdatum',

      'ezship.error.invalid_input':
        'Eine oder mehrere Feld(er) ungültig oder fehlen',
      'ezship.error.account_not_exist':
        'ezShip-Konto existiert nicht. Bitte Händler kontaktieren',
      'ezship.error.no_permission':
        'ezShip-Konto verfügt nicht über ausreichende Berechtigung',
      'ezship.error.no_easybag':
        'ezShip-Konto hat keine Easybag oder Minibag verfügbar',
      'ezship.error.invalid_store': 'Ungültiger Abholshop',
      'ezship.error.invalid_amount':
        'Ungültiger Betrag. Max. zulässige Betrag mit ezShip ist 6.000 TWD pro Bestellung.',
      'ezship.error.invalid_email': 'Ungültige E-Mail',
      'ezship.error.invalid_mobile': 'Ungültige Telefonnummer',
      'ezship.error.invalid_name': 'Ungültiger Empfängername',
      'ezship.error.system_error':
        'ezShip-Systemfehler. Bitte eine andere Versandmethode auswählen oder erneut versuchen.',

      'payments.fields.types.paypal': 'Kreditkarte oder Paypal',
      'payments.fields.types.paypal_express': 'Kreditkarte oder Paypal',
      'payments.fields.types.credit_card': 'Kreditkarte',
      'payments.fields.types.bank_transfer': 'Banküberweisung',
      'payments.fields.types.cash_on_delivery': 'Nachnahme',
      'payments.fields.types.free_checkout': 'Kostenlose Bestellung',
      'payments.fields.types.custom': 'Benutzerdefiniert',
      'payments.fields.types.allpay_barcode': 'Strichcode via Allpay',
      'payments.fields.types.allpay_credit': 'Kreditkarte via Allpay',
      'payments.fields.types.allpay_cvs': 'CVS via Allpay',
      'payments.fields.types.allpay_webatm': 'WebATM via Allpay',
      'payments.fields.types.asiapay': 'Kreditkarte via Asiapay',
      'payments.fields.types.prizm': 'Kreditkarte Visa/Master',
      'payments.fields.types.esun': 'Esun-Kreditkarte',

      'payment.cc.expirydate': 'Gültig bis',
      'payment.cc.holdername': 'Name des Karteninhabers',
      'payment.cc.number': 'Kreditkartennummer (Visa/Master)',
      'payment.cc.cvc': 'CVC',

      'payment.cc.expirydate.placeholder': 'MM/JJ',
      'payment.cc.holdername.placeholder': 'Name des Karteninhabers',
      'payment.cc.holdername.hint': 'Identisch mit Namen auf der Karte',
      'payment.cc.number.placeholder': 'Kreditkartennummer (keine Leerzeichen)',
      'payment.cc.cvc.placeholder': 'CVC',
      'payment.cc.cvc.hint':
        'Der 3-stellige Sicherheitscode befindet sich in der Regel auf der Rückseite Ihrer Karte.',
      'payment.action.confirm': 'Bestätigen',
      'payment.cc.update.instructions':
        'Bitte Ihre Kreditkarte unten eingeben, um sie zu aktualisieren',
      'payment.cc.fields.accept_terms':
        'Ich akzeptiere die Nutzungsbedingungen und Datenschutzrichtlinien',
      'payment.cc.fields.accept_terms.validation.required':
        'Sie müssen die Nutzungsbedingungen akzeptieren, um fortzufahren',
      'payment.cc.cashier.footer':
        'The payment service is powered by SHOPLINE Payments',
      'payment.cc.tw.cashier.footer':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',

      'payments.fields.types.ezship': 'Bei Abholung via ezShip',

      cart: 'Warenkorb',
      'cart.title': 'Warenkorb',
      'cart.item.remove': 'Entfernen',
      'cart.items.remove': 'Entfernen',
      'cart.checkout': 'Kasse',
      'cart.empty': 'Ihr Warenkorb ist leer.',
      'cart.empty.description':
        'Produkte zum Warenkorb hinzufügen, um fortzufahren.',
      'cart.empty.continue': 'Jetzt weiter einkaufen!',

      'checkout.instructions': 'Auftragsdetails',
      'checkout.fields.email': 'Kunden-E-Mail',
      'checkout.fields.email.hint':
        'Bitte Ihre korrekte E-Mail-Adresse eingeben, da die Auftragsbestätigung an Ihre E-Mail gesendet wird.',
      'checkout.fields.phone.hint':
        'Bitte Ihre Mobilnummer eingeben, um Lieferstatus-Update per SMS zu empfangen',
      'checkout.fields.phone.invalid': 'Ungültige Telefonnummer',
      'checkout.fields.email.placeholder': 'E-Mail-Adresse',
      'checkout.fields.remarks': 'Anmerkungen für Shop',
      'checkout.fields.remarks.placeholder':
        'Anmerkungen zum Auftrag für den Shop hinterlassen',
      'checkout.fields.delivery_method': 'Liefermethode',
      'checkout.fields.out_of_stock':
        'Leider nicht genügend Lagerbestand, bitte aus Warenkorb entfernen und erneut zur Kasse gehen.',
      'checkout.fields.phone': 'Kontakttelefonnummer',
      'checkout.fields.phone.placeholder': 'Ihre Kontakttelefonnummer eingeben',
      'checkout.fields.add_new': 'Neu hinzufügen...',
      'checkout.fields.select': 'Eine auswählen...',

      'checkout.delivery_option.title': 'Lieferadresse',
      'checkout.instalment_amount': 'ca. {{amount}} x {{period}} Raten',
      'checkout.payment_method.title': 'Zahlung',

      'checkout.discount.applied_promotions': 'Angewandte Aktionen',
      'checkout.discount.applied_coupons': 'Angewandte Coupons',

      'checkout.promotion.tags.promotion': 'Aktion',
      'checkout.promotion.tags.coupon': 'Gutschein',
      'checkout.promotion.tags.membership': 'Mitgliederangebot',
      'checkout.promotion.tags.free_shipping': 'Kostenloser Versand',

      'checkout.payment_condition.title':
        'Still need {{difference}} to meet the checkout conditions!',
      'checkout.payment_condition.description':
        'Before proceeding to checkout, please return to the shopping cart to choose another payment method or continue shopping',
      'checkout.payment_condition.continue_shopping': 'Continue Shopping',
      'checkout.payment_condition.hint':
        'This order must meet the minimum amount in order to proceed to checkout',
      'checkout.payments.exist.order.tip':
        'You have already placed order <a href="{{ duplicateOrderLink }}">{ {{order}} }</a>. If you wish to complete the checkout, please click “Place Order”.',

      'checkout.payment_failed':
        'Diese Transaktion ist fehlgeschlagen. Bitte erneut versuchen.',

      'checkout.auto_fill.popup.title': 'Enter your phone for fast checkout',
      'checkout.auto_fill.popup.continue': 'Continue',
      'checkout.auto_fill.popup.error': 'Please enter the correct format',
      'checkout.auto_fill.popup.cancel': 'Cancel',
      'checkout.auto_fill.toast.error':
        'If there is no record of the last order detected, the fast checkout data will not be brought in',

      'address.fields.recipient_name': 'Empfängername',
      'address.fields.address.city': 'Stadt',
      'address.fields.address.state': 'Bezirk/Staat/Provinz',
      'address.fields.address.postcode': 'PLZ (wenn zutreffend)',
      'address.fields.address.country': 'Land',
      'address.fields.address.remarks': 'Anmerkungen',

      'messages.form.send': 'Senden',
      'action.add_photo': 'Foto hinzufügen',
      'action.send': 'Senden',
      'action.load_earlier': 'Frühere laden',
      'action.load_more': 'Mehr laden',

      'users.fields.name': 'Vollständiger Name',
      'users.fields.email': 'E-Mail',
      'users.fields.mobile_phone_or_email': 'E-Mail oder Mobilnummer',
      'users.fields.password': 'Passwort',
      'users.fields.name.placeholder': 'Vollständiger Name',
      'users.fields.email.placeholder': 'E-Mail',
      'users.fields.password.placeholder': 'Passwort',
      'users.save.success': 'Ihre Änderungen wurden gespeichert.',
      'users.save.email_verification.success':
        'Ihre Änderungen wurden gespeichert. E-Mail-Adresse wird nach der Verifizierung aktualisiert.',
      'users.add.phone': 'Neue Nummer hinzufügen',
      'users.add.phone.maximum':
        'Sie können nur bis zu 5 Telefonnummern hinzuzufügen.',
      'users.error.phone.format': 'Telefonnummer ist ungültig',
      'users.error.phone.required': 'Telefonnummer ist erforderlich',
      'users.add.delivery_address': 'Neue Adresse hinzufügen',
      'users.add.delivery_address.maximum':
        'Sie können nur bis zu 5 Adressen hinzufügen.',
      'users.error.not_save_mobile_phone':
        'Please verify this phone number before saving change.',
      'users.error.no_changes': 'Keine Änderungen zum Abspeichern.',
      'users.error.maxlength': 'ist zu lang.',
      'users.signup.male': 'Männlich',
      'users.signup.female': 'Weiblich',
      'users.signup.other': 'Nicht offengelegt',
      'users.signup.email': 'Mit Ihrer E-Mail-Adresse registrieren',
      'users.signup.mobile_phone': 'Mit Mobilnummer registrieren',
      'users.signup.unconfirmed':
        'Willkommen zurück! Um Ihr Konto zu schützen, ist eine Überprüfung erforderlich, um sich bei Ihrem Konto anzumelden. Die Bestätigungs-E-Mail wurde bereits an {{email}} gesendet. Bitte schauen Sie in Ihrem Postfach nach. Vielen Dank!',
      'users.signup.verification.hint.send':
        'Bestätigungs-E-Mail gesendet. Sie können nach {{count}} Sekunden eine neue anfordern.',
      'users.einvoice_carrier': 'E-Rechnung in Ihrem Konto zusammenfassen',

      'membership.profile.subscriptions.orders.update.status':
        'Updates zu meinen Aufträgen',
      'membership.profile.subscriptions.orders.comments.new':
        'Neue Kommentare von Händlern zu meinem Aufträgen',
      'membership.profile.subscriptions.messages.new':
        'Neue Nachrichten von Händlern',
      'membership.profile.subscriptions.marketing.news':
        'Shop-Updates und Angebote',
      'membership.profile.subscriptions.none': 'Keine',
      'membership.profile.mobile.number.verified':
        'Mobilnummer wurde überprüft und ersetzt die zuvor gespeicherten Telefonnummern.',
      'membership.profile.check.code.error':
        'Prüfcode falsch! Bitte erneut eingeben oder Code-SMS erneut senden',

      'store_credits.title': 'Shop-Guthaben',
      'store_credits.credit_balance': 'Guthabenstand',
      'store_credits.credit_history': 'Guthabenverlauf',
      'store_credits.fields.date': 'Datum',
      'store_credits.fields.remarks': 'Grund für Änderung im Guthaben',
      'store_credits.fields.value': 'Guthabenentwicklung',
      'store_credits.fields.expiry_date': 'Gültig bis',
      'store_credits.fields.balance': 'Guthabenstand',
      'store_credits.fields.order_prefix':
        'Verwendung von Guthaben in Aufträgen',
      'store_credits.fields.welcome_credit': 'Willkommensguthaben',
      'store_credits.fields.birthday_credit': 'Geburtstagsguthaben',
      'store_credits.fields.user_credit_expired': 'Guthaben abgelaufen',
      'store_credits.fields.never_expires': 'Verfällt niemals',
      'store_credits.fields.never_expires_point': 'Points Never Expired',
      'store_credits.fields.display.never_expired': 'Never Expired',
      'store_credits.fields.auto_reward_prefix': 'Order ',
      'store_credits.fields.auto_reward': ' gets reward credits',
      'store_credits.fields.revert_credit':
        'Guthaben aus storniertem Auftrag zurückerstattet',
      'store_credits.fields.revert_credit.return':
        'Credit Reverted from Returned order',
      'store_credits.fields.revert_credit.order_split':
        'Credits reverted from split order',
      'store_credits.fields.revert_credit.order_edit':
        'Credits reverted from edited order',
      'store_credits.fields.revert_credit.return_order_revert':
        'Reverted credits; from return',
      'store_credits.no_data': 'Keine Gutschriften',

      'error.404.title': 'Es tut uns leid',
      'error.404.description': 'Diese Seite existiert nicht',
      'error.401.title': 'Es tut uns leid',
      'error.401.description':
        'Sie sind nicht berechtigt auf diese Seite zuzugreifen',
      'error.403.title': 'Es tut uns leid',
      'error.403.description':
        'Sie sind nicht berechtigt auf diese Seite zuzugreifen',
      'error.403.not_signed_in.message': 'vielleicht, weil Sie sich nicht ',
      'error.403.not_signed_in.anchor': 'anmelden',
      'error.continue': 'Jetzt weiter einkaufen!',

      'orders.comments.title': 'Shop- und Kundenkommentare',
      'orders.comments.text.name': 'Kommentar',
      'orders.comments.text.placeholder':
        'Einen Kommentar für diesen Auftrag eingeben',

      'orders.fields.payment_fee': 'Zahlungsgebühr',

      'orders.invoices.title': 'Rechnung',
      'orders.invoices.carrier': 'Allgemeiner Spediteur',
      'orders.invoices.carrier_type.member': 'Mitglied',
      'orders.invoices.carrier_type.mobile_barcode': 'Mobiler Strichcode',
      'orders.invoices.carrier_type.npc_barcode': 'NPC-Strichcode',
      'orders.invoices.get_invoice': 'Ich brauche eine Quittung',

      'orders.notifications.title':
        'Benachrichtigungen über Auftragsaktualisierungen',

      'order.payments.login.confirm':
        'Please log in and go to pay again or update credit card information!',

      'model.credit_card.title': 'Ihre Kreditkarteninformationen',
      'model.credit_card.subtitle':
        'Bitte Ihre Kreditkarte unten eingeben, um sie zu aktualisieren',
      'model.credit_card.choose_card': 'Fast checkout with your saved card',
      'model.credit_card.use_new_card': 'Use a new card',
      'model.slpayment.security_agreement':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',
      'model.slpayment.recurring_pay_agreement':
        'We’ll use the payment details you just provided for subsequent recurrent payments. By submitting, you agree to the <a href="https://shopline.tw/about/product_subscription_agreements_en" target="_blank" rel="noopener">Term Purchase bank card Withholding Agreement.</a>',
      'model.update_credit_card_for_product_subscription.success':
        'Credit card information updated successfully',
      'model.update_credit_card_for_product_subscription.fail':
        'The payment information for Product Subscription is invalid. Please try again.',

      'facebook_messenger_checkbox.subscribed': 'Gesendet via',
      'facebook_messenger_checkbox.turn_off': 'Deaktivieren',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        'Benachrichtigungen über Auftragsaktualisierungen',
      'facebook_messenger_checkbox.topics.MARKETING':
        'Shop-Updates und Angebote',

      'imagepicker.title': 'Bild-Upload',

      'promotions.section.discount_summary.set.on_item':
        '<b>{{ discount_value }}</b> Rabatt auf <b>Ausgewählte Produkte</b> genießen',
      'promotions.section.discount_summary.set.on_order':
        '<b>{{ discount_value }}</b> Rabatt auf <b>Gesamten Auftrag</b> genießen',
      'promotions.section.discount_summary.discount_value':
        '<b>{{ type == "amount" ? Currency : ""  }}{{ value }}{{ type == "percentage" ? "%":""  }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>Ausgewählte Produkte</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>Gesamter Auftrag</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        'mit dem Kauf <b>ausgewählter Produkte</b> über <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        'mit dem Kauf über <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }} Artikel',
      'promotions.section.discount_summary.criteria.bundle_group':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }}.',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        ' Buy more, Save more!',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        ' Limited offer for your first set.',
      'promotions.section.discount_summary.content':
        '{{ benefits }}{{ criteria }}',
      'promotions.section.discount_percentage.caption': '{{ value }}% Rabatt',

      'product.page.title.product-info': 'Produkt',
      'product.page.quantity': 'Menge',
      'product.page.unit_price': 'Einheitspreis',
      'product.page.subtotal': 'Zwischensumme',
      'product.page.add_to_cart_count':
        'Bereits <span class="order-detail-count">{{count}}</span> Artikel hinzugefügt',

      'products.quick_cart.quantity': 'Menge',
      'products.quick_cart.preorder_now': 'JETZT VORBESTELLEN',
      'products.quick_cart.add_to_cart': 'ZUM WARENKORB HINZUFÜGEN',
      'products.quick_cart.out_of_stock': 'AUSVERKAUFT',
      'products.quick_cart.out_of_stock_hint':
        'Nicht genügend Bestand.\n Der Artikel wurde Ihrem Warenkorb nicht hinzugefügt.',
      'products.quick_cart.low_stock_hint':
        'Nicht genügend Bestand.\n Bitte Ihre Menge anpassen.',
      'products.quick_cart.reached_max_purchase_quantity':
        'Limit {{ message }} pro Auftrag.',
      'products.quick_cart.quantity_of_stock_hint':
        'Nur {{message}} Artikel übrig.',
      'products.quick_cart.messagetobuy':
        'Bitte den Shop-Betreiber für Auftragsdetails kontaktieren.',
      'products.quick_cart.message': 'NACHRICHT',
      'products.quick_cart.show_more': 'Weitere Details',
      'products.message.title': 'Diesen Artikel wirklich löschen?',
      'products.message.button_cancel': 'Abbrechen',
      'products.message.button_ok': 'OK',
      'products.purchase_limit_hint': 'Limit {{limit}} per customer',
      'purchase_limit_hint.purchasable':
        'Limit {{limit}} per customer. You can only purchase {{purchasable_qty}} more.',
      'products.purchase_limit_hint_can_not_purchase':
        'Limit {{limit}} per customer. You have already purchased to limit.',

      'products.category.advance_filter.title': 'Filter',
      'products.category.advance_filter.clear': 'Auswahl löschen',
      'products.category.advance_filter.clear_all': 'Auswahl löschen',
      'products.category.advance_filter.apply': 'Filter anwenden',
      'products.category.advance_filter.apply.hint':
        'Bitte wählen Sie zuerst die Filteroptionen aus',
      'products.category.advance_filter.show_more': 'Zeig mehr',
      'products.category.advance_filter.show_less': 'Zeige weniger',
      'products.category.advance_filter.type.color': 'Farbe',
      'products.category.advance_filter.type.size': 'Größe',
      'products.category.advance_filter.type.brand': 'Marke',
      'products.category.advance_filter.type.material': 'Material',
      'products.category.advance_filter.type.price':
        'Preisklasse ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': 'Mindest',
      'products.category.advance_filter.placeholder.max_price': 'Max',

      'hk_sfplus.region': 'Region',
      'hk_sfplus.area': 'Bereich',
      'hk_sfplus.district': 'Bezirk',
      'hk_sfplus.address': '{{region}}, {{district}}, {{area}}',

      'tcat.time_slot.title': 'Ankunftszeit',
      'tcat.time_slot.01': 'Vor 13:00',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': 'Jederzeit',
      'verification.email.trial_merchant.reach_limit.failure':
        'The verification email is not available during the trial operation of the store. If needed, please contact the store for assistance.',
    });
  },
]);
