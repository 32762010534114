app.directive('taxFeeInfo', [
  'taxService',
  function (taxService) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/shared/tax/templates.tax.fee.info.html'),
      link: function (scope, element) {
        element.find('.tax-fee-info').on('click', function () {
          var scrollTop = $('.cart-summary').offset().top;
          var navHeight = $('.NavigationBar-container:visible()').height();
          $('html, body').animate({ scrollTop: scrollTop - navHeight }, 300);
        });

        scope.$on('tax.fee.address.changed', function () {
          scope.showTaxFeeInfo = false;
        });

        scope.$on('tax.fee.isUpdated', function (event, taxData) {
          scope.taxData = taxData;
          scope.salesTaxRate = taxService.getTaxRateText(taxData.sales);
          scope.deliveryTaxRate = taxService.getTaxRateText(taxData.delivery);
          if (scope.salesTaxRate || scope.deliveryTaxRate) {
            scope.showTaxFeeInfo = true;
          }
        });
      },
    };
  },
]);
