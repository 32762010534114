const CLAIM_RESULT = {
  succeed: 'succeed',
  failed: 'failed',
};

app.directive('couponInput', [
  'promotionService',
  'pnotifyService',
  '$filter',
  'logger',
  function (promotionService, pnotifyService, $filter, logger) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../../public/themes/shared/components/templates.coupon-input.html'),
      scope: {
        onClaimSucceed: '&',
      },
      link: function (scope) {
        scope.value = '';
        scope.isClaiming = false;
        // unwrap since this function is passed without parenthesis to accept params
        scope.onClaimSucceed = scope.onClaimSucceed();

        scope.claim = () => {
          if (scope.value === '') {
            return;
          }
          scope.isClaiming = true;
          promotionService
            .claimCouponByCode(scope.value)
            .then((res) => {
              const { result, error_message, name, coupon_status } = res.data;
              if (result === CLAIM_RESULT.succeed) {
                scope.onClaimSucceed({ name, coupon_status });
                scope.value = '';
              } else {
                pnotifyService.notify(error_message, {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                });
              }
            })
            .catch((error) => {
              logger.error('Error when claiming coupon by code', error);
              pnotifyService.notify(
                $filter('translate')('coupon_input.claim_failed'),
                {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                },
              );
            })
            .finally(() => {
              scope.isClaiming = false;
            });
        };
      },
    };
  },
]);
