import { merge } from 'lodash-es';
import {
  ADD_CARD_RESULT_STATUS,
  LOCAL_STORAGE_WEBHOOK_ID,
  BIND_CARD_REDIRECT_QUERY_PARAMS,
  CHECK_POLLINGID_STATUS,
  POLLING_INTERVAL,
} from '../constants/productSubscriptionSlpaymentCreditCardUpdate';
import CREDIT_CARD from '../constants/credit-card';
import { toMoney, dollarsToCents } from '../../utils/money';
import { paymentMethodMap } from '../../service/slPaymentService';

app.controller('SlpUpdateCardForProductSubscriptionModalController', [
  '$rootScope',
  '$scope',
  '$uibModalInstance',
  'staticResourceHost',
  'slPaymentService',
  'mainConfig',
  '$filter',
  '$location',
  '$http',
  'shopline_payment_instrument_polling_endpoint',
  function (
    $rootScope,
    $scope,
    $uibModalInstance,
    staticResourceHost,
    slPaymentService,
    mainConfig,
    $filter,
    $location,
    $http,
    shopline_payment_instrument_polling_endpoint,
  ) {
    const DEFAULT_CREDIT_CARD_IMG = `${staticResourceHost}assets/footer/card_default.png`;
    $scope.staticResourceHost = staticResourceHost;
    $scope.state = {
      isModalIniting: false,
      isSubmitting: false,
      isAuthorizationFormIniting: false,
      isUpdateSuccess: false,
    };
    $scope.errors = {};
    $scope.creditCardList = [];
    $scope.selectedCardId = null;
    $scope.isBindCardFormOpen = false;
    $scope.shoplinePaymentV2Content = null;

    const getCreditCardList = () => {
      if ($rootScope.currentUser) {
        $scope.state.isModalIniting = true;

        slPaymentService
          .getPaymentInstruments()
          .then((result) => {
            $scope.creditCardList = result.data;
            $scope.selectedCardId = $scope.creditCardList
              ? $scope.creditCardList[0].id
              : null;
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            $scope.state.isModalIniting = false;
          });
      }
    };

    getCreditCardList();

    const getCreditCardStatus = (webhookMap, id) => {
      // because of using $http will happen cors error so change to use fetch
      fetch(`${shopline_payment_instrument_polling_endpoint}${id}`)
        .then((res) => res.text())
        .then((res) => {
          // res === 'SUCCESS' and res === 'FAIL' only if status code equals 200
          if (
            [
              CHECK_POLLINGID_STATUS.SUCCESS,
              CHECK_POLLINGID_STATUS.FAIL,
            ].includes(res)
          ) {
            webhookMap[id].isGetRes = true;
            try {
              getCreditCardList();
            } catch (error) {
              console.error(error);
              $scope.errors.paymentFailed = $filter('translate')(
                'user.add_credit_card.fail',
              );
            }
          }
          if (res === CHECK_POLLINGID_STATUS.FAIL) {
            $scope.errors.paymentFailed = $filter('translate')(
              'user.add_credit_card.fail',
            );
          }
          localStorage.setItem(
            LOCAL_STORAGE_WEBHOOK_ID,
            JSON.stringify(webhookMap),
          );
        })
        .catch((e) => {
          console.error(e);
          $scope.errors.paymentFailed = $filter('translate')(
            'user.add_credit_card.fail',
          );
        })
        .finally(() => {
          if (webhookMap[id].interval) {
            clearInterval(webhookMap[id].interval);
          }
        });
    };

    const checkPollingId = () => {
      const params = new URLSearchParams(window.location.search);
      const webhookMap =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_WEBHOOK_ID)) || {};

      if (!params.has(BIND_CARD_REDIRECT_QUERY_PARAMS.POLLING_ID)) {
        const webhookMapList = Object.keys(webhookMap);
        const allResponsesReceived = webhookMapList.every(
          (item) => webhookMap[item]['isGetRes'],
        );

        if (allResponsesReceived)
          localStorage.removeItem(LOCAL_STORAGE_WEBHOOK_ID);
        return;
      }
      if (!webhookMap[params.get(BIND_CARD_REDIRECT_QUERY_PARAMS.POLLING_ID)]) {
        webhookMap[params.get(BIND_CARD_REDIRECT_QUERY_PARAMS.POLLING_ID)] = {
          isGetRes: false,
          interval: null,
        };
      }
      const webhookMapList = Object.keys(webhookMap);
      webhookMapList.forEach((item) => {
        if (!webhookMap[item]['isGetRes']) {
          getCreditCardStatus(webhookMap, item);
          webhookMap[item]['interval'] = setInterval(
            () => getCreditCardStatus(webhookMap, item),
            POLLING_INTERVAL,
          );
        }
      });
    };

    checkPollingId();

    $scope.isCreditCardOverLimit = function () {
      return $scope.creditCardList.length >= CREDIT_CARD.CARD_LIST_LIMIT;
    };

    $scope.getCreditCardTypeImg = function (brand) {
      const brandImg =
        CREDIT_CARD.BRAND_ICON_MAP[brand] || DEFAULT_CREDIT_CARD_IMG;
      return brandImg;
    };

    $scope.handleSelectedCardId = function (id) {
      if (!_.isEmpty($scope.shoplinePaymentV2Content)) {
        $scope.shoplinePaymentV2Content.destroy();
        $scope.shoplinePaymentV2Content = null;
      }
      $scope.selectedCardId = id;
      $scope.isBindCardFormOpen = false;
      $scope.errors = {};
    };

    $scope.handleOpenBindCardForm = function () {
      if (_.isEmpty($scope.shoplinePaymentV2Content)) initSlPaymentSDK();
      $scope.selectedCardId = null;
      $scope.isBindCardFormOpen = true;
      $scope.errors = {};
      // clear the webhook id from the local storage (from previous binding card attempt)
      localStorage.removeItem(LOCAL_STORAGE_WEBHOOK_ID);
    };

    const initSlPaymentSDK = () => {
      $scope.state.isAuthorizationFormIniting = true;
      const currency = toMoney({
        cents: dollarsToCents(1, mainConfig.merchantData.base_currency_code),
        currency_iso: mainConfig.merchantData.base_currency_code,
      });
      const amount =
        currency.currency_iso === 'TWD' ? currency.cents * 100 : currency.cents;

      const paymentConfig = merge(
        {},
        slPaymentService.getDefaultPaymentConfig(),
        {
          amount,
          paymentMethod: paymentMethodMap.credit,
          customerToken: '',
          paymentInstrument: {
            bindCard: {
              enable: true,
              protocol: {
                mustAccept: true,
                textType: {
                  paymentAgreement: true,
                },
                switchVisible: true,
              },
            },
          },
        },
      );

      slPaymentService
        .renderSDK({
          paymentConfig,
          elementId: '#slp-product-subscription-credit-card-update-form',
        })
        .then((result) => {
          $scope.isBindCardFormOpen = true;
          $scope.shoplinePaymentV2Content = result;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          $scope.state.isAuthorizationFormIniting = false;
          $scope.$apply();
        });
    };

    $scope.getCurrencyLabel = function () {
      const currencyCode = mainConfig.merchantData.base_currency_code;
      return toMoney({
        symbol: currencyCode,
        cents: dollarsToCents(1, currencyCode),
      }).label;
    };

    $scope.cancel = () => {
      if ($scope.state.isSubmitting) {
        return;
      }

      $uibModalInstance.dismiss('cancel');
      $scope.errors = {};

      /** clear url params from adding the new credit card */
      $location.search({});
    };

    $scope.close = () => {
      $uibModalInstance.dismiss('cancel');
      $location.search({});
    };

    const handleAddNewCreditCard = () => {
      const orderId = window.location.href.split('/').pop();

      $scope.shoplinePaymentV2Content
        .validate()
        .then((valid) => {
          if (!valid) {
            throw new Error(ADD_CARD_RESULT_STATUS.VALIDATION_FAILED);
          }
          $scope.state.isSubmitting = true;
          return $scope.shoplinePaymentV2Content.getPaySession();
        })
        .then((paySessionRes) => {
          let data = {
            polling_id: mainConfig.currentUser._id + new Date().getTime(),
            pay_session: paySessionRes,
            order_id: orderId,
          };
          return slPaymentService.updatePaymentInstruments(data);
        })
        .then((paymentInstrumentRes) => {
          return $scope.shoplinePaymentV2Content.pay(
            paymentInstrumentRes.data.next_action,
          );
        })
        .then((payRes) => {
          if (payRes && payRes.paymentError) {
            $scope.errors.paymentFailed = $filter('translate')(
              'user.add_credit_card.fail',
            );
          }
        })
        .catch((error) => {
          if (error.message !== ADD_CARD_RESULT_STATUS.VALIDATION_FAILED) {
            $scope.errors.paymentFailed = $filter('translate')(
              'user.add_credit_card.fail',
            );
          }
        })
        .finally(() => {
          $scope.state.isSubmitting = false;
        });
    };

    const handleUpdateProductSubscriptionPaymentCreditCard = () => {
      $scope.state.isSubmitting = true;
      const payload = {
        performer_id: $scope.order.customer_id, // current_user.id
        payment_data: {
          product_subscription: {
            shopline_payment_customer_id:
              mainConfig.currentUser.shopline_payment_customer_id,
            shopline_payment_instrument_id: $scope.selectedCardId,
          },
        },
      };

      const productSubscriptionId =
        $scope.order.subtotal_items[0].product_subscription_id;
      $http({
        method: 'PUT',
        url: `/api/product_subscriptions/${productSubscriptionId}`,
        data: payload,
      })
        .then((response) => {
          if (response.data) {
            $scope.state.isUpdateSuccess = true;
          }
        })
        .catch((e) => {
          console.error(e);
          $scope.errors.paymentFailed = $filter('translate')(
            'model.update_credit_card_for_product_subscription.fail',
          );
        })
        .finally(() => {
          $scope.state.isSubmitting = false;
        });
    };

    $scope.submit = () => {
      if (
        $scope.state.isAuthorizationFormIniting ||
        $scope.state.isModalIniting
      ) {
        return;
      }

      $scope.selectedCardId !== null
        ? handleUpdateProductSubscriptionPaymentCreditCard()
        : handleAddNewCreditCard();
    };

    $scope.$on('$destroy', () => {
      // clean up any intervals and prevent potential memory leaks
      const webhookMap =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_WEBHOOK_ID)) || {};
      Object.keys(webhookMap).forEach((item) => {
        if (webhookMap[item].interval) {
          clearInterval(webhookMap[item].interval);
          webhookMap[item].interval = null;
        }
      });

      localStorage.setItem(
        LOCAL_STORAGE_WEBHOOK_ID,
        JSON.stringify(webhookMap),
      );
    });
  },
]);
