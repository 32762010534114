app.directive('intlTelControlGroup', [
  '$filter',
  'intlTelInputService',
  '$timeout',
  function ($filter, intlTelInputService, $timeout) {
    return {
      restrict: 'A',
      templateUrl: function (element, attr) {
        return attr.templateUrl
          ? attr.templateUrl
          : require('../../../../../public/themes/v1/default/views/modules.control-group.html');
      },
      transclude: true,
      require: ['^form'],
      scope: {
        label: '@', // Gets the string contents of the `label` attribute
        fieldName: '@', // Gets the string contents of the `data-field-name` attribute
        validateLocalPhone: '=',
        validateMobilePhone: '=',
        numberRequired: '=', // Gets the string contents of the `data-number-required` attribute
      },
      link: function (scope, element, attrs, [formController]) {
        scope.label = scope.label ?? '';
        const candidates = [
          element.find('input.intl-tel-input'),
          element.find('input.intl-tel-input-checkout'),
        ];
        const IntlInput = candidates.find((elem) => elem.length > 0);
        if (!IntlInput) return;

        const fieldName = IntlInput.attr('name');
        const modelName = IntlInput.attr('ng-model');
        const fieldObject = formController[fieldName];
        scope.for = fieldName;

        scope.$watchGroup(
          [
            () => IntlInput.val(),
            () => formController.$dirty,
            () => formController.$valid,
            () => formController.$submitted,
            () => formController.submitted,
          ],
          (newValue, oldValue) => {
            if (newValue != oldValue) validate(IntlInput, fieldObject);
          },
        );
        scope.$watch(modelName, () => validate(IntlInput, fieldObject));
        IntlInput.on(
          'countrychange',
          () => $timeout(() => validate(IntlInput, fieldObject)), // $timeout to prevent "$digest already in progress"
        );

        const validate = (element, field) => {
          if (!field) return;
          const validateTarget = {
            countryRequired: true,
            numberRequired: scope.numberRequired ?? true,
            numberValidation: true,
            localPhoneValidation: scope.validateLocalPhone === true,
            mobilePhoneValidation: scope.validateMobilePhone === true,
          };
          intlTelInputService.updateIntlInputValidity(element, field, {
            validateTarget,
          });
          const isSubmitted =
            formController.$submitted || formController.submitted;
          scope.hasError = isSubmitted ? field.$invalid : false;
          if (scope.hasError) {
            intlTelInputService.removeHint(element);
          }
          if (field.$error) {
            const errors = validatePhone(field, {
              wording: { field_name: scope.fieldName },
            });
            scope.errorMessage = errors?.[0]?.message;
          }
        };

        /**
         * @param {*} field: NgModelController
         * @param {*} config
         * @returns {
         *  type: 'countryRequired' | 'numberRequired' | 'numberValidation' | 'localPhoneValidation' | 'mobilePhoneValidation' |
         *  'required' | 'parse' | 'minlength' | 'maxlength' | 'uniqueness' | 'pattern',
         *  message: string
         * }[]
         */
        const validatePhone = (field, config) => {
          const { wording } = config;
          const rules = [
            {
              type: 'countryRequired',
              condition: field.$error.countryRequired === true,
              message: $filter('translate')(
                'form.validation.calling_code',
                wording,
              ),
            },
            {
              type: 'numberRequired',
              condition: field.$error.numberRequired === true,
              message: $filter('translate')(
                'form.validation.phone.required',
                wording,
              ),
            },
            {
              type: 'numberValidation',
              condition: field.$error.numberValidation === true,
              message: $filter('translate')(
                'form.validation.phone.error',
                wording,
              ),
            },
            {
              type: 'localPhoneValidation',
              condition: field.$error.localPhoneValidation === true,
              message: $filter('translate')(
                'form.validation.phone.error',
                wording,
              ),
            },
            {
              type: 'mobilePhoneValidation',
              condition: field.$error.mobilePhoneValidation === true,
              message: $filter('translate')(
                'form.validation.phone.error',
                wording,
              ),
            },
            {
              type: 'parse',
              condition: field.$error.parse === true,
              message: $filter('translate')('form.validation.invalid', wording),
            },
            {
              type: 'minlength',
              condition: field.$error.minlength === true,
              message: $filter('translate')(
                'form.validation.minlength',
                wording,
              ),
            },
            {
              type: 'maxlength',
              condition: field.$error.maxlength === true,
              message: $filter('translate')(
                'form.validation.maxlength',
                wording,
              ),
            },
            {
              type: 'pattern',
              condition: field.$error.pattern === true,
              message: $filter('translate')('form.validation.pattern', wording),
            },
          ];
          const errors = rules.reduce((acc, { type, condition, message }) => {
            return condition ? [...acc, { type, message }] : acc;
          }, []);
          return errors;
        };
      },
    };
  },
]);
