app.directive('deliveryFormSfplusHome', [
  'mainConfig'
  '$timeout'
  '$compile'
  'checkoutService'
  'cart'
  '$q'
  '$filter'
  (
    mainConfig
    $timeout
    $compile
    checkoutService
    cart
    $q
    $filter
  ) ->
    {
      restrict: 'A'
      scope: {
        type: "@",
        address1: "@",
        address2: "@"
      }
      link: (scope, element, attrs) ->
        areas = try
            JSON.parse($('#hk-sfplus-regions-data').val())
          catch error
            [] # Catch error when the method is called on re-rendering

        $sfAreas = element
        $sfAreas
          .find("#sf-plus-home-district")
          .attr("ng-model", "district")
          .attr("ng-options", "district as (district| translateModel) for district in availableSfPlusDistricts")
          .attr("ng-change", "onSfDistrictChange()")
          .attr("ng-disabled", "!availableSfPlusDistricts.length")

        $sfAreas
          .find("#sf-plus-home-area")
          .attr("ng-model", "area")
          .attr("ng-options", "location as (location.area| translateModel) for location in availableSfPlusAreas")
          .attr("ng-disabled", "!availableSfPlusAreas.length")
          .attr("ng-change", "onSfAreaChange()")
        $sfAreas.html($compile($sfAreas.html())(scope))

        element.on 'change', 'select[name="hk_sfplus[region]"]', (event) ->
          scope.region = checkoutService.getElement('hk_sfplus[region]').val()
          scope.district = {}
          scope.area = {}
          setDistricts()
          setAreas()

        setDistricts = ->
          filtered_areas = _.filter(areas, ((d) -> return d.region.key == scope.region))
          districts = _.map(_.uniq(filtered_areas,((area)-> return area.district.en)), ((area)-> area.district))
          $timeout((() -> scope.availableSfPlusDistricts = districts), 0) if districts?

        setAreas = ->
          filtered_areas = _.filter(areas, ((area) -> return area.district.en == scope.district.en))
          $timeout((() -> scope.availableSfPlusAreas = filtered_areas), 0) if filtered_areas?

        scope.onSfAreaChange = ->
          chineseValue = scope.area.region["zh-hant"] + " " + scope.area.district["zh-hant"]+ " " + scope.area.area["zh-hant"]
          element.find("#sfplus-delivery-regions").val(chineseValue);

          displayValue = $filter("translate")('hk_sfplus.address' , {
            region: $filter('translateModel')(scope.area.region),
            district: $filter('translateModel')(scope.area.district),
            area: $filter('translateModel')(scope.area.area)
          })
          element.find("#sfplus-delivery-address2").val(displayValue);
          null

        scope.onSfDistrictChange = setAreas

        checkoutService.getElement('order[delivery_address][address_1]').val(scope.address1)
        preferAddresses = if scope.address2 then scope.address2.split(" ") else []
        if preferAddresses.length == 3
          preferRegion = _.find(areas, (a) -> return Object.values(a.region).includes(preferAddresses[0]))
          if preferRegion.region
            scope.region = preferRegion.region.key
            preferDistrict = _.find(areas, (a) -> return Object.values(a.district).includes(preferAddresses[1]))
            scope.district = preferDistrict.district
            setDistricts()
            preferArea = _.find(areas, (a) -> return Object.values(a.area).includes(preferAddresses[2]))
            scope.area = preferArea
            setAreas()
            scope.onSfAreaChange()
            checkoutService.getElement('hk_sfplus[region]').val(preferRegion.region.key)
    }
])
