import dayjs from 'dayjs';
import {
  USER_REMINDER_TYPE,
  USER_REMINDER_STORAGE_KEY,
} from '../constants/user_reminder';

const COUPON_EXPIRE_DAY = 7;

app.service('userReminderService', [
  'mainConfig',
  'featureService',
  'slFeatureService',
  'promotionService',
  '$q',
  'logger',
  function (
    mainConfig,
    featureService,
    slFeatureService,
    promotionService,
    $q,
    logger,
  ) {
    let hasUnclaimedCoupon = false;

    const getShouldCheckUnclaimedCoupons = () => {
      if (
        !slFeatureService.hasFeature('coupon_v2') ||
        !mainConfig.merchantData.promotion_setting?.show_coupon
      ) {
        return false;
      }

      const expiresDay = dayjs(
        localStorage.getItem(USER_REMINDER_STORAGE_KEY.COUPON_EXPIRES),
      );
      if (expiresDay.isAfter(dayjs())) {
        return false;
      }

      return true;
    };

    const fetchHasUnclaimedCoupon = () => {
      if (!getShouldCheckUnclaimedCoupons()) {
        return $q.resolve();
      }

      return promotionService
        .getCouponCenterCoupons()
        .then((res) => {
          hasUnclaimedCoupon = res.data.data.items.some(
            (coupon) => !coupon.is_drew,
          );
        })
        .catch((error) => {
          logger.error('failed to fetchHasUnclaimedCoupon', error);
        });
    };

    const getShouldShowMemberReferral = (campaignRule) => {
      // campaignRule also check session, see check_member_referral_signup_popup
      if (
        !featureService.hasFeature('customer_referral') ||
        mainConfig.currentUser ||
        !campaignRule
      ) {
        localStorage.removeItem(
          USER_REMINDER_STORAGE_KEY.MEMBER_REFERRAL_SL_MREF,
        );
        localStorage.removeItem(
          USER_REMINDER_STORAGE_KEY.MEMBER_REFERRAL_CLICKED,
        );
        return false;
      }

      const storageSlMref = localStorage.getItem(
        USER_REMINDER_STORAGE_KEY.MEMBER_REFERRAL_SL_MREF,
      );
      const storageClicked =
        localStorage.getItem(
          USER_REMINDER_STORAGE_KEY.MEMBER_REFERRAL_CLICKED,
        ) === 'true';
      const urlParams = new URLSearchParams(window.location.search);
      const urlSlMref = urlParams.get('sl-mref');

      if (urlSlMref && urlSlMref !== storageSlMref) {
        localStorage.setItem(
          USER_REMINDER_STORAGE_KEY.MEMBER_REFERRAL_SL_MREF,
          urlSlMref,
        );
        localStorage.setItem(
          USER_REMINDER_STORAGE_KEY.MEMBER_REFERRAL_CLICKED,
          false,
        );
        return true;
      } else if (storageSlMref && !storageClicked) {
        return true;
      }

      return false;
    };

    const getShouldShowCoupon = () => {
      if (!getShouldCheckUnclaimedCoupons()) {
        return false;
      }

      return hasUnclaimedCoupon;
    };

    const getReminderType = ({ campaignRule }) => {
      if (getShouldShowMemberReferral(campaignRule)) {
        return USER_REMINDER_TYPE.MEMBER_REFERRAL;
      }
      if (getShouldShowCoupon()) {
        return USER_REMINDER_TYPE.COUPON;
      }

      return null;
    };

    const handleClose = (reminderType) => {
      switch (reminderType) {
        case USER_REMINDER_TYPE.MEMBER_REFERRAL:
          localStorage.setItem(
            USER_REMINDER_STORAGE_KEY.MEMBER_REFERRAL_CLICKED,
            true,
          );
          break;
        case USER_REMINDER_TYPE.COUPON:
          localStorage.setItem(
            USER_REMINDER_STORAGE_KEY.COUPON_EXPIRES,
            dayjs().add(COUPON_EXPIRE_DAY, 'day').toISOString(),
          );
          break;
        default:
          throw Error(`unknown userReminderType: ${reminderType}`);
      }
    };

    const handleAction = (reminderType) => {
      switch (reminderType) {
        case USER_REMINDER_TYPE.MEMBER_REFERRAL:
          window.location.href = '/users/sign_up';
          break;
        case USER_REMINDER_TYPE.COUPON:
          window.location.href = '/coupon_center';
          break;
        default:
          throw Error(`unknown userReminderType: ${reminderType}`);
      }
    };

    return {
      getReminderType,
      handleClose,
      handleAction,
      fetchData: fetchHasUnclaimedCoupon,
    };
  },
]);
