import templateUrl from '../../../../../public/themes/shared/checkout/templates.payment_condition_panel.html';
import { CHECKOUT_EVENTS } from '../constants/angular-events';

app.directive('paymentConditionPanel', [
  '$rootScope',
  'mainConfig',
  'moneyService',
  'multiCartDeliveries',
  'multiCheckoutService',
  function (
    $rootScope,
    mainConfig,
    moneyService,
    multiCartDeliveries,
    multiCheckoutService,
  ) {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        totalOrderOver: '=',
        totalAmount: '=',
        multiCartDollars: '=',
        paymentMethods: '=',
        productsLink: '@',
        paymentCodType: '@',
      },
      link: function (scope, element) {
        scope.difference = getDifference();

        function getDifference() {
          if (
            multiCheckoutService.isToggleOn() &&
            scope.paymentCodType === 'COD'
          ) {
            return Object.entries(multiCartDeliveries).reduce(
              (sum, [cartTagId, delivery]) => {
                const paymentId = delivery.payment_method._id;
                const totalOrderOver = scope.paymentMethods[paymentId] || 0;
                if (totalOrderOver <= 0) return sum;

                const dollars = scope.multiCartDollars[cartTagId] || 0;
                const difference = totalOrderOver - dollars;
                return sum + (Math.max(difference, 0) || 0);
              },
              0,
            );
          }
          return scope.totalOrderOver - scope.totalAmount;
        }

        scope.getMoneyLabel = function (dollars) {
          const { alternate_symbol, symbol_first, iso_code } =
            mainConfig.merchantData.base_currency || {};

          return moneyService.toMoney({
            dollars,
            symbol_first,
            symbol: alternate_symbol,
            currencyIso: iso_code,
          }).label;
        };

        $rootScope.$on(CHECKOUT_EVENTS.CART.SUMMARY.LOADED, function () {
          const { alternate_symbol, symbol_first, iso_code } =
            mainConfig.merchantData.base_currency || {};

          scope.totalAmount = $('#total_amount').val();
          scope.totalAmountToMoney = moneyService.toMoney({
            cents: scope.totalAmount,
            symbol_first,
            symbol: alternate_symbol,
            currencyIso: iso_code,
          });

          const difference = getDifference();

          scope.difference = difference;
          scope.$emit('checkout.payment.condition.updated', {
            difference,
          });
          setHintAndButton();
        });

        function setHintAndButton() {
          if (scope.difference > 0) {
            $('.payment-condition-hint').show();
            $('#place-order-btn').attr('disabled', true);
            $('#place-order-recaptcha').attr('disabled', true);
          } else {
            $('.payment-condition-hint').hide();
            $('#place-order-btn').attr('disabled', false);
            $('#place-order-recaptcha').attr('disabled', false);
          }
        }

        $(document).ready(function () {
          var isV1Theme = $('body.v1_theme').length > 0;
          var getNavHeight = function () {
            if (isV1Theme) {
              return window.matchMedia('(min-width: 768px)').matches
                ? 0
                : $('#fixed-menu-container').height();
            }
            return $('nav:visible').height();
          };
          var onScroll = function () {
            var panelTop = $(element).offset().top;
            var navHeight = getNavHeight();
            var scrollTop = $(window).scrollTop();
            var targetPosition = panelTop - navHeight;
            if (scrollTop > targetPosition) {
              $('.payment-condition-panel')
                .css('top', navHeight)
                .addClass('fixed');
            } else {
              $('.payment-condition-panel')
                .css('top', 'unset')
                .removeClass('fixed');
            }
          };
          $(window).on(
            'scroll',
            _.throttle(function () {
              onScroll();
            }, 100),
          );
          setHintAndButton();
          onScroll();
        });
      },
    };
  },
]);
