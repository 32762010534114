app.directive("checkoutDeliveryEzship", [
  '$compile'
  '$filter'
  'ordersService'
  (
    $compile
    $filter
    ordersService
  ) ->
    {
      restrict: 'A'
      scope: {
        getFormData: "&",
        ezShipMerchantId: "@"
      }
      link: (scope, element, attrs) ->
        scope.pickStore = () ->
          data = scope.getFormData()
          ordersService.saveSessionData(data.order, {
            "save_fields_phone": data.saveFields.phone,
          }).then((data,status) ->
            returnURL = sprintf("%s//%s/callback", window.location.protocol, window.location.host)
            userId = scope.ezShipMerchantId
            processId = "0"
            postRedirect('https://map.ezship.com.tw/ezship_map_web.jsp', {
              suID: userId,
              processID: processId,
              rtURL: returnURL,
            })
          )

        postRedirect = (url, postData) ->
          postFormStr = sprintf("<form method='POST' action='%s' >\n", url)
          for key of postData
            if postData.hasOwnProperty(key)
              postFormStr += '<input type=\'hidden\' name=\'' + key + '\' value=\'' + postData[key] + '\'></input>'

          postFormStr += "<input type='submit' value='Submit' /></form>" # IE needs a submit button to work
          formElement = $(postFormStr)
          # submit doesn't work on IE if the form element is not attached to anything
          $('body').append(formElement)
          $(formElement).submit()

        $container = element
        if $container.length == 1
          $container.find(".btn-pick-store").attr("ng-click", "pickStore()")
          $container.html($compile($container.html())(scope))
    }
])