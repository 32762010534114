/**
 * link: https://shopline.atlassian.net/wiki/spaces/EN/pages/2404122807/intlTelInputCheckout+intlTelInputService
 */
app.directive('intlTelInputCheckout', [
  'mainConfig',
  'intlTelInputService',
  function (mainConfig, intlTelInputService) {
    return {
      restrict: 'C',
      link: function (scope, element, attrs) {
        const handleCountryChange = () => {
          intlTelInputService.renderCountryISO(element);
          intlTelInputService.updateInputPaddingLeft(element);
          const hasPlaceholderAndHint = element.parents(
            '.iti--allow-dropdown.without-country-code',
          ).length;
          if (
            hasPlaceholderAndHint &&
            intlTelInputService.getCountry('abbr', element)
          )
            intlTelInputService.removePlaceholderAndHint(element);
          if (
            !hasPlaceholderAndHint &&
            !intlTelInputService.getCountry('abbr', element)
          )
            intlTelInputService.insertPlaceholderAndHint(element);
        };

        /* setup listener */
        function cleanUp() {
          element.off();
        }
        element.on('countrychange', handleCountryChange);
        scope.$on('$destroy', cleanUp);

        if (attrs.isFormat === 'true') {
          const validateAndFormatPhone = () => {
            const errorMessages = intlTelInputService.validatePhone(element);
            if (errorMessages.length === 0) {
              const formattedPhone = intlTelInputService.formatPhone(element);
              element.trigger('formattedphonechange', formattedPhone);
            } else {
              const numericPhone = intlTelInputService.filterNonNumeric(
                element,
              );
              element.trigger('formattedphonechange', numericPhone);
            }
          };
          element.on('countrychange', validateAndFormatPhone);
          element.on('input', validateAndFormatPhone);
        }
      },
    };
  },
]);
