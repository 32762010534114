app.service('sevenElevenService', [
  'browserService',
  function (browserService) {
    const SEVEN_ELEVEN_E_MAP = {
      desktop: 'https://emap.presco.com.tw/c2cemap.ashx',
      mobile: 'https://emap.presco.com.tw/c2cemapm-u.ashx',
    };

    const SEVEN_ELEVEN_DELIVERIES = [
      'tw_711_pay',
      'tw_711_nopay',
      'tw_711_b2c_pay',
      'tw_711_b2c_nopay',
      'emap_only_711',
    ];

    const isSevenEleven = (region_type) =>
      SEVEN_ELEVEN_DELIVERIES.includes(region_type);

    const getEMapCallbackUrl = () =>
      `${window.location.origin}/callback?isSameTab=true`;

    const getEMapUrl = () => {
      return browserService.isMobile()
        ? SEVEN_ELEVEN_E_MAP.mobile
        : SEVEN_ELEVEN_E_MAP.desktop;
    };

    return {
      SEVEN_ELEVEN_E_MAP,
      getEMapUrl,
      isSevenEleven,
      getEMapCallbackUrl,
    };
  },
]);
