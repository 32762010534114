app.directive('zeekPickUpForm', [
  'slLogisticsStationService',
  function (slLogisticsStationService) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/shared/checkout/templates.zeek_pick_up_form.html'),
      link: function (scope, element, attr) {
        var companyCode = attr.companyCode;
        var countryCode = 'HK';

        var init = function () {
          scope.zeekPickupStatus.provinces = [];
          scope.zeekPickupStatus.provinceOptions = [];
          scope.zeekPickupStatus.cityOptions = [];
          scope.zeekPickupStatus.storeOptions = [];
          scope.zeekPickupStatus.selectedProvince = '';
          scope.zeekPickupStatus.selectedCity = '';
          scope.zeekPickupStatus.selectedDistrict = '';
          scope.zeekPickupStatus.selectedStore = '';
          return slLogisticsStationService
            .getProvinceList({
              companyCode: companyCode,
              countryCode: countryCode,
            })
            .then(function (res) {
              scope.zeekPickupStatus.provinces = _.clone(res.data.area_nodes);
              scope.zeekPickupStatus.provinceOptions = _.map(
                scope.zeekPickupStatus.provinces,
                'name',
              );
              scope.zeekPickupStatus.selectedProvince =
                scope.zeekPickupStatus.provinceOptions[0];
              scope.onProvinceChange();
            });
        };

        if (!scope.zeekPickupStatus.provinces) init();

        var getStoreList = function () {
          slLogisticsStationService
            .getStoreList({
              companyCode: companyCode,
              province: scope.zeekPickupStatus.selectedProvince,
              city: scope.zeekPickupStatus.selectedCity,
              countryCode: countryCode,
            })
            .then(function (res) {
              scope.zeekPickupStatus.storeOptions = res.data.data;
            })
            .catch(function () {
              scope.storeError = true;
              scope.selectedCity = '';
            });
        };

        var generateCityOptions = function () {
          if (
            scope.zeekPickupStatus.selectedProvince === '' ||
            scope.zeekPickupStatus.provinces.length === 0
          )
            return [];
          var targetAreaNodes = _.find(
            scope.zeekPickupStatus.provinces,
            function (province) {
              return province.name === scope.zeekPickupStatus.selectedProvince;
            },
          ).area_nodes;
          return targetAreaNodes
            ? targetAreaNodes.map(function (node) {
                return node.name;
              })
            : [];
        };

        scope.onProvinceChange = function () {
          scope.zeekPickupStatus.selectedCity = '';
          scope.zeekPickupStatus.selectedDistrict = '';
          scope.zeekPickupStatus.selectedStore = '';
          scope.onZeekLocationChange({});
          scope.zeekPickupStatus.cityOptions = generateCityOptions();
        };

        scope.onCityChange = function () {
          scope.zeekPickupStatus.selectedDistrict = '';
          scope.zeekPickupStatus.selectedStore = '';
          scope.zeekPickupStatus.storeError = false;
          scope.onZeekLocationChange({});
          if (scope.zeekPickupStatus.selectedCity) {
            getStoreList();
          }
        };

        scope.onStoreChange = function () {
          scope.onZeekLocationChange({
            location_code: scope.zeekPickupStatus.selectedStore.station_seq,
            province: scope.zeekPickupStatus.selectedProvince,
            city: scope.zeekPickupStatus.selectedCity,
            address_1: scope.zeekPickupStatus.selectedStore.station_address,
            location_name: scope.zeekPickupStatus.selectedStore.station_name,
            ref_data: {
              locker_brand: scope.zeekPickupStatus.selectedStore.desc,
            },
          });
        };
      },
    };
  },
]);
