app.controller('FacebookInitController', [
  'fbService',
  '$attrs',
  function (fbService, $attrs) {
    var crtl = this;

    fbService.init($attrs.facebookSdkVersion).then(function () {
      crtl.doneFbInit = true;
    });
  },
]);
