import { onTabActive } from '../features/member-center';

app.controller('MemberPointsController', [
  '$scope',
  '$rootScope',
  '$uibModal',
  '$timeout',
  '$filter',
  'mainConfig',
  'memberPointsService',
  'promotionService',
  'moneyService',
  '$element',
  function (
    $scope,
    $rootScope,
    $uibModal,
    $timeout,
    $filter,
    mainConfig,
    memberPointsService,
    promotionService,
    moneyService,
    $element,
  ) {
    $scope.memberPoints = [];
    $scope.pagination = {
      total: 0,
      itemsPerPage: 0,
      current: 0,
    };
    $scope.state = {
      loadingItems: false,
      loadingSummary: false,
      loadingRule: false,
    };
    $scope.errors = {
      loadMoreError: false,
      loadSummaryError: false,
      loadingRuleError: false,
    };
    $scope.isPromotionExist = false;
    $scope.memberPointRules = [];
    $scope.memberPointsNeverExpire = {};
    $scope.moneyService = moneyService;
    var currentLocale = mainConfig.localeData.loadedLanguage.code;
    $scope.isMemberPointEnable =
      mainConfig.merchantData.user_setting.data.enable_member_point;
    $scope.showRuleModal = function () {
      var ruleRemarks = $scope.ruleRemarks;
      var ruleDescription = validateRemark(ruleRemarks[currentLocale])
        ? ruleRemarks[currentLocale]
        : ruleRemarks['en'];
      $rootScope.currentModal = $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/users/templates.dialog.member_point_rule.html'),
        controller: [
          '$scope',
          '$filter',
          '$uibModalInstance',
          function ($scope, $filter, $uibModalInstance) {
            $scope.ruleDescription = ruleDescription;
            $scope.confirm = function () {
              $uibModalInstance.close();
            };
            $timeout(function () {
              $('.promotion-term-dialog .scroll-area').overlayScrollbars({
                overflowBehavior: { x: 'hidden' },
              });
              $('.promotion-term-modal-backdrop').one('click', function () {
                $uibModalInstance.dismiss('cancel');
              });
            });
          },
        ],
        windowClass: 'promotion-term-modal-window',
        backdropClass: 'promotion-term-modal-backdrop',
      });
      $rootScope.currentModal.opened = $rootScope.currentModal.opened.then(
        function () {
          $rootScope.$emit('modal.open');
        },
      );
    };
    $scope.getMemberPointSummary = function () {
      $scope.errors.loadSummaryError = false;
      $scope.state.loadingSummary = true;
      var limit = 2;
      memberPointsService
        .getFulfillmentSummary(limit)
        .then(function (response) {
          var res = response.data;
          $scope.memberPointsFulfillments = res.items;
          $scope.remainingPointSummary = res.remaining_value;
          $scope.memberPointsNeverExpire = res.never_expire_item || {};
        })
        .catch(function () {
          $scope.errors.loadSummaryError = true;
        })
        .finally(function () {
          $scope.state.loadingSummary = false;
        });
    };

    $scope.getMemberPointRules = function () {
      $scope.state.loadingRule = true;
      memberPointsService
        .getMemberPointRules()
        .then(function (response) {
          $scope.memberPointRules = response.data.items;
          const earnFromOrderRuleArray = $scope.memberPointRules.filter(
            (rule) => rule.rule_type === 'earn_from_order',
          );
          let nullMembershipTierRule;
          const earnFromOrderRule =
            earnFromOrderRuleArray.find((rule) => {
              if (
                rule.membership_tier_id ===
                  mainConfig.currentUser.membership_tier_id &&
                rule.status === 'active'
              )
                return rule;
              if (rule.membership_tier_id === null && !nullMembershipTierRule) {
                nullMembershipTierRule = rule;
              }
            }) || nullMembershipTierRule;
          $scope.ruleRemarks = earnFromOrderRule.remarks_translations;
          var redeemOrderRule = _.find($scope.memberPointRules, function (
            rule,
          ) {
            return rule.rule_type !== 'earn_from_order';
          });
          if ($scope.isMemberPointEnable) {
            $scope.isRedeemOrderActive = redeemOrderRule.status === 'active';
            const isEarnFromOrderActive = earnFromOrderRule.status === 'active';
            var costMoney = moneyService.toMoney({
              dollars: earnFromOrderRule.unit_price,
            }).label;
            var discountMoney = moneyService.toMoney({
              dollars: redeemOrderRule.price_per_unit,
            }).label;

            const allDescription = [
              {
                match: $scope.isRedeemOrderActive && isEarnFromOrderActive,
                description: $filter('translate')(
                  'member_points.redeem_cash_and_earn_from_order_description',
                  {
                    costMoney: costMoney,
                    earnPoint: earnFromOrderRule.points_per_unit,
                    costPoint: redeemOrderRule.unit_point,
                    discountMoney: discountMoney,
                  },
                ),
              },
              {
                match: isEarnFromOrderActive,
                description: $filter('translate')(
                  'member_points.earn_from_order_description',
                  {
                    costMoney: costMoney,
                    earnPoint: earnFromOrderRule.points_per_unit,
                  },
                ),
              },
              {
                match: $scope.isRedeemOrderActive,
                description: $filter('translate')(
                  'member_points.redeem_cash_description',
                  {
                    costPoint: redeemOrderRule.unit_point,
                    discountMoney: discountMoney,
                  },
                ),
              },
            ];

            $scope.memberPointRulesDescription =
              allDescription.find((description) => description.match)
                ?.description || '';

            var pointEqualCashValue =
              parseInt(
                ($scope.currentUser.member_point_balance || 0) /
                  redeemOrderRule.unit_point,
              ) * redeemOrderRule.price_per_unit;
            var pointEqualCash = moneyService.toMoney({
              dollars: pointEqualCashValue,
            }).label;
            $scope.pointEqualCash = pointEqualCash;
          }
        })
        .catch(function () {
          $scope.errors.loadRuleError = true;
        })
        .finally(function () {
          $scope.state.loadingRule = false;
        });
    };
    var validateRemark = function (remark) {
      return !!remark;
    };
    $scope.getMemberPoints = function () {
      $scope.errors.loadMoreError = false;
      if (
        $scope.pagination.current > $scope.maxPage ||
        $scope.state.loadingItems
      ) {
        return;
      }
      $scope.state.loadingItems = true;
      memberPointsService
        .getMemberPoints($scope.pagination.current + 1)
        .then(function (response) {
          $scope.pagination.current += 1;
          var data = response.data;
          data.items.forEach(function (item) {
            if (!_.findWhere($scope.memberPoints, { _id: item._id })) {
              $scope.memberPoints.push(item);
            }
          });
          $scope.pagination.total = data.total;
          $scope.pagination.itemsPerPage = data.limit;
          $scope.maxPage = Math.ceil(
            $scope.pagination.total / $scope.pagination.itemsPerPage,
          );
        })
        .catch(function () {
          $scope.errors.loadMoreError = true;
        })
        .finally(function () {
          $scope.state.loadingItems = false;
        });
    };
    $scope.getPromotionExistOrNot = function () {
      promotionService
        .checkRedeemGiftPromotionExist()
        .then(function (response) {
          $scope.isPromotionExist = response.data.result;
        });
    };
    $scope.getMemberPointSummary();
    $scope.getMemberPointRules();
    $scope.getMemberPoints();
    $scope.getPromotionExistOrNot();

    onTabActive($element, () => {
      history.replaceState(null, null, `member_points${location.search}`);
    });
  },
]);
