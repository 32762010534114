app.directive('promotionDateInfo', [
  'featureService',
  'promotion',
  '$filter',
  function (featureService, promotion, $filter) {
    return {
      restrict: 'E',
      template: '<div ng-bind-html="dateString"></div>',
      scope: {
        type: '@',
      },
      link: function (scope) {
        var dateStringFormat = 'YYYY/MM/DD HH:mm';

        var date =
          scope.type === 'start'
            ? dayjs(promotion.start_at).format(dateStringFormat)
            : dayjs(promotion.end_at).format(dateStringFormat);

        var translateKey = null;

        switch (promotion.discount_type) {
          case 'member_point_redeem_gift':
            translateKey =
              scope.type === 'start'
                ? 'promotions.page.redeem_gift.start_at'
                : 'promotions.page.redeem_gift.end_at';
            break;
          default:
            null;
        }

        scope.dateString =
          scope.type === 'end' && !promotion.end_at
            ? null
            : $filter('translate')(translateKey, { date: date });
      },
    };
  },
]);
