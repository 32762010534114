app.directive('formNumberInput', [
  '$timeout',
  '$filter',
  function ($timeout, $filter) {
    return {
      restrict: 'E',
      scope: {
        itemId: '@',
        label: '@',
        min: '@',
        max: '@',
        isRequired: '@',
      },
      require: ['^form'],
      templateUrl: require('../../../../../public/themes/v1/default/views/form.number-input.html'),
      link: function ($scope, element, attrs, ctrls) {
        var formController = ctrls[0];
        var min = $scope.min.length ? parseInt($scope.min, 10) : undefined;
        var max = $scope.max.length ? parseInt($scope.max, 10) : undefined;

        var shouldLimitBtnDisabled = function (number) {
          if (number === undefined) return true;
          if (number.length === 0) return true;
        };
        var shouldeMinBtnDisabled = function (number, min) {
          return (
            shouldLimitBtnDisabled(number) ||
            (min !== undefined && number <= min)
          );
        };
        var shouldeMaxBtnDisabled = function (number, max) {
          return (
            shouldLimitBtnDisabled(number) ||
            (max !== undefined && number >= max)
          );
        };

        $scope.isMinusDisabled = shouldeMinBtnDisabled($scope.number, min);
        $scope.isPlusDisabled = shouldeMaxBtnDisabled($scope.number, max);

        var debouncePromise;
        $scope.onChange = function () {
          var value = event.target.value;
          if (debouncePromise) $timeout.cancel(debouncePromise);
          debouncePromise = $timeout(function () {
            $scope.number = rectify(value, min, max);
          }, 450);
        };

        $scope.decrease = function () {
          $scope.number = rectify($scope.number - 1, min, max);
        };

        $scope.increase = function () {
          $scope.number = rectify($scope.number + 1, min, max);
        };

        $scope.$watch('number', function (next) {
          $scope.isMinusDisabled = shouldeMinBtnDisabled(next, min);
          $scope.isPlusDisabled = shouldeMaxBtnDisabled(next, max);
        });

        var rectify = function (value, min, max) {
          if (value) {
            if (typeof value === 'string') {
              // product assumes number input is integer for simplicity for now
              value = value.replace(/\D/g, '');
              value = parseInt(value, 10);
            }
            if (min !== undefined && value < min) return min;
            if (max !== undefined && value > max) return max;
          }
          return value;
        };

        var validate = function () {
          var fieldObject = formController[$scope.itemId];
          if (fieldObject == undefined) return;

          if (formController.$submitted || formController.submitted) {
            $scope.hasError = fieldObject.$invalid;
          } else {
            $scope.hasError = false;
          }
          $scope.error = fieldObject.$error;
        };

        $scope.$watch(function () {
          return validate();
        });

        $scope.$watchGroup(['hasError', 'error.required'], function () {
          if ($scope.error.required === true) {
            $scope.errorMessage = $filter(
              'translate',
            )('form.validation.required', { field_name: $scope.label });
            return;
          }
        });
      },
    };
  },
]);
