app.service('customValidationService', [
  function () {
    var self = this;
    self.checkTwId = function (id) {
      if (typeof id !== 'string') return false;
      if (id.length !== 10) return false;

      var activeLetters = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
      var idLetter = id[0];
      var index = activeLetters.indexOf(idLetter);
      if (index == -1) return false;

      var multipliers = [9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
      var letterMultiplier1 = [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        3,
        3,
        3,
        3,
        3,
        3,
      ];
      var letterMultiplier2 = [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        0,
        1,
        2,
        3,
        4,
        5,
      ];
      var sum = letterMultiplier1[index] + letterMultiplier2[index] * 9;

      for (var i = 1; i < 10; i++) {
        if (isNaN(id[i])) return false;
        sum += id[i] * multipliers[i];
      }
      return sum % 10 === 0;
    };
    self.englishAddressPattern = "/^[a-zA-Z0-9,.'-/() ]*$/";
  },
]);
