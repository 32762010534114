(function () {
  function toggleActiveClass(el, isActive) {
    if (isActive) {
      if (el.className.split(' ').indexOf('active') < 0) {
        el.className += ' active';
      }
    } else {
      el.className = el.className.replace(/ active/, '');
    }
  }
  function findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }
  window.onTabsetTabClicked = function (event) {
    if (event) {
      var tab = event.target;
      var target = tab.getAttribute('href').replace(/^#/, '');
      var tabset = findAncestor(tab, 'Tabset');
      [].forEach.call(tabset.getElementsByClassName('Tab-label'), function (t) {
        toggleActiveClass(t, t == tab);
      });
      [].forEach.call(tabset.getElementsByClassName('Tab-content'), function (
        c,
      ) {
        toggleActiveClass(c, c.id == target);
      });
      if (window.innerWidth < 768) {
        var targetTab = document.getElementById(target);
        var anchor;
        if (targetTab && targetTab.getAttribute('scroll-to').length) {
          anchor = document.querySelector(targetTab.getAttribute('scroll-to'));
        } else {
          anchor = tabset.firstElementChild;
        }
        var offset = parseInt(targetTab.getAttribute('scroll-offset'), 10) || 0;
        window.scrollTo(
          0,
          anchor.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        );
      }
    }
  };
})();
