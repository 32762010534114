import { CHECKOUT_EVENTS } from '../constants/angular-events';

app.directive('rewardCreditSummary', [
  'checkoutService',
  '$compile',
  'logger',
  function (checkoutService, $compile, logger) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        scope.$on(CHECKOUT_EVENTS.CART.SUMMARY.CHANGED, function () {
          checkoutService
            .requestPartial('cart', 'reward_credit_summary')
            .then(function (res) {
              element.html(res.data);
            })
            .catch(function (err) {
              logger.log('Unable to load reward credit', err);
            });
        });
        scope.$on(CHECKOUT_EVENTS.CART.SUMMARY.RELOAD, function () {
          checkoutService
            .requestPartial('cart', 'reward_credit_summary')
            .then(function (res) {
              element.html(res.data);
            })
            .catch(function (err) {
              logger.log('Unable to load reward credit', err);
            });
        });
      },
    };
  },
]);
