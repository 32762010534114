app.controller('CartsController', [
  '$rootScope',
  '$scope',
  '$http',
  '$window',
  'cartService',
  'merchantService',
  'mainConfig',
  'Analytics',
  '$filter',
  'productService',
  'trackerService',
  'gaService',
  'imageService',
  'hiidoTrackerService',
  'fastCheckoutService',
  'logger',
  'slFeatureService',
  'productSetService',
  function (
    $rootScope,
    $scope,
    $http,
    $window,
    cartService,
    merchantService,
    mainConfig,
    Analytics,
    $filter,
    productService,
    trackerService,
    gaService,
    imageService,
    hiidoTrackerService,
    fastCheckoutService,
    logger,
    slFeatureService,
    productSetService,
  ) {
    $scope.checkoutButtonLoading = false;
    $scope.isProductSetEnabled = slFeatureService.hasFeature('product_set');
    $scope.isProductSetRevampEnabled = slFeatureService.hasFeature(
      'product_set_revamp',
    );
    $scope.state = {
      removingItems: [],
      variationOpenedChildren: new Set(),
    };

    $scope.$window = $window;

    if (!$rootScope.registerOnClickBuyNow) {
      $rootScope.registerOnClickBuyNow = $rootScope.$on(
        'clicked_buy_now_button',
        function () {
          if (!$scope.checkoutButtonLoading) {
            $scope.onCheckoutClicked(null, { isBuyNow: true });
          }
        },
      );
    }

    $scope.onCheckoutClicked = function (event, option) {
      var useFastcheckoutCart =
        option && option.isBuyNow && fastCheckoutService.isEnableEc;
      if (!cartService.isAllRedeemGift()) {
        var cartItems = useFastcheckoutCart
          ? cartService.getFastCheckoutCartItems()
          : $rootScope.currentCart.getItems();
        if (Analytics.configuration.enhancedEcommerce) {
          gaService.setUserId();
          for (var x = 0; x < cartItems.length; x++) {
            // Analytics.addProduct('sku-2', 'Test Product 2', 'Category-1', 'Brand 2', 'variant-3', 'price 2499', 'int quantity', 'COUPON', 'int position');
            try {
              Analytics.addProduct(
                productService.getSku(
                  cartItems[x].product_id,
                  cartItems[x].sku,
                  cartItems[x].variation,
                ),
                $filter('translateModel')(
                  cartItems[x].product.title_translations,
                ),
                '',
                '',
                productService.getVariationName(cartItems[x].variation),
                cartService.getItemPrice(cartItems[x]).dollars.toString(),
                cartItems[x].quantity,
                '',
                '0',
              );
            } catch (e) {
              logger.error(e);
            }
          }
          Analytics.trackCheckout(1, 'Checkout');
          Analytics.trackEvent('UX', 'checkout', 'Checkout', undefined, true);
        }
        var subtotal = useFastcheckoutCart
          ? $rootScope.currentCart.getFastCheckoutSubtotal()
          : $rootScope.currentCart.getSubtotal();
        trackerService.fbInitiateCheckout(cartItems, subtotal);
        trackerService.track({
          type: trackerService.generalEventType.START_CHECKOUT,
          data: {
            items: cartItems,
            subtotal,
          },
        });
        hiidoTrackerService.cart.checkout();
      }

      $scope.checkoutButtonLoading = true;
      if (option && option.isBuyNow) {
        // Buy Now flow
        if (fastCheckoutService.isEnableEc) {
          var fastCheckoutCartItems = cartService.getFastCheckoutCartItems();
          if (Analytics.configuration.enhancedEcommerce) {
            Analytics.trackCheckout(2, 'InitateCheckout');
            Analytics.trackEvent(
              'UX',
              'initate_checkout',
              'InitateCheckout',
              undefined,
              true,
            );
          }
          const subtotal = $rootScope.currentCart.getFastCheckoutSubtotal();
          trackerService.track({
            type: trackerService.generalEventType.INITIATE_CHECKOUT,
            data: {
              items: fastCheckoutCartItems,
              subtotal,
            },
          });
          $window.location =
            '/fast_checkout?product_id=' + fastCheckoutCartItems[0].product_id;
          return;
        }
      } else {
        // mini cart checkout flow
        if (fastCheckoutService.isEnableEc) {
          if (Analytics.configuration.enhancedEcommerce) {
            Analytics.trackCheckout(2, 'InitateCheckout');
            Analytics.trackEvent(
              'UX',
              'initate_checkout',
              'InitateCheckout',
              undefined,
              true,
            );
          }
          const fastCheckoutCartItems = cartService.getFastCheckoutCartItems();
          const subtotal = $rootScope.currentCart.getFastCheckoutSubtotal();
          trackerService.track({
            type: trackerService.generalEventType.INITIATE_CHECKOUT,
            data: {
              items: fastCheckoutCartItems,
              subtotal,
            },
          });
          $window.location = '/fast_checkout';
          return;
        }
      }

      if ($scope.isInIframe()) {
        var win = window.open(mainConfig.checkoutLandingPath, '_blank');
        win.focus();
      } else {
        $window.location = mainConfig.checkoutLandingPath;
      }
    };

    $scope.isInIframe = function () {
      try {
        return window.self !== window.top;
      } catch (e) {
        //console.log("iframe check error");
        return true;
      }
    };

    $scope.removeItemFromCart = function (item_id) {
      if ($scope.state.removingItems.indexOf(item_id) < 0) {
        $scope.state.removingItems.push(item_id);
        cartService.removeItem(
          item_id,
          function (data, id) {
            var index = $scope.state.removingItems.indexOf(id);
            $scope.state.removingItems.splice(index, 1);
          },
          { pageType: 'cart' },
          { skip_reapply_promotion: true },
        );
      }
    };

    $scope.getImgSrc = function (item, size) {
      return imageService.getMediaImageUrl(
        (item.variation || {}).media
          ? item.variation.media
          : item.product.cover_media,
        { size: size },
      );
    };

    $scope.checkJoinVariantsEnabled = function () {
      var enableTheme = ['sangria'];
      var currentTheme = $rootScope.mainConfig.merchantData.current_theme_key;
      return enableTheme.includes(currentTheme);
    };

    $scope.joinVariants = function (item) {
      if (item.variation && item.variation.fields) {
        var variants = [];
        item.variation.fields.forEach(function (item) {
          variants.push($filter('translateModel')(item.name_translations));
        });
        return variants.join(', ');
      }
    };

    $scope.checkCanRenderPaypalSPButton = function () {
      var cartItems = $rootScope.currentCart.getItems();
      var paypalPaymentId = $('#paypal-payment-id').val();
      var hasBlockThePaypalPayment = false;
      if (Array.isArray(cartItems)) {
        cartItems.forEach(function (item) {
          if (
            item.product &&
            item.product.blacklisted_payment_ids &&
            item.product.blacklisted_payment_ids.includes(paypalPaymentId)
          ) {
            hasBlockThePaypalPayment = true;
          }
        });
      }
      return !hasBlockThePaypalPayment;
    };

    $scope.getChildVariationShorthand = productSetService.getChildVariationName;
    $scope.getSelectedItemDetail = productSetService.getSelectedItemDetail;

    $scope.shouldShowDeleteButton =
      !slFeatureService.hasFeature('lock_cart_sc_product') ||
      !mainConfig.merchantData.checkout_setting?.enable_lock_cart_sc_product;

    $scope.onClickOpenVariation = (id) =>
      $scope.state.variationOpenedChildren.add(id);
  },
]);
