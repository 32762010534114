app.directive('promotionExcludedItem', [
  '$filter',
  '$document',
  '$window',
  'userAgentService',
  function ($filter, $document, $window, userAgentService) {
    return {
      restrict: 'C',
      template: '*',
      scope: {
        itemId: '@',
        hasOrderPromotions: '=',
        hasMemberPromotions: '=',
        hasUserCredit: '=',
        hasMemberPoint: '=',
        displayHint: '=',
      },
      link: function (scope, element) {
        const cartItemElement = $document.find(
          '.exclude-promotion-product-' + scope.itemId,
        );

        const hints = [
          ...(scope.hasMemberPromotions
            ? ['promotions.exclude.items.hint.member_tier']
            : []),
          ...(scope.hasOrderPromotions
            ? ['promotions.exclude.items.hint.order_discount']
            : []),
          ...(scope.hasUserCredit
            ? ['promotions.exclude.items.hint.user_credit']
            : []),
          ...(scope.hasMemberPoint
            ? ['promotions.exclude.items.hint.member_point']
            : []),
        ];

        // prettier-ignore
        const hintListHtml = hints.map((i18nKey) => `
          <li>
            ${$filter('translate')(i18nKey)}
          </li>
        `).join('');

        function initialPopUp() {
          if (cartItemElement.length <= 0 || !scope.displayHint) {
            return;
          }
          cartItemElement.popover({
            animation: true,
            placement: 'right',
            html: true,
            trigger: 'manual',
            content: '<div>' + '</div>',
            template: `
              <div class="popover excluded-promotion-item-panel" role="tooltip">
                <div>
                  <span>
                    ${$filter('translate')(
                      'promotions.exclude.items.hint.title',
                    )}
                  </span>
                </div>
                <ul class="hint-list">
                  ${hintListHtml}
                </ul>
              </div>
            `,
          });
          cartItemElement.popover('show');
        }

        function setPopover() {
          var position =
            $window.innerWidth > 767
              ? 'right'
              : $window.innerWidth / 2 > element.offset().left
              ? 'right'
              : 'left';
          var popoverElement = $document.find(
            '.excluded-promotion-item-panel.in',
          );

          if (popoverElement.length > 0) {
            var elementPosition = element[0].getBoundingClientRect();
            popoverElement[0].style.position = 'fixed';
            popoverElement[0].style.left = elementPosition.left + 15 + 'px';
            popoverElement[0].style.top = elementPosition.top - 30 + 'px';
            if ($window.innerWidth <= 767) {
              popoverElement[0].style.left = '0px';
              var popupPosition = popoverElement[0].getBoundingClientRect();
              var cartItemPosition = cartItemElement[0].getBoundingClientRect();
              if (position === 'right') {
                if (
                  cartItemPosition.right -
                    elementPosition.right -
                    popupPosition.width >
                  0
                ) {
                  popoverElement[0].style.left = elementPosition.right + 'px';
                } else {
                  popoverElement[0].style.top = elementPosition.top + 30 + 'px';
                }
              } else {
                if (elementPosition.left < popupPosition.width) {
                  popoverElement[0].style.top = elementPosition.top + 30 + 'px';
                } else {
                  var leftPosition =
                    elementPosition.left - popupPosition.width - 15;
                  popoverElement[0].style.left = leftPosition + 'px';
                }
              }
            }
          }
        }

        function clearCurrentPopover() {
          var lastPopover = $document.find('.excluded-promotion-item-panel');
          if (lastPopover.length > 0) {
            lastPopover.popover('hide');
          }
        }

        function setPopoverTop() {
          var popoverElement = $document.find(
            '.excluded-promotion-item-panel.in',
          );
          if (popoverElement.prev().attr('data-item-id') !== scope.itemId) {
            return;
          }
          var starElement = $document.find(
            'span.promotion-excluded-item[item-id="' + scope.itemId + '"]',
          );
          if (starElement.length <= 0) {
            return;
          }
          var position = starElement[0].getBoundingClientRect();
          popoverElement[0].style.top = position.top - 30 + 'px';
        }

        if (userAgentService.isMobi() || userAgentService.isiOS()) {
          angular.element($window).bind('scroll', function () {
            setPopoverTop();
          });

          angular.element($window).bind('click', function (e) {
            if (e.target.innerText !== '*') {
              clearCurrentPopover();
            }
          });

          element.on('click', function () {
            var popoverElement = $document.find(
              '.excluded-promotion-item-panel.in',
            );
            if (popoverElement.length > 0) {
              clearCurrentPopover();
              if (popoverElement.prev().attr('data-item-id') === scope.itemId) {
                return;
              }
            }
            initialPopUp();
            setPopover();
          });
        } else {
          element.on('mouseenter', function () {
            initialPopUp();
            setPopover();
          });

          element.on('mouseleave', function () {
            cartItemElement.popover('hide');
          });
        }
      },
    };
  },
]);
