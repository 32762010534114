import { validateBirthday, getJsMonth, getFilteredBirthday } from '../../utils/birthday';
import { BOOLEAN_STATUS, GENERAL_FIELD_TYPE } from '../../constants/userSetting';
import { BIRTHDAY_FORMAT } from '../constants/user';

app.directive("checkoutCustomerForm", [
  '$compile'
  '$timeout'
  '$rootScope'
  'mainConfig'
  'checkoutService'
  'intlTelInputService'
  '$http'
  '$q'
  'customerInfo'
  'settingService'
  'logger'
  (
    $compile
    $timeout
    $rootScope
    mainConfig
    checkoutService
    intlTelInputService
    $http
    $q
    customerInfo
    settingService
    logger
  ) ->
    {
      restrict: 'A'
      scope: {}
      link: (scope, element, attrs) ->
        # to make child directive get scope, due to html is not rendered by templateUrl
        element.html $compile(element.html())(scope)

        scope.BIRTHDAY_FORMAT = BIRTHDAY_FORMAT
        scope.userSetting = {}
        scope.birthdayForm = {
          year: Number(customerInfo.sessionBirthYear) || null,
          month: Number(customerInfo.sessionBirthMonth) || null,
          date: Number(customerInfo.sessionBirthDay) || null,
        }
        scope.birthdayPickerDisabled = false
        scope.hasBirthdaySubmitError = false

        scope.userSetting = settingService.getUserSettingFromMainConfig();

        fetchData = () ->
          # For validateFormField birthday validate
          $rootScope.minimumAgeLimit = scope.userSetting.minimum_age_limit
          $rootScope.birthdayFormat = scope.userSetting.birthday_format
          $rootScope.birthdayRequired =
            scope.userSetting.general_fields?.find (field) -> field.type == GENERAL_FIELD_TYPE.BIRTHDAY
            ?.options.order.required == BOOLEAN_STATUS.TRUE

          if ($rootScope.currentUser)
            { year: userBirthYear, month: userBirthMonth, date: userBirthDate } = getFilteredBirthday({
              birthdayFormat: scope.userSetting.birthday_format,
              year: $rootScope.currentUser.birth_year,
              month: $rootScope.currentUser.birth_month,
              date: $rootScope.currentUser.birth_day,
            })
            # if user has a valid birthday already, disable birthday-picker
            scope.birthdayPickerDisabled =
              validateBirthday({
                birthdayFormat: scope.userSetting.birthday_format,
                year: userBirthYear,
                month: getJsMonth(userBirthMonth),
                date: userBirthDate,
              }) == null

            scope.birthdayForm.year = userBirthYear || scope.birthdayForm.year
            scope.birthdayForm.month = userBirthMonth || scope.birthdayForm.month
            scope.birthdayForm.date = userBirthDate || scope.birthdayForm.date

        # TODO: after all data is from api, remove this if
        if element.find('birthday-picker').length > 0
          fetchData()

        # This issue should have been fixed by SL-39972.
        # We will wait and see if it will recur. Once confirmed, we can delete the changes of SL-35217.
        scope.$watch(() ->
          $rootScope.hasCheckoutBirthdaySubmitError
        ,() ->
          scope.hasBirthdaySubmitError = $rootScope.hasCheckoutBirthdaySubmitError
          if scope.birthdayPickerDisabled && scope.hasBirthdaySubmitError
            logger.error(
              'Checkout has valid birthday but submit error',
              {
                user_id: $rootScope.currentUser._id,
                birth_year: $rootScope.currentUser.birth_year,
                birth_month: $rootScope.currentUser.birth_month,
                birth_day: $rootScope.currentUser.birth_day,
                birthdayFormat: scope.userSetting.birthday_format,
                sessionBirthYear: customerInfo.sessionBirthYear,
                sessionBirthMonth: customerInfo.sessionBirthMonth,
                sessionBirthDay: customerInfo.sessionBirthDay,
                anyStorePickup: customerInfo.anyStorePickup,
                birthdayForm: scope.birthdayForm,
              }
            )
        )

        scope.$on "checkout.customer.needs_update", (() -> onCustomerInfoUpdated())
        onCustomerInfoUpdated = () ->
          payload = {
            customer_name: checkoutService.getElementValue("order[customer_name]", element),
            customer_phone: checkoutService.getElementValue("order[customer_phone]", element)
          }
          if $(checkoutService.customerIntlKey).length > 0
            payload.customer_country = intlTelInputService.getCountry('abbr', $(checkoutService.customerIntlKey));
          $rootScope.$broadcast("checkout.customer.changed", payload)

        notifyCustomerUpdateDebounced = _.debounce(onCustomerInfoUpdated, 100)

        element.on "input", "input[name='order[customer_name]'], input[name='order[customer_phone]']", (() -> notifyCustomerUpdateDebounced())

        element.on('countrychange', checkoutService.customerIntlKey, () -> notifyCustomerUpdateDebounced())
        element.on "destroy", (() -> element.off()) # Unbind events
    }
])
