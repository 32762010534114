import { get, pick } from 'lodash-es';

app.controller('PasswordsEditController', [
  '$window',
  '$scope',
  '$http',
  '$filter',
  'resource',
  'mainConfig',
  'locationService',
  '$cookies',
  function (
    $window,
    $scope,
    $http,
    $filter,
    resource,
    mainConfig,
    locationService,
    $cookies,
  ) {
    $scope.resource = resource.data;
    $scope.from = resource.from;
    $scope.resource.owner_id = mainConfig.merchantData._id;
    const queryParams = locationService.getQueryParams();
    $scope.resource.reset_password_token = get(
      queryParams,
      'reset_password_token',
    );
    $scope.is_reset = get(queryParams, 'reset');
    const memberRelatedParams = pick(queryParams, [
      'registered_by',
      'registered_by_channel_id',
      'member_referral_code',
      'utm_source',
      'utm_campaign',
      'utm_medium',
      'utm_content',
      'utm_term',
    ]);

    $scope.$watch('resource.password', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.userForm.password.$setValidity('general', true);
      }
    });
    $scope.$watch('resource.password_confirmation', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.userForm.password_confirmation.$setValidity('general', true);
      }
      if (
        $scope.resource.password != $scope.resource.password_confirmation &&
        $scope.userForm.submitted
      ) {
        $scope.userForm['password_confirmation']['validationMessage'] = $filter(
          'translate',
        )('form.validation.confirmation');
        $scope.userForm['password_confirmation'].$setValidity('general', false);
      }
    });

    $scope.goBack = function ($event) {
      $event.preventDefault();
      $scope.userForm.errorMessages = null;
      $window.history.back();
    };

    $scope.togglePassword = function ($event) {
      const eyeElement = $($event.currentTarget);
      const password = eyeElement.prevAll('input:first');
      const type = password.attr('type') === 'password' ? 'text' : 'password';
      password.attr('type', type);
      eyeElement.toggle();
      eyeElement.siblings('.eye-toggle').toggle();
    };

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting == true) {
        return;
      }
      $scope.userForm.submitted = true;
      $scope.userForm.submitting = true;
      $scope.userForm.errorMessages = null;

      if ($scope.resource.password != $scope.resource.password_confirmation) {
        $scope.userForm['password_confirmation']['validationMessage'] = $filter(
          'translate',
        )('form.validation.confirmation');
        $scope.userForm.password_confirmation.$setValidity('general', false);
      } else {
        $scope.userForm.password_confirmation.$setValidity('general', true);
      }

      if ($scope.userForm.$invalid) {
        $scope.userForm.submitting = false;
        return;
      }

      $http({
        method: 'PUT',
        url: '/api/users/password',
        data: {
          user: $scope.resource,
          from: $scope.from,
          ...memberRelatedParams,
        },
      })
        .then(function (res) {
          if ($scope.is_reset) {
            const redirect_to = res.data.redirect_to;
            $window.location.href = redirect_to;
          } else {
            const fbc = $cookies.get('_fbc') || '';
            const fbp = $cookies.get('_fbp') || '';
            $window.location.href = `/sign_up_confirmation?fbc=${fbc}&fbp=${fbp}`;
          }
        })
        .catch(function (res) {
          $scope.userForm.errorMessages = res.data.error_messages;
          $scope.userForm.submitting = false;
        });
    };
  },
]);
