import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
} from '../constants/signupLoginEventsTracking';

app.controller('ConfirmationsNewController', [
  '$scope',
  '$http',
  '$filter',
  '$timeout',
  'resource',
  'mainConfig',
  'flash',
  'pnotifyService',
  'customValidator',
  'trackerService',
  'Analytics',
  '$attrs',
  '$location',
  function (
    $scope,
    $http,
    $filter,
    $timeout,
    resource,
    mainConfig,
    flash,
    pnotifyService,
    customValidator,
    trackerService,
    Analytics,
    $attrs,
    $location,
  ) {
    if (flash.registration_message) {
      PNotify.removeAll();
      pnotifyService.notify(flash.registration_message, {});
    }

    if ($attrs.userId) {
      trackerService.fbSignup($attrs.userId);
      trackerService.track({
        type: trackerService.generalEventType.REGISTRATION,
      });

      // for user sign up flow tracking
      trackerService.track({
        type: trackerService.generalEventType.MemberSignUpSucceed,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
          property: PROPERTY_FOR_USER_FLOW.sign_up_option,
          value: mainConfig.merchantData.user_setting.data.signup_method,
        },
      });
    }

    if (Analytics.configuration.enhancedEcommerce) {
      Analytics.pageView();
    }

    $scope.count = 0;
    $scope.countDownText = '';
    $scope.resource = resource.data;

    $scope.resource.owner_id = mainConfig.merchantData._id;
    $scope.userForm = {
      submitting: false,
    };

    $scope.emailRegex = customValidator.EMAIL_REGEX;

    $scope.resendConfirmationEmail = function () {
      if ($scope.userForm.submitting) {
        return;
      }

      $scope.userForm.submitting = true;
      $scope.userForm.errorMessage = null;
      $scope.userForm.infoMessage = null;

      $http({
        method: 'POST',
        url: '/users/confirmation',
        data: {
          user: $scope.resource,
          ui: $location.search()['ui'] ? $location.search()['ui'] : undefined,
        },
      })
        .then(
          function (res) {
            if (res.data.confirmed) {
              $scope.userForm.infoMessage = res.data.message;
            } else {
              $scope.count = 5;
              countDown();
              pnotifyService.notify(res.data.message, {});
            }
          },
          function (res) {
            $scope.userForm.errorMessage = res.data.message;
          },
        )
        .finally(function () {
          $scope.userForm.submitting = false;
        });
    };

    var countDown = function () {
      $scope.count--;
      if ($scope.count) {
        $scope.countDownText = $filter(
          'translate',
        )('users.signup.verification.hint.send', { count: $scope.count });
      } else {
        return;
      }
      $timeout(countDown, 1000);
    };

    $scope.redirectToSignIn = function () {
      const redirectParam = new URLSearchParams($location.search());
      redirectParam.has('email')
        ? redirectParam.delete('email')
        : redirectParam;
      window.location.href = '/users/sign_in?' + redirectParam;
    };
  },
]);
