app.directive('shoplineProductReview', [
  '$http',
  '$rootScope',
  '$lightbox',
  '$filter',
  'featureService',
  'productReviewCommentService',
  'productReviewService',
  'staticImageHost',
  function (
    $http,
    $rootScope,
    $lightbox,
    $filter,
    featureService,
    productReviewCommentService,
    productReviewService,
    staticImageHost,
  ) {
    return {
      templateUrl: require('../../../../../public/themes/shared/product/templates.shopline_product_review.html'),
      restrict: 'E',
      scope: false,
      link: function (scope) {
        if (!productReviewCommentService.productReviewEnabled) {
          return;
        }

        const fetchProductReviews = function () {
          productReviewService
            .getProductReview(scope.product._id)
            .then(function (response) {
              var productReview = response.data.data[0];
              if (productReview && productReview.total_comments_count > 0) {
                var total = productReview.total_comments_count;
                scope.shoplineProductReview = {
                  avg_score: productReview.avg_score.toFixed(1),
                  total: total,
                  rating: [
                    Math.round((productReview.rating.five * 100) / total),
                    Math.round((productReview.rating.four * 100) / total),
                    Math.round((productReview.rating.three * 100) / total),
                    Math.round((productReview.rating.two * 100) / total),
                    Math.round((productReview.rating.one * 100) / total),
                  ],
                };
              }
            })
            .catch(function () {
              scope.shoplineProductReview = null;
            });
        };

        scope.getProductReviewComments = function (params) {
          productReviewCommentService
            .getProductReviewComments(params)
            .then(function (response) {
              if (response.data.total > 0) {
                scope.productReviewComments = response.data;
                scope.productReviewComments.items.forEach(function (
                  item,
                  index,
                ) {
                  scope.productReviewComments.items[
                    index
                  ].user_name = anonymize(item.user_name);
                });
                scope.pagination = {
                  total: response.data.total,
                  limit: 10,
                  page: response.data.page,
                  maxSize: 5,
                };
              }
            })
            .catch(function () {
              scope.productReviewComments = null;
            });
        };

        function createProductReviewCommentsObserver() {
          const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
          };

          const intersectionCallback = (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                scope.getProductReviewComments({
                  product_id: scope.product._id,
                  page: 1,
                });
                observer.disconnect();
              }
            });
          };

          // eslint-disable-next-line compat/compat
          const observer = new IntersectionObserver(
            intersectionCallback,
            options,
          );

          observer.observe(
            document.querySelector('.product-reviews-container'),
          );
        }

        fetchProductReviews();

        createProductReviewCommentsObserver();

        var anonymize = function (name) {
          if (!name || name.length === 0) {
            return;
          } else if (name.length === 1) {
            //add a random letter when length is 1
            return (
              name +
              '****' +
              String.fromCharCode(97 + Math.floor(Math.random() * 26))
            );
          } else {
            //name.first + "****" + name.last
            return name[0] + '****' + name[name.length - 1];
          }
        };

        scope.staticImageHost = staticImageHost;

        scope.enlargeImage = function (allMedias, targetMedia, $event) {
          // these themes only enlarge image when click button
          if (
            ($rootScope.mainConfig.merchantData.current_theme_key ===
              'philia' ||
              $rootScope.mainConfig.merchantData.current_theme_key ===
                'varm') &&
            $event.target.tagName === 'IMG'
          ) {
            return;
          }
          $lightbox.open(allMedias, allMedias.indexOf(targetMedia), {
            size: 'lg',
          });
        };

        scope.reviewSummaryInfoFromShopline = function () {
          if (scope.mainConfig.localeData.loadedLanguage.code === 'ja') {
            return (
              $filter('translate')('product.product_review.out_of_5_stars') +
              scope.shoplineProductReview.avg_score
            );
          } else {
            return (
              scope.shoplineProductReview.avg_score +
              $filter('translate')('product.product_review.out_of_5_stars')
            );
          }
        };
      },
    };
  },
]);
