import { isNull, iteratee } from 'lodash-es';
import { ONE_PAGE_STORE_TRACKER_TYPE } from '../constants/tracker';

function gmcId(
  product,
  variation,
  { single_variation: singleVariation, unique_ids: uniqueIds },
) {
  const productSku = product.sku || product._id;
  if (singleVariation || !(variation && variation.key)) {
    return uniqueIds ? product._id : productSku;
  }
  if (uniqueIds) {
    return `${product._id}:${variation.key}`;
  }
  return variation.sku || `${productSku}:${variation.key}`;
}

app.run([
  '$rootScope',
  'mainConfig',
  '$filter',
  'productService',
  'trackerService',
  'cartService',
  function (
    $rootScope,
    mainConfig,
    $filter,
    productService,
    trackerService,
    cartService,
  ) {
    const { eventTrackers } = window;
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    const baseCurrencyCode = mainConfig.merchantData.base_currency_code;
    const { generalEventType } = trackerService;

    const transformItem = (item) => ({
      item_id: productService.getSku(item.product_id, item.sku, item.variation),
      item_name: $filter('translateModel')(item.product.title_translations),
      ...(item.product.brand ? { item_brand: item.product.brand } : {}),
      price: cartService.getItemPrice(item).dollars || 0,
      quantity: item.quantity,
    });

    const isAllRedeemGift = (items) => {
      return items.every((item) => !isNull(item.item_points));
    };

    $rootScope.$on(generalEventType.REGISTRATION, () => {
      gtag('event', 'sign_up', {
        send_to: 'ga4',
      });
    });
    $rootScope.$on(generalEventType.SEARCH, (e, data) => {
      gtag('event', 'search', {
        send_to: 'ga4',
        search_term: data.query,
      });
    });
    $rootScope.$on(generalEventType.PRODUCT_CLICK, (e, data) => {
      gtag('event', 'select_item', {
        send_to: 'ga4',
        items: [
          {
            item_id: data.product_sku,
            item_name: data.product_title,
            quantity: 1,
          },
        ],
      });
    });
    $rootScope.$on(generalEventType.VIEW_CONTENT, (e, data) => {
      gtag('event', 'view_item', {
        send_to: 'ga4',
        currency: baseCurrencyCode,
        value: data.value,
        items: [
          {
            item_id: productService.getSku(
              data.product._id,
              data.product.sku,
              data.variationSelected,
            ),
            item_name: $filter('translateModel')(
              data.product.title_translations,
            ),
            discount: Math.max(data.product.price?.cents - data.value || 0, 0),
            ...(data.product.brand ? { item_brand: data.product.brand } : {}),
            price: data.product.price?.cents || data.value,
            quantity: 1,
          },
        ],
      });
    });
    $rootScope.$on(generalEventType.ADD_TO_WISHLIST, (e, data) => {
      gtag('event', 'add_to_wishlist', {
        send_to: 'ga4',
        currency: baseCurrencyCode,
        value: data.value,
        items: [
          {
            item_id: data.product_id,
            quantity: 1,
          },
        ],
      });
    });
    $rootScope.$on(generalEventType.ADD_TO_CART, (e, { items }) => {
      const totalValue = items.reduce(
        (accumulator, item) => accumulator + (item.value || 0),
        0,
      );
      eventTrackers.forEach(
        ({
          event_key: eventKey,
          event_type: eventType,
          config_data: configData,
        }) => {
          if (eventKey === 'google_analytics_4') {
            gtag('event', 'add_to_cart', {
              send_to: ['ga4'],
              currency: baseCurrencyCode,
              value: totalValue,
              items: items.map((item) => ({
                item_id: productService.getSku(
                  item.product._id,
                  item.product.sku,
                  item.variationSelected,
                ),
                item_name: $filter('translateModel')(
                  item.product.title_translations,
                ),
                discount: Math.max(
                  item.product.price?.cents - item.value || 0,
                  0,
                ),
                ...(item.product.brand
                  ? { item_brand: item.product.brand }
                  : {}),
                price: item.product.price?.cents || item.value,
                quantity: item.quantity,
              })),
            });
          } else if (eventType !== 'added_product_to_cart') {
            return;
          } else if (eventKey === 'google_adwords') {
            gtag('event', 'conversion', {
              send_to: ['google_ads_added_product_to_cart'],
            });
          } else if (
            eventKey === 'google_remarketing' &&
            configData.dynamic_remarketing
          ) {
            gtag('event', 'add_to_cart', {
              send_to: ['google_remarketing'],
              value: totalValue,
              items: items.map((item) => ({
                id: gmcId(item.product, item.variationSelected, configData),
                google_business_vertical: 'retail',
              })),
            });
          } else if (
            eventKey === 'google_remarketing' &&
            !configData.dynamic_remarketing
          ) {
            gtag('event', 'conversion', {
              send_to: ['google_remarketing'],
            });
          }
        },
      );
    });
    $rootScope.$on(generalEventType.REMOVE_FROM_CART, (e, data) => {
      gtag('event', 'remove_from_cart', {
        send_to: 'ga4',
        currency: baseCurrencyCode,
        value: data.value,
        items: [
          {
            item_id: productService.getSku(
              data.product._id,
              data.product.sku,
              data.variationSelected,
            ),
            item_name: $filter('translateModel')(
              data.product.title_translations,
            ),
            ...(data.product.brand ? { item_brand: data.product.brand } : {}),
            price: data.product.price?.cents || data.value,
            quantity: data.quantity,
          },
        ],
      });
    });
    $rootScope.$on(generalEventType.START_CHECKOUT, (e, data) => {
      gtag('event', 'view_cart', {
        send_to: 'ga4',
        currency: baseCurrencyCode,
        value: data.subtotal?.dollars || 0,
        items: data.items.map((item, index) => ({
          ...transformItem(item),
          // NOTE: product data here is different from others
          item_id: productService.getSku(
            item.product._id,
            item.product.sku,
            item.variation,
          ),
          index,
        })),
      });
    });
    $rootScope.$on(generalEventType.INITIATE_CHECKOUT, (e, data) => {
      gtag('event', 'begin_checkout', {
        send_to: 'ga4',
        currency: baseCurrencyCode,
        value: data.subtotal?.dollars || 0,
        items: data.items.map((item, index) => ({
          ...transformItem(item),
          index,
        })),
      });
    });
    $rootScope.$on(generalEventType.ADD_SHIPPING, (e, data) => {
      gtag('event', 'add_shipping_info', {
        send_to: 'ga4',
        currency: baseCurrencyCode,
        value: data.subtotal?.dollars || 0,
        items: data.items.map((item, index) => ({
          ...transformItem(item),
          index,
        })),
      });
    });
    $rootScope.$on(generalEventType.ADD_BILLING, (e, data) => {
      gtag('event', 'add_payment_info', {
        send_to: 'ga4',
        currency: baseCurrencyCode,
        value: data.subtotal?.dollars || 0,
        items: data.items.map((item, index) => ({
          ...transformItem(item),
          index,
        })),
      });
    });
    $rootScope.$on(generalEventType.PURCHASE, (e, data) => {
      gtag('event', 'purchase', {
        send_to: 'ga4',
        user_id: data.customerId,
        currency: baseCurrencyCode,
        transaction_id: data.transactionId,
        value: data.subtotal?.dollars || 0,
        shipping: data.shipping || 0,
        items: data.items.map((item, index) => ({
          item_id: productService.getSku(
            item.item_id,
            item.object_data.sku,
            item.item_data?.variation_data,
          ),
          item_name: $filter('translateModel')(
            item.object_data.title_translations,
          ),
          ...(item.object_data.brand
            ? { item_brand: item.object_data.brand }
            : {}),
          price: item.item_price.dollars || 0,
          quantity: item.quantity,
          index,
        })),
      });

      if (!isAllRedeemGift(data.items)) {
        const remarketingTracker =
          eventTrackers.find(iteratee({ event_key: 'google_remarketing' })) ||
          {};

        if (remarketingTracker.config_data?.dynamic_remarketing) {
          gtag('event', 'purchase', {
            send_to: 'google_remarketing',
            ecomm_pagetype: 'purchase',
            ecomm_totalvalue: data.subtotal.dollars || 0,
            ecomm_prodid: data.items.map((item) =>
              productService.getSku(
                item.item_id,
                item.object_data.sku,
                item.variation_data,
                remarketingTracker.config_data,
              ),
            ),
          });
        }

        gtag('event', 'conversion', {
          send_to: 'google_ads_placed_an_order',
          value: data.subtotal?.dollars,
          currency: baseCurrencyCode,
          transaction_id: data.orderId,
        });
      }
    });
    $rootScope.$on(ONE_PAGE_STORE_TRACKER_TYPE.CartImpression, () => {
      const { protocol, host, pathname } = window.location;

      gtag('event', 'page_view', {
        send_to: 'ga4',
        page_path: `${protocol}//${host}${pathname}/cart`,
      });
    });

    $rootScope.$on(ONE_PAGE_STORE_TRACKER_TYPE.CheckoutInput, () => {
      const { protocol, host, pathname } = window.location;

      gtag('event', 'page_view', {
        send_to: 'ga4',
        page_path: `${protocol}//${host}${pathname}/checkout`,
      });
    });
  },
]);
