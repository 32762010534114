app.service('smsSubscriptionService', [
  function () {
    return {
      hasMarketingSubscriptions: function (subscriptions) {
        var filteredSubscriptions = _.filter(subscriptions, function (
          subscription,
        ) {
          return (
            subscription.topic === 'MARKETING' &&
            subscription.platform === 'SMS'
          );
        });

        if (_.isEmpty(filteredSubscriptions)) {
          return false;
        }

        return true;
      },
      getActiveSubscriptions: function (topics, subscriptions) {
        return _.reduce(
          subscriptions,
          function (acc, subscription) {
            _.each(topics, function (topic) {
              if (
                subscription.isActive &&
                subscription.topic === topic &&
                subscription.platform === 'SMS'
              ) {
                acc.push(subscription);
              }
            });
            return acc;
          },
          [],
        );
      },
    };
  },
]);
